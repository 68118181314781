import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { actionLogin } from "../../store/actions/authAction";

const Login = (props) => {
  const Navigate = useNavigate();
  const { actionLogin, LoginLoader } = props;
  useEffect(() => {
    if (localStorage.GrandealzFrontendJwtToken) {
      Navigate("/");
    }
  }, []);
  const onFinish = (values) => {
    actionLogin(values, Navigate);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div style={{ padding: "100px" }}>
      <div className="container activelink">
        <Card
          style={{
            width: 400,
            margin: "auto",
            padding: "24px",
            borderRadius: "10px ",
          }}
        >
          <h1 style={{ textAlign: "center", fontWeight: "bold" }}>Login</h1>
          <div className="spacer" />
          <Form
            autoComplete="off"
            requiredMark={"optional"}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              colon={false}
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter your Email!",
                },
              ]}
            >
              <Input
                className="border-input"
                autoFocus="none"
                suffix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <div className="spacer" />
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your Password!",
                },
              ]}
            >
              <Input.Password
                className="border-input"
                name="password"
                placeholder="Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <div className="spacer" />
            <Row>
              <Col span={12}>
                <Form.Item>
                  <Link className="link-color" to="/forgot-password">
                    Forgot password?
                  </Link>
                </Form.Item>
              </Col>
              <Col span={12} align="right">
                <Form.Item>
                  <Button
                    className="button-comman whites-btn"
                    loading={LoginLoader}
                    htmlType="submit"
                    size="medium"
                    style={{ width: 150, color: "black" }}
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    LoginLoader: state.auth.LoginLoader,
  };
};

export default connect(mapStateToProps, { actionLogin })(Login);
