import { RightOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Typography,
  Upload,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import box from "../../assets/profilePage/box.png";
import coupon from "../../assets/profilePage/Coupon-new.png";
import creditCard from "../../assets/profilePage/credit-card.png";
import heart from "../../assets/profilePage/heart.png";
import defaultImage from "../../assets/profilePage/defaultImage.png";
import padlock from "../../assets/profilePage/padlock.png";
import placeholder from "../../assets/profilePage/placeholder.png";
import user from "../../assets/profilePage/user.png";
import voice from "../../assets/profilePage/voice.png";
import {
  logoutUser,
  actionsetProfilePic,
  actionIsVerified,
} from "../../store/actions/authAction";
import { BASE_URL_UPLOAD, BASE_URL_UPLOAD_PROFILE } from "../config/web-config";
const { Text, Title } = Typography;

const style = {
  padding: "20px 50px",
};

const PersonalDetailComman = (props) => {
  const {
    component,
    logoutUser,
    userProfileData,
    actionsetProfilePic,
    isUserVerified,
    actionIsVerified,
  } = props;
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState();
  const location = useLocation();

  useMemo(() => {
    setImageUrl(userProfileData.profile_pic);
  }, [userProfileData]);

  const propsFile = {
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setImageUrl(info.file.response.data.profile_pic);
        console.log(info, "info");
        actionsetProfilePic(info.file);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div className="main-wrapper">
      <div className="container">
        <Row>
          <Col className="gutter-row" md={12} lg={10} xl={6}>
            <div className="site-card-border-less-wrapper">
              <Card bordered={false} className="card-image">
                <ImgCrop rotate aspect={1 / 1}>
                  <Upload
                    {...propsFile}
                    name="file"
                    action={BASE_URL_UPLOAD_PROFILE}
                    headers={{
                      Authorization:
                        "Bearer " +
                        localStorage.getItem("GrandealzFrontendJwtToken"),
                    }}
                    showUploadList={false}
                  >
                    <div className="content-overlay" />
                  </Upload>
                </ImgCrop>
                <Image
                  width={150}
                  className="profile-pic"
                  src={imageUrl ? imageUrl : defaultImage}
                  preview={false}
                />
                <Title level={4} ellipsis={true}>
                  {userProfileData &&
                    userProfileData.first_name +
                      " " +
                      userProfileData.last_name}
                </Title>
                <Typography.Text
                  style={{ marginTop: 5, fontSize: 17 }}
                  ellipsis={true}
                >
                  {userProfileData && userProfileData.email}
                </Typography.Text>
                <div className="spacer" />
              </Card>
              <div className="spacer" />
              <Card bordered={false} className="card-margin">
                <Link to="/personal-detail">
                  <Row
                    justify="space-between"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Col xs={3} md={3}>
                      <img src={user} alt="" />
                    </Col>
                    <Col xs={19} md={19}>
                      <Text strong={location.pathname == "/personal-detail"}>
                        Personal Details
                      </Text>
                    </Col>

                    <Col xs={2} md={2} align="right">
                      <RightOutlined style={{ color: "black" }} />
                    </Col>
                  </Row>
                </Link>
              </Card>
              <Card bordered={false} className="card-margin">
                <Link to="/wishlist">
                  <Row
                    justify="space-between"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Col xs={3} md={3}>
                      <img src={heart} alt="" />
                    </Col>
                    <Col xs={19} md={19}>
                      <Text strong={location.pathname == "/wishlist"}>
                        Wishlist
                      </Text>
                    </Col>

                    <Col xs={2} md={2} align="right">
                      <RightOutlined style={{ color: "black" }} />
                    </Col>
                  </Row>
                </Link>
              </Card>
              <Card bordered={false} className="card-margin">
                {userProfileData.roles !== "bot" ? (
                  <Link to="/my-order">
                    <Row
                      justify="space-between"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <Col xs={3} md={3}>
                        <img src={box} alt="" />
                      </Col>
                      <Col xs={19} md={19}>
                        <Text strong={location.pathname == "/my-order"}>
                          My Orders
                        </Text>
                      </Col>

                      <Col xs={2} md={2} align="right">
                        <RightOutlined style={{ color: "black" }} />
                      </Col>
                    </Row>
                  </Link>
                ) : (
                  <Row
                    onClick={() => actionIsVerified(true)}
                    justify="space-between"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                      cursor: "pointer",
                    }}
                  >
                    <Col xs={3} md={3}>
                      <img src={box} alt="" />
                    </Col>
                    <Col xs={19} md={19}>
                      <Text strong={location.pathname == "/my-order"}>
                        My Orders
                      </Text>
                    </Col>

                    <Col xs={2} md={2} align="right">
                      <RightOutlined style={{ color: "black" }} />
                    </Col>
                  </Row>
                )}
              </Card>
              <div className="spacer" />
              <Card bordered={false} className="card-margin">
                {userProfileData.roles !== "bot" ? (
                  <Link to="/active-tickets">
                    <Row
                      justify="space-between"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <Col xs={3} md={3}>
                        <img src={coupon} alt="" />
                      </Col>
                      <Col xs={19} md={19}>
                        <Text strong={location.pathname == "/active-tickets"}>
                          Tickets
                        </Text>
                      </Col>

                      <Col xs={2} md={2} align="right">
                        <RightOutlined style={{ color: "black" }} />
                      </Col>
                    </Row>
                  </Link>
                ) : (
                  <Row
                    onClick={() => actionIsVerified(true)}
                    justify="space-between"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                      cursor: "pointer",
                    }}
                  >
                    <Col xs={3} md={3}>
                      <img src={coupon} alt="" />
                    </Col>
                    <Col xs={19} md={19}>
                      <Text strong={location.pathname == "/active-tickets"}>
                        Tickets
                      </Text>
                    </Col>

                    <Col xs={2} md={2} align="right">
                      <RightOutlined style={{ color: "black" }} />
                    </Col>
                  </Row>
                )}
              </Card>

              <Card bordered={false} className="card-margin">
                {/* <Link to={`/my-address/${userProfileData._id}`}> */}
                <Link to={`/my-address`}>
                  <Row
                    justify="space-between"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Col xs={3} md={3}>
                      <img src={placeholder} alt="" />
                    </Col>
                    <Col xs={19} md={19}>
                      <Text strong={location.pathname == "/my-address"}>
                        My Address
                      </Text>
                    </Col>

                    <Col xs={2} md={2} align="right">
                      <RightOutlined style={{ color: "black" }} />
                    </Col>
                  </Row>
                </Link>
              </Card>
              <Card bordered={false} className="card-margin">
                <Link to="/change-password">
                  <Row
                    justify="space-between"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Col xs={3} md={3}>
                      <img src={padlock} alt="" />
                    </Col>
                    <Col xs={19} md={19}>
                      <Text strong={location.pathname == "/change-password"}>
                        Change Password
                      </Text>
                    </Col>

                    <Col xs={2} md={2} align="right">
                      <RightOutlined style={{ color: "black" }} />
                    </Col>
                  </Row>
                </Link>
              </Card>
              <div className="spacer" />
              <Row>
                <Col span={24} align="middle">
                  <Button
                    onClick={() => logoutUser(navigate)}
                    size="large"
                    className="button-logout"
                  >
                    Log out
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="gutter-row" md={12} lg={14} xl={18}>
            <div style={style}>{component}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    isUserVerified: state.auth.isUserVerified,
  };
};
export default connect(mapStateToProps, {
  logoutUser,
  actionsetProfilePic,
  actionIsVerified,
})(PersonalDetailComman);
