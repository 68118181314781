import React from 'react';
import { Card, Col, Row, Typography } from "antd";

const ShippingExchangePolicy = () => {
    const { Title } = Typography;
    return (
        <div className="wrapper">
            <Title className="text-line" level={1}>
                {" "}
                Shipping and Exchange Policy{" "}
            </Title>
            <div className="spacer"></div>
            <br />

            <Row align="middle" gutter={[16, 8]}>
                <Col span={24} align="left">
                    <Card bordered={false}>
                        <p className="faq-paragraph">
                            To return your product, you should mail your product to: 622 Manglam Electronic Market Jaipur Rajasthan India 302001.
                        </p>
                        <br />
                        <p className="faq-paragraph">
                            You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
                            Delivery Time Variations: From 7 to 14 Days, Depending on Your Location.
                        </p>
                        <br />
                        <p className="faq-paragraph">
                        If you are shipping an item over &#x20B9;75, you should consider using a trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.
                        </p>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ShippingExchangePolicy