import React from "react";
import {
  Descriptions,
  Button,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Divider,
  Card,
} from "antd";

const { Text, Title } = Typography;
const { TextArea } = Input;
const ContactUs = () => {
  const onFinish = (values) => {};
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="contact-wrapper">
        <Title className="text-line" level={1}>
          Contact Us
        </Title>

        <Row gutter={[16, 16]} align="middle">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Title style={{ fontWeight: 500 }} level={4} className="lorem-text">
              Get in Touch with Grandealz: We're Here to Assist You!
            </Title>
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              requiredMark={"optional"}
              style={{ padding: "35px 2px" }}
            >
              <Form.Item
                colon={false}
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Name!",
                  },
                ]}
              >
                <Input type="text" autoFocus="none" placeholder="Name" />
              </Form.Item>
              <div className="spacer" />
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter your email!",
                  },
                ]}
              >
                <Input name="email" placeholder="Email" />
              </Form.Item>
              <div className="spacer" />

              <Form.Item
                colon={false}
                name="topic"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Topic!",
                  },
                ]}
              >
                <Input type="text" autoFocus="none" placeholder="Topic" />
              </Form.Item>
              <div className="spacer" />
              <Form.Item
                colon={false}
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Topic!",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="message" />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ color: "black" }}
                  className="button-comman whites-btn"
                  loading={false}
                  htmlType="submit"
                  size="large"
                >
                  Send Message
                </Button>
              </Form.Item>
            </Form>
          </Col>
          {/* <Col span={1}></Col> */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            align="top"
            style={{ paddingBottom: 35 }}
          >
            <Card style={{ padding: 9 }}>
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%20%20%20503,%20Arista%20Business,%20Opp%20HOF%20House%20Sindhubhavan%20Road,%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20Bodakdev,%20Ahmedabad%20380054,%20Gujarat,%20India+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    id="gmap_canvas"
                    frameBorder="0"
                    scrolling="no"
                    className="test-iframe"
                    style={{ width: "100%", height: "230px" }}
                  ></iframe>
                </div>
              </div>
              <div className="spacer"></div>
              {/* <br /> */}
              <p className="map-text">Grandealz</p>
              <Divider />
              <div className="spacer"></div>
              <Descriptions title=" ">
                <Descriptions.Item className="map-text" label="Address">
                  503, Arista Business, Opp HOF House Sindhubhavan Road,
                  Bodakdev, Ahmedabad 380054, Gujarat, India
                </Descriptions.Item>
              </Descriptions>
              <Divider />
              <div className="spacer"></div>
              <Descriptions title=" ">
                <Descriptions.Item className="map-text" label="Call us now">
                  <a style={{ color: "black" }} href="tel:9898338292">
                    +91-855-534-9865
                  </a>
                </Descriptions.Item>
              </Descriptions>
              <Divider />
              <div className="spacer"></div>
              <Descriptions title=" ">
                <Descriptions.Item
                  className="map-text"
                  label="Write us an email"
                >
                  <a
                    style={{ color: "rgba(0,0,0,.85)" }}
                    href="mailto:info@grandealz.com"
                  >
                    info@grandealz.com{" "}
                  </a>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactUs;
