import { DownOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button, Card, Col, Empty, Form, Image, Input, Modal, Pagination, Row, Spin, Typography, DatePicker, Select, Badge
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FilterIcon from "../../assets/comman/filter.png";
import { actionGetOrders } from "../../store/actions/orderAction";
const { Title } = Typography;
const { RangePicker } = DatePicker;

const Myorder = (props) => {
  const { actionGetOrders, ordersLoader, ordersData, ordersCount } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const resetForm = () => {
    form.resetFields();
    setStartDate("");
    setEndDate("");
    setStatus("");
    //setSearch("")
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const onFinish = (values) => {
    setStatus(values.status ? values.status : "");
    const data = actionGetOrders(
      0,
      10,
      search ? search : "",
      values.status ? values.status : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
    data && setIsModalOpen(false)
  };

  const onFinishSearch = (values) => {
    setSearch(values.search ? values.search : "");
    actionGetOrders(
      0,
      10,
      values.search ? values.search : "",
      status ? status : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
  };


  const onChangePagination = (skip, limit) => {
    actionGetOrders(
      skip,
      limit,
      search ? search : "",
      status ? status : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
  };

  useEffect(() => {
    actionGetOrders(0, 10);
  }, []);
  return (
    <Spin spinning={ordersLoader}>
      <div className="container my-order">
        <h1 className="font-bold">
          My <span className="text-line"> Orders</span>
        </h1>
        <div className="spacer" />
        <Form
          name="basic" 
          layout="inline"
          onFinish={onFinishSearch}
          autoComplete="off"
        >
          <Row style={{ width: "100%" }} >
            <Col span={18} >
              <Form.Item name="search">
                <Input
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ width: "100%" }}
                  autoFocus="none"
                  type={"text"}
                  size="large"
                  placeholder="Search Here"
                />
              </Form.Item>
            </Col>

            <Col span={4} align="center">
              <Form.Item>
                <Button style={{ borderRadius: '10px' }} size="large" htmlType="submit">
                  Search
                </Button>
              </Form.Item>
            </Col>
            <Col span={2} align="right">
              <Form.Item>
                <Badge count={status && startDate && endDate ? 2 : status || startDate && endDate ? 1 : 0}>
                  <img
                    className="order-filter"
                    onClick={showModal}
                    width="40"
                    src={FilterIcon}
                    alt="product-image"
                  />
                </Badge>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {
          ordersData && ordersData.length > 0 ?
            <>
              <div className="order-list-overflow">
                {ordersData &&
                  ordersData.length &&
                  ordersData.map((d, index) => {
                    return (
                      <div key={index}>
                        <div key={index}>
                          <div className="spacer" />
                          <Card bordered={false}>
                            <Link to={`/order-detail/${d._id}`}>
                              <Row
                                gutter={[24, 16]}
                                justify="space-between"
                                align="middle"
                              >
                                <Col
                                  span={20}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    style={{
                                      padding: "10px",
                                      backgroundColor: "#F9F9F9",
                                      maxWidth: "100px",
                                      minWidth: "100px",
                                    }}
                                    src={d.draws.draw.product_image}
                                    //width={100}
                                    preview={false}
                                    alt="product-image"
                                  />
                                  <div style={{ marginLeft: "20px" }}>
                                    <p className="order-title">
                                      {d.draws.draw.product_title}
                                    </p>
                                    <p className="order-description">
                                      {d.draws.draw.draw_title}
                                    </p>
                                    <p className="order-price">
                                      ₹ {d.draws.draw.product_price}
                                    </p>
                                    {d.draws.draw_tickets &&
                                      d.draws.draw_tickets.length > 0 && (
                                        <p className="order-description">
                                          {d.draws.draw_tickets.length} Tickets
                                        </p>
                                      )}
                                  </div>
                                </Col>

                                <Col span={2} align="right">
                                  <RightOutlined style={{ color: "black" }} />
                                </Col>
                              </Row>
                            </Link>
                          </Card>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <Row>
                <Col span={24} align="right" style={{ marginTop: "10px" }}>
                  <Pagination
                    size="small"
                    showSizeChanger={false}
                    hideOnSinglePage={true}
                    total={ordersCount}
                    onChange={(e, d) => onChangePagination(e - 1, d)}
                  />
                </Col>
              </Row>
            </>
            :

            <div style={{ margin: "100px" }}>
              <Row>
                <Col span={24} align="middle">
                  <Empty description="No order found" />
                  <div className="spacer" />
                  <Link to="/">
                    <Button size="large" className="button-comman">
                      Start Shoping
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
        }


      </div>

      <Modal
        footer={false}
        className="Draws-modal"
        title={
          <>
            <Row align="middle">
              <Col align="left" span={4}>
                <DownOutlined onClick={handleCancel} />
              </Col>
              <Col span={16} align="middle">
                <Title level={3}>Filter</Title>
              </Col>
              <Col span={4} align="right">
                <a
                  onClick={() => resetForm()}
                  style={{ color: "#E70736", fontSize: 18 }}
                >
                  RESET
                </a>
              </Col>
            </Row>
          </>
        }
        open={isModalOpen}
        onOk={handleOk}
      //onCancel={handleCancel}
      >
        <Form
        className='color-main'
          name="forms"
          form={form}
          style={{ padding: "0px 10px" }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="date">
            <RangePicker onChange={onRangeChange} format="YYYY-MM-DD" />
          </Form.Item>
          <div className="spacer" />
          <Form.Item name="status">
            <Select
              showSearch
              placeholder="Select Status"
              optionFilterProp="children"
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: "Confirmed",
                  label: "Confirmed",
                },
                {
                  value: "Shiped",
                  label: "Shipped",
                },
                {
                  value: "Out For Delivery",
                  label: "Out For Delivery",
                },
                {
                  value: "Delivered",
                  label: "Delivered",
                },
                {
                  value: "Cancelled",
                  label: "Cancelled",
                },
                {
                  value: "Pending",
                  label: "Pending",
                },
                {
                  value: "On Hold",
                  label: "On Hold",
                },
                {
                  value: "Refunded",
                  label: "Refunded",
                },
                {
                  value: "Pending Payment",
                  label: "Pending Payment",
                },
                {
                  value: "Payment Failed",
                  label: "Payment Failed",
                },
              ]}
            />
          </Form.Item>
          <div style={{ textAlign: "center", marginTop: 25 }}>
            <Form.Item>
              <Button
                style={{ width: "60%", height: 50 }}
                size="large"
                className="button-comman"
                htmlType="submit"
              >
                Apply
              </Button>
            </Form.Item>
            <div className="spacer"></div>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return {
    ordersLoader: state.order.ordersLoader,
    ordersData: state.order.ordersData,
    ordersCount: state.order.ordersCount,
  };
};
export default connect(mapStateToProps, { actionGetOrders })(Myorder);
