import {
  HeartFilled,
  HeartOutlined,
  MinusOutlined,
  PlusOutlined,
  ShareAltOutlined,
  TabletTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Progress,
  Row,
  Typography,
  Image,
  Spin,
} from "antd";
import React, { useEffect, useMemo } from "react";
import money from "../../assets/comman/money.png";
import productPencil from "../../assets/comman/productPencil.png";
import calendar1 from "../../assets/comman/calendar1.png";
import Ellipse from "../../assets/comman/Ellipse.png";
import pencil from "../../assets/comman/product-pencil.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { actionGetDrawDetail } from "../../store/actions/authAction";
import {
  actionAddWishlist,
  actionAddCart,
  actionGetCart,
} from "../../store/actions/wishlistCartAction";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { useState } from "react";
const { Text, Title } = Typography;

const ProductDetail = (props) => {
  const {
    actionGetDrawDetail,
    drawDetailLoader,
    actionAddCart,
    actionGetCart,
    drawDetailData,
    actionAddWishlist,
    wishlistData,
    cartData,
    addCartLoader,
  } = props;

  const { productId } = useParams();
  const [quantity, setQantity] = useState(1);
  const Navigate = useNavigate();

  useMemo(() => {}, [drawDetailData]);

  useEffect(() => {
    actionGetDrawDetail(productId);
    actionGetCart();
  }, []);

  const handleOk = (id, loader) => {
    setQantity(1);
    actionAddCart({
      draw: id,
      qty: 1,
    });
  };

  const cartDataProduct =
    cartData.draws &&
    cartData.draws.length &&
    cartData.draws.find((d) => d.draw._id == drawDetailData._id);
  return (
    <Spin spinning={drawDetailLoader}>
      <div className="product-wrapper-margin">
        <div className="container">
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Card>
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={12} className="min-height-top-col">
                        <Card style={{ background: "rgba(252, 252, 252, 1)" }}>
                          <Row align="top" className="min-height-top">
                            {drawDetailData.max_draw_date ||
                            drawDetailData.max_draw_date_description ||
                            drawDetailData.draw_date ? (
                              <>
                                <Col
                                  span={3}
                                  style={{ marginTop: 8 }}
                                  align="center"
                                >
                                  <img
                                    src={calendar1}
                                    alt="calender"
                                    className="calendar-image"
                                  />
                                </Col>
                                <Col span={15} align="left">
                                  <Text
                                    className="responsive-text"
                                    style={{ textAlign: "center" }}
                                  >
                                    {drawDetailData.max_draw_date
                                      ? `Max Draw Date:
                                        ${dayjs(
                                          drawDetailData.max_draw_date
                                        ).format("DD MMMM, YYYY")}
                                    `
                                      : drawDetailData.draw_date
                                      ? `Draw Date:
                                        ${dayjs(
                                          drawDetailData.draw_date
                                        ).format("DD MMMM, YYYY")}
                                    `
                                      : ""}
                                  </Text>
                                  <div>
                                    <div
                                      className="responsive-text-1"
                                      style={{ fontSize: 11, color: "#616161" }}
                                      type="secondary"
                                    >
                                      {drawDetailData.max_draw_date_description}
                                    </div>
                                  </div>
                                </Col>
                                <Col span={5} align="right">
                                  <img src={Ellipse} alt="" />
                                </Col>
                              </>
                            ) : null}
                          </Row>
                        </Card>
                      </Col>
                      <Col span={24} align="middle">
                        <div className="spacer" />
                        <div className="product-image-container">
                          <Image
                            className="product-image-padding"
                            preview={false}
                            src={drawDetailData.product_image}
                          />
                        </div>
                        <div className="spacer" />
                      </Col>
                      <Col span={24} align="middle">
                        <Title level={3}>
                          {" "}
                          <Text strong>Buy a</Text>
                        </Title>
                        <Title level={4} style={{ marginTop: "0px" }}>
                          <Text type="secondary">
                            {drawDetailData.product_title}
                          </Text>
                        </Title>
                        <p>
                          <Text>{drawDetailData.product_description}</Text>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col span={2} align="middle">
                    <Divider type="vertical" />
                  </Col> */}
                  {/* <Col span={11}>
                    <Row>
                      <Col
                        span={24}
                        className="min-height-top-col"
                        align="right"
                      >
                        <Progress
                          className="min-height-top"
                          style={{ float: "right" }}
                          type="circle"
                          width={80}
                          percent={
                            (100 *
                              drawDetailData.total_no_of_sold_out_tickets) /
                            drawDetailData.total_no_of_tickets
                          }
                          format={(percent) =>
                            percent === 100 ? (
                              <span className="color-progress-text">
                                All sold
                              </span>
                            ) : (
                              <p className="progress-wrapper">
                                <span className="color-progress-text">
                                  {drawDetailData.total_no_of_sold_out_tickets}
                                </span>{" "}
                                Sold out off{" "}
                                <span className="color-progress-text">
                                  {drawDetailData.total_no_of_tickets}
                                </span>
                              </p>
                            )
                          }
                          strokeColor={{
                            "0%": "#D1035A",
                            "100%": "#EC092D",
                          }}
                        />
                      </Col>
                      <Col span={24} align="middle">
                        <div className="spacer" />
                        <div className="product-image-container">
                          <Image
                            preview={false}
                            src={drawDetailData.draw_image}
                          />
                        </div>
                        <div className="spacer" />
                      </Col>
                      <Col span={24} align="middle">
                        <Title level={3}>
                          {" "}
                          <Text strong>Get a chance to win:</Text>
                        </Title>

                        <Title
                          level={4}
                          style={{ marginTop: "0px", fontSize: 25 }}
                        >
                          {" "}
                          <Text type="secondary">
                            {drawDetailData.draw_title}
                          </Text>
                        </Title>

                        <p>
                          <Text>{drawDetailData.draw_description}</Text>
                        </p>
                      </Col>
                    </Row>
                  </Col> */}
                </Row>
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Row
                  className="background-color-gray card-padding"
                  align="middle"
                  justify="space-between"
                >
                  <Col span={13}>
                    <p className="gray-25">Price</p>
                    <p className="gray-16">Inclusive of Tax</p>
                  </Col>
                  <Col span={11} align="right">
                    <p className="pink-25">₹{drawDetailData.product_price}</p>
                  </Col>
                </Row>
                <div className="spacer" />
                <Row align="middle" justify="space-between">
                  <Col span={24}>
                    <div className="spacer" />
                    {wishlistData &&
                    wishlistData.some(
                      (d) => d.draw._id == drawDetailData._id
                    ) ? (
                      <Link to="/wishlist">
                        <Button
                          size="large"
                          icon={<HeartFilled style={{ color: "#EA082E" }} />}
                          style={{ border: "1px solid #616161" }}
                          className="full-width-btn"
                        >
                          Go to Wishlist
                        </Button>
                      </Link>
                    ) : (
                      // <Link to='/wishlist' >
                      <Button
                        size="large"
                        icon={<HeartOutlined />}
                        className="full-width-btn"
                        onClick={() =>
                          actionAddWishlist({ draw: drawDetailData._id })
                        }
                        style={{ border: "1px solid #616161" }}
                      >
                        Add to Wishlist
                      </Button>
                      // </Link>
                    )}
                  </Col>
                  {/* <Col span={24}>
                    <div className="spacer" />
                    <Button
                      size="large"
                      icon={<ShareAltOutlined />}
                      className="full-width-btn"
                      style={{ border: '1px solid #616161' }}

                    >
                      Share campaign
                    </Button>
                  </Col> */}
                  <Col span={24}>
                    <div className="spacer" />
                    {/* {cartData && cartData.some((d) => d.draw._id == drawDetailData._id) ? ( */}
                    {cartData.draws &&
                    cartData.draws.some(
                      (d) => d.draw._id == drawDetailData._id
                    ) ? (
                      <>
                        <Row gutter={16}>
                          <Col>
                            <Button
                              onClick={() =>
                                actionAddCart({
                                  draw: cartDataProduct.draw._id,
                                  qty: cartDataProduct.qty - 1,
                                })
                              }
                              className="ant-btn-custom"
                              size={"large"}
                              icon={<MinusOutlined />}
                            />
                          </Col>
                          <Col>
                            <Button className="ant-btn-custom" size={"large"}>
                              {cartDataProduct.qty}
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              onClick={() =>
                                actionAddCart({
                                  draw: cartDataProduct.draw._id,
                                  qty: cartDataProduct.qty + 1,
                                })
                              }
                              className="ant-btn-custom"
                              size={"large"}
                              icon={<PlusOutlined />}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Button
                        disabled={
                          addCartLoader ||
                          drawDetailData.total_no_of_sold_out_tickets >=
                            drawDetailData.total_no_of_tickets
                        }
                        style={{ border: "1px solid #000000" }}
                        onClick={() =>
                          handleOk(drawDetailData._id, addCartLoader)
                        }
                        size="large"
                        className="full-width-btn"
                      >
                        ADD TO CART
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <div className="spacer" />
          {/* <Title level={3}>
            {" "}
            <Text strong>Buy a {drawDetailData.product_title}</Text>
          </Title> */}
          {/* <Title level={3} style={{ marginTop: 0 }}>
            {" "}
            <Text strong>{drawDetailData.draw_title}</Text>
          </Title> */}
          {/* <Title level={3}>
            {" "}
            <Text type="secondary">Prize Details</Text>
          </Title>
          <div className="spacer" />
          <Text>{drawDetailData.draw_description}</Text>
          <div className="spacer" /> */}
          {/* <Row gutter={[16, 16]} justify="space-around" align="middle">
            <Col span={4}>
              <Card style={{ padding: 5 }}>
                <Row>
                  <Col
                    span={24}
                    align="middle"
                    style={{ background: "#F1F1F1", padding: 10 }}
                  >
                    <Image
                      preview={false}
                      width={120}
                      src={drawDetailData.product_image}
                    />
                  </Col>
                  <Col span={24}>
                    <Title style={{ textAlign: "center" }} level={4}>
                      {drawDetailData.product_title}
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Title
                      style={{ textAlign: "center", color: "#D8000D" }}
                      level={5}
                    >
                      ₹{drawDetailData.product_price}
                    </Title>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={20}>
              <Title level={3}>
                {" "}
                <Text type="secondary">Product Details</Text>
              </Title>
              <div className="spacer" />
              <Text>{drawDetailData.product_description}</Text>
            </Col>
          </Row> */}
        </div>
      </div>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return {
    drawDetailLoader: state.auth.drawDetailLoader,
    wishlistData: state.wishlistCart.wishlistData,
    drawDetailData: state.auth.drawDetailData,
    addCartLoader: state.wishlistCart.addCartLoader,
    cartData: state.wishlistCart.cartData,
  };
};

export default connect(mapStateToProps, {
  actionGetDrawDetail,
  actionGetCart,
  actionAddWishlist,
  actionAddCart,
})(ProductDetail);
