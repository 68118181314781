import React from "react";
import { Typography } from "antd";

const Terms = () => {
  const { Title } = Typography;
  return (
    <div className="wrapper">
      <Title className="text-line" level={1}>
        {" "}
        Terms and Conditions{" "}
      </Title>
      <div className="spacer"></div>
      <br />
      <h3>Welcome to Grandealz!</h3>
      <br />
      <p>
        This website and ‘Grandealz’ App (collectively referred to as
        “Platform”) are owned and operated by Supergran Dealz LLP, having
        address at Arista,, 205-206, Sindhu Bhavan Marg, Bodakdev, Ahmedabad,
        Gujarat 380054, India (referred to as “Grandealz”, “We”, “Us”, “Our” or
        “Company” – which expression, unless repugnant to the context and
        meaning thereof also includes the successors and assigns of Grandealz).
      </p>
      <br />
      <p>
        If you continue to browse and use the Platform, you are agreeing to
        comply with and be bound by the following Terms &amp; Conditions of Use,
        Privacy Policy and other Policies published on the Platform from time to
        time (Collectively referred to as the &ldquo;Terms&rdquo;). The Terms
        constitute an Agreement between Grandealz and any natural/legal person
        who accesses or uses the Platform in any manner (referred to as
        &ldquo;User&rdquo;, &ldquo;you&rdquo; or &ldquo;your&rdquo;).
      </p>
      <br />{" "}
      <p>
        If you do not agree with any of these Terms, please do not use this
        Platform. By accessing or using the Platform, you accept the Terms and
        agree to abide by the same.
      </p>
      <br />
      <h2>Your Use of the Platform:</h2>
      <br />
      <p>
        The products and/or services and/or promotional schemes made available
        on the Platform shall only be availed by User(s) who are at least
        eighteen (18) years of age and can form legally binding contracts under
        Indian Contract Act, 1872 and/or other applicable law in force.
        <br />
        <br />
        User(s) accessing or using the Platform/Services represent and warrant
        that they are eligible to use the Platform and are capable of entering
        into Contract under India Contract Act, 1872 and/or other applicable law
        in force. If a user is below 18 (Eighteen) years of age, it is assumed
        that he/she is using/browsing the Platform under supervision of his/her
        parent or legal guardian and that such user&rsquo;s parent or legal
        guardian has read and agrees to the Terms. If the User is using the
        Platform on behalf of a company, organisation or entity, the User
        represents that he/she has authority to act on behalf of that entity,
        and that such entity accepts this Agreement.
        <br />
        <br />
        Grandealz reserves the right to deny access to the Platform to any User
        if Grandealz discovers that the User does not meet the conditions set in
        the Terms.
        <br />
        <br />
        You cannot access or use the Platform for any purpose other than the
        purpose for which we make the Platform available to you and you cannot
        use either the Platform in any way that infringes the rights of anyone
        else or that restricts or inhibits anyone else's enjoyment of the
        Platform or that is in contravention to the applicable laws, rules and
        regulations.
        <br />
        <br />
        You can only access the Platform for lawful purposes. You are solely
        responsible for the knowledge of and adherence to any and all applicable
        laws, rules, and regulations pertaining to your use of the Platform. You
        will not commit or attempt to do any action on or with regard to the
        Platform which may be harmful, harassing, defamatory, obscene,
        pornographic, invasive of another's privacy, hateful, objectionable,
        disparaging, or otherwise unlawful in any manner whatsoever. We reserve
        the right to block any User and/or suspend and/or permanently disable
        user account if we believe in our sole discretion that action of such
        User has resulted in breach of these Terms or any other applicable laws,
        rules, and regulations.
      </p>
      <br />
      <h2>Account Registration:</h2>
      <br />
      <p>
        In order to purchase Products, access the services offered by us and
        utilise the Platform, you may be required to create an account with us.
        You may be required to provide certain information about yourself in
        order to register and maintain your account with us. You agree that all
        information provided by you will be true, complete, accurate and not
        offensive. You are responsible for updating your account registration
        and user profile details to keep it true, accurate, current, and
        complete at all times. <br />
        <br />
        You acknowledge that the information or material you provide, in any
        manner whatsoever, are not confidential or proprietary; does not
        infringe any rights of a third party; and is not in violation of any
        applicable laws, rules or regulations. <br />
        <br />
        We reserve the right to refuse registration, or limit, suspend or
        withdraw access to the Platform to any person at any time and for any
        reason.
        <br />
        <br />
        While registering your account on the Platform, you may be required to
        register a username and password for use of the Platform. You are
        responsible at all times for maintaining the confidentiality of Username
        and Password, and restricting access to and use of, your Account and
        Password.
        <br />
        <br />
        You will be responsible for all activities done through your User
        Account. If someone other than you uses the Platform with your login
        information, you will be held accountable for all actions taken in your
        name.
        <br />
        <br />
        You agree to reimburse Grandealz for any costs, expenses and damages
        suffered by Grandealz due to any unauthorized or illegal use of your
        Account and/or Password by you or any person accessing your Account
        and/or Password.
        <br />
        <br />
        The User Accounts are non-transferrable and cannot be sold or
        transferred to any other person. <br />
        <br />
        If you are accessing, browsing and using the Platform on behalf of a
        company or organization; you represent that you have the authority to
        bind such company or organization to all the Terms herein. In the event
        that the person/entity on whose behalf you are accessing or browsing the
        Platform refuses to be bound by the Terms, you agree to accept liability
        for any harm caused by any wrongful use of the Platform resulting from
        such access or use of the Platform in whatsoever nature.
      </p>
      <br />
      <h2>Modification of Terms:</h2>
      <br />
      <p>
        Grandealz may at any time modify the Terms without any prior notice to
        you. It will be your responsibility to accept the modifications from
        time to time to use the Platform. In the event the modified Terms is not
        acceptable to you, you shall discontinue browsing through the Platform
        and using our services. However, if you continue to use the Platform,
        you shall be deemed to have agreed with the modified Terms. In case of
        any modification to the Terms, the version of the Terms that will apply
        to your order, access of Services and participation in promotional
        events will be those uploaded on the Platform at the time of placing of
        order or accessing services or participating in promotional events, as
        the case may be.
      </p>
      <br /> <h2>Products, Pricing and Delivery:</h2>
      <br />{" "}
      <p>
        By placing order for the Products through our Platform, you agree to the
        Terms set here.
        <br />
        <br />
        The Products provided through this Platform are for personal use only.{" "}
        <br />
        <br />
        The Company may discontinue, suspend, or modify the availability,
        quantity and price of any of the Products on the Platform at any time,
        without any notice or liability to you. <br />
        <br />
        All orders are acknowledged at current pricing. We will bill at price in
        effect at the time of placing of order. Items for orders placed together
        may be shipped separately.
        <br />
        <br />
        Payments shall be made using the Payment Methods as displayed and made
        available on the Platform at the time of placing of order. <br />
        <br />
        We do not warrant that the Product descriptions, colours, information or
        other content available on the Platform are hundred percent accurate,
        complete, reliable, current, or error-free. The Platform may contain
        typographical errors or inaccuracies and may not be complete or updated.
        Please note that the Product pictures are indicative and may not match
        the actual Product in identical manner.
        <br />
        <br />
        You agree and understand that though we endeavour to ship and deliver
        our Products to all our Users in an efficient manner, we may, in our
        sole discretion determine any location or areas as unserviceable for
        delivery of our Products. In the event any location or area has been
        deemed unserviceable by us, we shall notify such user. You may also
        verify whether the destination address entered by you is unserviceable
        for delivery by entering the relevant information on the Platform at the
        time of registering the account and/or purchasing products, as the case
        may be.Grandealz reserves the right to cancel your order in case the
        destination address entered by you is unserviceable.
        <br />
        <br />
        You agree that you shall bear absolute liability in case of any failure
        of Grandealz in delivering the product due to your failure to provide
        correct, complete, sufficient and accurate information. <br />
        <br />
        You understand that Grandealz will deliver the Products to you through
        its third-party Delivery/Logistic Partners. Such Delivery Partners are
        separate and independent from Grandealz. Grandealz shall not be liable
        for any acts or omissions on part of the Delivery Partner including
        their delivery agents, employees, or personnel.&nbsp;
        <br />
        <br />
        We reserve the right to charge or collect shipping/delivery charges for
        the delivery of Products. Shipping/delivery charges may vary based on
        the product, area of delivery or payment mechanism. You agree that we
        are authorized to collect the shipping and delivery charges for the
        delivery service provided by Grandealz through its Delivery Partner. In
        the event we charge a shipping charges for the delivery of a purchased
        Product, such shipping charges will not be refunded by us pursuant to
        any return/cancellation request raised by you. You acknowledge and
        accept that the title and risk of all Products ordered by you shall pass
        on to you upon the delivery of the purchased Products to you.
        <br />
        <br />
        Additional applicable taxes, charges, duties may be charged depending
        upon the destination where the order has to be shipped. Paying such
        duties, charges (if applicable) at the time of delivery is the duty of
        the customer/user. Grandealz reserves the right to collect taxes and/or
        such other levy/ duty/ surcharge from the User that it may have to incur
        on behalf of the User at the time of receiving the order.
        {/* <br /> */}
        {/* The Applicable Shipping Charges will be either included in the price of
        the Product as reflected on the Platform or will be added and indicated
        in the Bill amount generated at the time of placing of the order. */}
        <br />
        <br />
        The Applicable Shipping Charges will be included and indicated in the
        final bill amount generated at the time of placing of the order. The
        user will bear the Shipping Charges.
        <br />
        <br />
        Upon placement of the order, the estimated shipping and delivery
        timelines may be available on order details page. The estimated delivery
        times are only indicative. There may be some delay in actual delivery
        than what is estimated at the time of placing of order, due to reasons
        which may be beyond our reasonable control. <br />
        <br />
        In case of delay in delivery of the Product, you will have the right
        either to cancel the ordered Product or wait for the Product to be
        delivered. Please note that your order will be cancelled due to: (i)
        unavailability of the Product ordered; or (ii) at your instructions, in
        the event of failure to deliver the Product on the expected time of
        delivery by our delivery partners. You agree that the Company shall not
        be liable to pay for any damage or loss either direct or indirect owing
        to such cancellation of the order or delay in delivery.
      </p>
      <br />
      <h2>Products Return/Exchange/Refund Policy:</h2>
      <br />
      <p>
        Please refer to the Products Return/Exchange/Refund Policy -
        incorporated by reference herein, that is uploaded on the Platform. By
        using, browsing, accessing, or purchasing from the Grandealz Platform,
        you agree to be bound by our Products Return/Exchange/Refund Policy.
      </p>
      <br />
      <h2>Intellectual Property Rights:</h2>
      <br />
      <p>
        All information and content available on the Platform and its
        &ldquo;look and feel&rdquo;, including but not limited to trademarks,
        text, graphics, artwork, images, information, videos, sounds, software
        and the compilation and organization thereof (collectively, the
        "Content") is the Intellectual Property of Grandealz, our affiliates,
        partners or licensors. Except for the limited use of the Platform for
        the purposes set out in these Terms, no content of the Platform can be
        used, reproduced, duplicated, copied, sold, modified, or otherwise
        exploited, in whole or in part, directly or indirectly, by you for any
        purpose without our express, prior written consent from Grandealz.
        <br />
        <br />
      </p>
      <p>
        We grant you a limited, revocable, and non-exclusive license to access
        and make personal use of the Platform and the content therein for
        browsing our Platform for your personal use, purchasing Products and/or
        for participating in our promotional schemes. Any unauthorized use by
        you of the Platform or any Content other than for the purpose mentioned
        above automatically terminates the limited licenses set forth in this
        section without prejudice to any other remedy available to us under
        these Terms and applicable laws, rules, regulations.
      </p>
      <br />
      <h2>Third Party Links:</h2>
      <br />
      <p>
        Our Platform may offer various information through direct or indirect
        links to third-party Websites/Applications. Grandealz does not endorse
        any such information or content provided by third-party
        Websites/Applications and expressly disclaims any and all liability in
        connection with the third-party Websites/Applications. Grandealz&nbsp;is
        not responsible or liable for the services, products, information or the
        content provided by third-party Websites/Applications and any damage or
        loss that may result from your access to or use of such third-party
        Websites/Applications. <br /> <br />
        The use of any third-party link or application may be subject to any
        terms and conditions provided on such third-party Websites/Applications
        which is not governed by us. You assume all responsibility and risk of
        use of any third-party links (including any content therein).
        <br />
        <br />
        Grandealz does not have any obligation to examine or scan third-party
        links or applications, for any purpose, and is not responsible for the
        accuracy, completeness, appropriateness or legality of any third-party
        links/applications including the content therein.
      </p>
      <br />
      <h2>Privacy Policy:</h2>
      <br />
      <p>
        We believe in protecting privacy of our users. Please refer to the
        Privacy Policy - incorporated by reference herein, that is uploaded on
        the Platform. By using, browsing, accessing, or purchasing from the
        Grandealz Platform, you agree to be bound by our Privacy Policy.
      </p>
      <br />
      <h2>Communication from Grandealz:</h2>
      <p>
        By accessing our Platform; placing an order; availing our services; and
        participating in our Promotional Offers - you agree to receive
        communications from Grandealz periodically. At any point of time, you
        have the right to withdraw your consent and opt-out from receiving the
        communications from Grandealz. You may use the opt out link found in any
        email/message that you may receive or in absence of such provision, you
        may choose to opt out by giving a written request to Grandealz to that
        effect.
      </p>
      <br />
      <h2>Posting by User:</h2>
      <ul>
        <li>
          {" "}
          By posting, storing, or transmitting any content on the Platform, you
          hereby grant to Grandealz a perpetual, worldwide, non-exclusive,
          royalty-free, right and license to use, copy, display, perform, create
          derivative works from, distribute, transmit, delete and assign such
          content in any form, in all media, anywhere in the world, subject to
          Grandealz&rsquo;s privacy policy.
        </li>
        <li>
          {" "}
          You are solely responsible for your interactions with other users of
          the Platform and any content that you post on the Platform.
        </li>
        <li>
          Grandealz is not liable for any damage or harm resulting from any
          posts by or interactions between the users.
        </li>
        <li>
          {" "}
          Grandealz reserves the right, but has no obligation, to monitor
          interactions among users of the Platform and to remove any content
          that Grandealz deems objectionable.
        </li>
      </ul>
      <br />
      <h2>Disclaimer of Warranties & Limitation of Liabilities:</h2>
      <ul>
        <li>
          Your use of the Platform and the services, products and promotional
          schemes made available on the Platform are at your sole risk. The
          Platform and the services, products and promotional schemes are
          offered on an “as is” and “as available” basis. Grandealz expressly
          disclaims all warranties of any kind, whether express or implied.
        </li>
        <li>
          The inclusion of any products, services or offers on the Platform at a
          particular time does not imply or warrant that the products, services
          or offers will be available at all and any time.
        </li>
        <li>
          Grandealz will try to ensure that all information, whether in relation
          to the products, services, promotional offers or otherwise
          (hereinafter “Information”), provided on the Platform is correct.
          However, Grandealz does not guarantee the accuracy of the Information.
          Grandealz makes no representations or warranties as to the
          completeness or accuracy of Information available on the Platform from
          time to time.
        </li>
        <li>
          You agree that the information is being supplied to you on the
          condition that you will make your own determination as to the
          suitability of such information for your purpose prior to use or in
          connection with the making of any decision.
        </li>
        <li>
          Grandealz shall have the right, at any time, to change or discontinue
          any product, content, material, feature or service of the Platform.
        </li>
        <li>
          Grandealz will not be liable for any direct, indirect, incidental,
          special or consequential damages or loss in connection with your use
          of this Platform in any manner, including liabilities resulting from
          (a) the use or the inability to use the Platform or products or allied
          services; (b) the cost of procuring substitute of the products; (c)
          any products purchased or obtained or transactions entered into
          through the Platform; (d) interruption of business; (e) access delays
          or access interruptions to the Platform; (f) viruses, system failures
          or malfunctions which may occur in connection with your use of the
          Platform; (g) any inaccuracies or omissions in content.
        </li>
      </ul>
      <br />
      <h2>Promotional Schemes and Events:</h2>
      <p>
        Grandealz may, from time to time, conduct various types of marketing
        campaigns and promotional schemes, which may include offers, contests,
        competitions and events where the user may be awarded some gift
        (collectively referred to as “Promotional Offers”), as an effort to
        boost marketing and sales of its Products and/or Services:
        <br /> <br />{" "}
      </p>
      <ul>
        <li>
          Such Promotional Offers shall be subject to the terms and conditions
          which are solely determined by us, and the terms and conditions of
          such Promotional Offers may vary for the users based on factors
          relating to the User such as usage of the platform, purchase of
          products, volume of transactions, place of residence.
        </li>
        <li>
          If you participate in any Promotional offers conducted by Grandealz,
          it is your responsibility to review and familiarize yourself with the
          terms and conditions applicable to such Promotional Offers including
          the modifications thereto. Your continued use of the Platform and/or
          participation in Promotional Offers shall be construed as acceptance
          of the applicable terms and conditions.
        </li>
        <li>
          It is your responsibility to ensure that your participation in
          Promotional Offers by Grandealz will not violate any applicable law,
          rules or regulations that you may be subject to. If the state or
          country of which you are a resident prohibits its residents from
          participating in such Promotional Offers as offered by Grandealz, you
          shall refrain from participating in the Promotional Offers. Grandealz
          will not be responsible for any non-compliance of applicable laws,
          rules, regulations by you.
        </li>

        <li>
          By entering into and participating in the Promotional Offers, you
          represent and agree that you meet the required legal, government and
          regulatory criteria to participate in such Promotional Offers and
          receive the gift offered.
        </li>
        <li>
          You understand and agree that you will be able to participate in the
          Promotional Offer only if you purchase Product(s) from Grandealz.
        </li>
        <li>
          If you are an employee of Grandealz (including its affiliate and
          associate companies), you cannot participate in the Promotional Offers
          and Schemes offered by Grandealz.
        </li>
        <li>
          The recipient of the Gift from the Promotional Offers will be decided
          on random and fair basis. The results of the Promotional Offers are
          not subject to negotiation by users / participants / recipients of the
          Gift.
        </li>
        <li>
          If any recipient of the Gift from the Promotional Offer is
          subsequently found to be ineligible to participate in the Promotional
          Offer, we may at our sole discretion reclaim the Gift or dispose of
          the same in such a manner and to such person as we deem fit.
        </li>
        <li>
          The distribution of the gift will be done as per what is offered by
          Grandealz at the time of launching the Promotional Offer. For Gift in
          Cash, Grandealz will transfer the Gift amount to the recipient (user).
          The recipient (user) shall provide requisite details as asked by
          Grandealz in order to enable Grandealz to transfer the gift amount to
          the recipient (user). Grandealz will not be liable in case of any
          inability of Grandealz in transferring the gift amount due to the
          recipient/user’s failure to provide correct, complete, sufficient and
          accurate information as required by Grandealz. For non-cash gifts, the
          recipient (user) will be required to collect the gift from Grandealz
          at a place and time as intimated by Grandealz to this effect.
          Grandealz will not be responsible for arranging any transport or any
          other expenses of a personal nature incurred by the recipient in the
          collection of the gift, unless otherwise agreed by us in writing.
        </li>
        <li>
          Unclaimed Gifts within the time period allotted in the Promotional
          Offer or in absence of any specific allotted time - within 30 days
          from the date of announcing results (“Allotted Time Period”) will be
          reclaimed by Greandealz. It will be Grandealz’s discretion to decide
          whether to give away the gift or not after the Allotted Time Period is
          over.
        </li>
        <li>
          We will not be responsible for claims, damages, or expenses of any
          nature whatsoever for any loss, injury including bodily injury, to any
          one and/or any third-party during and/or in course of usage of any
          gift or due to any defect in any gift.
        </li>
        <li>
          The income tax, gift tax or any other statutory tax liability that
          arises in connection with participating or receiving the gift from the
          Promotional Offer(s) from time to time will be solely borne by the
          user(s) / recipient (s) of Gift from the respective Promotional
          Offer(s). All Promotional Offers/Gifts shall be subject to deduction
          of applicable taxes.{" "}
        </li>
        <li>
          We reserve the right to extend, change, amend, discontinue or cancel
          any of the Promotional Offers without any prior intimation.
        </li>
        <li>
          You agree that if you are unable to participate in any Promotional
          Offer due to any error or omission whether or not attributable to
          Grandealz, including technical or other glitches, Grandealz will have
          no responsibility whatsoever towards you for inability to participate.
        </li>
        <li>
          You agree to provide us with details as we may require for
          verification process before handing over any gift that you may receive
          from any Promotional Offer held by Grandealz.
        </li>
        <li>
          Grandealz may offer Game Contests to its Customers/Users as part of
          its Promotional Offers. The Users acknowledge and understand that such
          Games require the Users/Customers to substantially apply their Skills.
          It is the User’s responsibility to ensure that his/her participation
          in Promotional Offers (including Game Contests) will not violate any
          applicable law, rules or regulations that the User may be subject to.
        </li>
        <li>
          You agree that your failure to comply with applicable laws, rules,
          regulations, terms and conditions will disqualify you from
          participating and/or receiving gift offered in the Promotional Offers.
        </li>
        <li>
          The participants understand and agree that by merely participating in
          Promotional Offer, the participant does not become eligible to claim
          the gift(s).
        </li>
        <li>
          All decisions with respect to Promotional Offers, including deciding
          upon the recipient and gifts to be distributed to the recipients shall
          be at the sole discretion of Grandealz and the same will be final &
          binding on all Users/Participants. No correspondence shall be
          entertained in this regard.
        </li>
      </ul>
      <br />
      <h2>FORCE MAJEURE:</h2>
      <p>
        Grandealz shall not be liable for any damages whatsoever arising out of
        force majeure events, directly or indirectly affecting Grandealz and/or
        the Platform. Examples of force majeure events include without
        limitation real or potential labour disputes, governmental actions, war
        or threat of war, civil unrest, demonstrations, fire, storm, flooding,
        explosion, earthquake, lockdowns, epidemic or pandemic, provisions or
        limitations of materials or resources, inability to obtain the relevant
        authorization, accident. Force majeure events beyond the Company’s
        reasonable control entitles the Company to suspend or limit the Platform
        and its services and promotional offers until further notice.
      </p>
      <br />
      <h2>Indemnification:</h2>
      <br />
      <p>
        You agree to defend, indemnify and hold Grandealz and its affiliates,
        contractors, employees, officers, directors, agents and third party
        partners harmless from any and all claims, losses, damages, liabilities,
        costs and expenses, including without limitation, legal fees and
        expenses, caused by or arising out of claims based upon your actions or
        inactions or related to your use or misuse of the Platform, any
        violation of these Terms or applicable laws, which may result in any
        loss or liability to Grandealz or any third party.
      </p>
      <br />
      <h2>Intellectual Property Rights Complaints:</h2>
      <br />
      <p>
        We respect the intellectual property of others. If you believe that any
        content on our Platform constitutes infringement of your Intellectual
        Property Right, please write to us providing the following: (i)
        identification of the work that you claim has been infringed; (ii) a
        statement along with proof that you are the owner or authorized to act
        on the owner's behalf for the work claimed to be infringed; (iii)
        description and location of the material on the website that you claim
        is infringing; (iv) your address, telephone number and email address.
        Receipt of your complain shall in no way be construed as acceptance of
        your claims and contentions. Grandealz reserves its rights to
        investigate on the complaint and take appropriate actions as it deems
        fit.
      </p>
      <br />
      <h2>Grievance Officer/Customer Care:</h2>
      <br />
      <p>
        The details of our Grievance Officer / Customer Care in charge are as
        follows:
      </p>
      <p>
        Phone: +91-855-534-9865
        <br />
        E-mail: info@grandealz.com
        <br />
        Availability Time: 8.30am to 6.30pm
      </p>
      <p>
        You can contact on the above details in case of any concern relating
        torefund/return/exchange of the Product, to report abuse or complain
        about any content hosted, transmitted, published, updated or shared on
        the Website.
      </p>
      <p>&nbsp;</p>
      <h2>General:</h2>
      <br />
      <ul>
        <li>
          You acknowledge and agree that these Terms constitute the complete and
          exclusive agreement between us concerning your use of the Platform,
          and supersede and govern all prior proposals, agreements, or other
          communications.
        </li>
        <br />
        <li>
          Nothing contained in these Terms shall be construed as creating any
          agency, partnership, or other form of joint enterprise between us.
        </li>
        <br />
        <li>
          Our failure to require your performance of any provision hereof shall
          not affect our full right to require such performance at any time
          thereafter, nor shall our waiver of a breach of any provision hereof
          be taken or held to be a waiver of the provision itself.
        </li>
        <br />
        <li>
          In the event that any provision of these Terms shall be unenforceable
          or invalid under any applicable law, such unenforceability or
          invalidity shall not render these Terms unenforceable or invalid as a
          whole but these Terms shall be modified, to the extent possible to
          most fully reflect the original intent of the parties as reflected in
          the original provision.
        </li>
        <br />
        <li>
          Grandealz makes no representation that the Platform or Products are
          available and/or appropriate for use in all jurisdictions. The users
          accessing the Platform do so at their own risk and shall bear the
          responsibilities for compliance with the applicable laws of their
          jurisdictions.
        </li>
        <br />
        <li>
          Grandealz can assign its rights and obligations under these Terms in
          its sole discretion without any prior notice to you.
        </li>
        <br />
        <li>
          Grandealz and its affiliates, associates, representatives shall have
          no liability to you for content that may be deemed offensive,
          objectionable or indecent in your personal opinion.
        </li>
        <br />
        <li>
          The headings in these Terms herein are included for convenience
          purposes only and are not intended to describe, interpret, define or
          limit the scope of the provisions contained therein.
        </li>
        <br />
        <li>
          {/* We ask all Users to check the applicable laws and ensure that their
          purchase of products from our Platform; availing of our Services and
          Participation in the Promotional Offers organized by Grandealz are
          lawful and in consonance with the applicable laws, rules and
          regulations to which they are subject to. The Promotional Offers are
          solely provided as a part of marketing and promotional campaign for
          the products sold on our Platform. The Game Contests that may be held
          by Grandealz as part of its Promotional Offer require the
          Users/Customers are skill-based games. The User is responsible for any
          decisions that the User makes based on the information provided on our
          Platform. The User must take his/her own personal circumstances into
          consideration, including the laws of his/her jurisdiction, before
          acting on any decision based on his/her visit to this Platform. */}
          We ask all Users to check the applicable laws and ensure that their
          purchase of products from our Platform; availing of our Services and
          Participation in the Promotional Offers organized by Grandealz are
          lawful and in consonance with the applicable laws, rules and
          regulations to which they are subject to. The Promotional Offers are
          solely provided as a part of marketing and promotional campaign for
          the products sold on our Platform. The Game Contests that may be held
          by Grandealz as part of its Promotional Offer require the
          Users/Customers are skill-based games. The User is responsible for any
          decisions that the User makes based on the information provided on our
          Platform. The User must take his/her own personal circumstances into
          consideration, including the laws of his/her jurisdiction, before
          acting on any decision based on his/her visit to this Platform.
        </li>
        <br />
        <li>
          Grandealz may at any time, in its sole discretion and without advance
          notice to you, cease operation of the Platform and supply of the
          Products/Services/Promotional Offers related thereto.
        </li>
        <br />
        <li>
          These Terms shall be construed in accordance with the applicable laws
          of India without regard to any conflict of law. You agree that any
          dispute arising in connection with your use of the Platform and the
          Products/Services/Promotional Offers provided through the Platform
          shall be finally settled under the Rules of Arbitration, Mediation,
          Conciliation and Alternate Dispute Resolution Center of Gujarat
          Chamber of Commerce & Industry by One Arbitrator appointed in
          accordance with the said Rules. The place and seat for arbitration
          proceeding shall be Ahmedabad, Gujarat, India. The language of the
          proceedings, documentation and the award shall be English. The award
          shall be made in writing and shall be final and binding on the User.
        </li>
        <br />
        <li>
          The User agree that the validity, operation and performance of these
          Terms shall be governed by and interpreted in accordance with the laws
          of India, and the User submits to the exclusive jurisdiction of Courts
          of Ahmedabad, Gujarat with respect to any matter or claim, suit,
          action or proceeding arising under or relating to these Terms subject
          to above clause “17K”.
        </li>
        <br />
      </ul>
      <p>&nbsp;</p>
    </div>
  );
};

export default Terms;
