import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import { actionChangePassword } from "../../store/actions/authAction";

const ChangePassword = (props) => {
  const [form] = Form.useForm();
  const { actionChangePassword, changePasswordLoader } = props;
  const onFinish = (values) => {
    const reset = () => {
      form.resetFields();
    };
    actionChangePassword(values, reset);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="container">
      <h1 className="font-bold">
        Change <span className="text-line"> Password</span>
      </h1>
      <div className="spacer" />
      <Form
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        requiredMark={"optional"}
        size="large"
      >
        <div className="spacer" />
        <Row>
          <Col span={14}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                // {
                //   min: 6,
                //   message:
                //     "passwords must be longer than or equal to 8 characters !",
                // },
              ]}
            >
              <Input.Password
                className="border-input"
                placeholder="Old password"
              />
            </Form.Item>
            <div className="spacer" />
          </Col>

          <Col span={14}>
            <Form.Item
              name="new_password"
              rules={[
                {
                  required: true,
                  message: "Please input your New password!",
                },
                {
                  validator: (_, value) =>
                    !value || form.getFieldValue("password") !== value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "New password cannot be the same as the old password!"
                          )
                        ),
                },
                {
                  min: 8,
                  message:
                    "passwords must be longer than or equal to 8 characters !",
                },
              ]}
            >
              <Input.Password
                size="large"
                className="border-input"
                placeholder="New Password"
              />
            </Form.Item>
            <div className="spacer" />
          </Col>

          <Col span={14}>
            <Form.Item
              name="confirm_new_password"
              dependencies={["new_password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                className="border-input"
                placeholder="Confirm New Password"
              />
            </Form.Item>
            <div className="spacer" />
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button
                loading={changePasswordLoader}
                size="large"
                className="button-comman"
                htmlType="submit"
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    changePasswordLoader: state.auth.changePasswordLoader,
  };
};

export default connect(mapStateToProps, { actionChangePassword })(
  ChangePassword
);
