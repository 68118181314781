import React, { useEffect } from "react";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Card, Row, Col } from "antd";
import { actionForgotPassword } from "./../../store/actions/authAction";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = (props) => {
  const { forgotPasswordLoader, actionForgotPassword } = props;
  const [form] = Form.useForm();
  const Navigate = useNavigate();

  const onFinish = (values) => {
    actionForgotPassword(values, Navigate);
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ padding: "100px" }}>
      <div className="container activelink">
        <Card
          style={{
            width: 400,
            margin: "auto",
            padding: "24px",
            borderRadius: "10px ",
          }}
        >
          <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
            Forgot Password
          </h1>
          <div className="spacer" />
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            requiredMark={"optional"}
          >
            <Form.Item
              colon={false}
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter your Email!",
                },
              ]}
            >
              <Input
                className="border-input"
                autoFocus="none"
                suffix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <div className="spacer" />
            <p>
              Provide your account email address to receive an email to reset
              your password.
            </p>
            <div className="spacer" />
            <Row>
              <Col span={12}>
                <Form.Item>
                  <Link className="link-color" to="/login">
                    Back to Login
                  </Link>
                </Form.Item>
              </Col>
              <Col span={12} align="right">
                <Form.Item>
                  <Button
                    className="button-comman whites-btn"
                    loading={forgotPasswordLoader}
                    htmlType="submit"
                    size="medium"
                    style={{ width: 150, color: "black" }}
                  >
                    Send
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    forgotPasswordLoader: state.auth.forgotPasswordLoader,
  };
};

export default connect(mapStateToProps, { actionForgotPassword })(
  ForgotPassword
);
