import { RightOutlined } from '@ant-design/icons'
import { Card, Descriptions, Divider, Button, Col, Row, Typography, Input, InputNumber, DatePicker, Checkbox } from 'antd'
import React from 'react'


const { Title, Text } = Typography;

const Payment = () => {
  return (
    <>

      <div className=" payment-cart cart-margin">
        <div className="container">
          <Row gutter={[16, 9]}>
            <Col span={17}>
              <Title style={{ padding: '0 25px' }} level={1}>Address
                <Divider id="divider" /></Title>
              <div className="spacer"></div>


              <Row gutter={[32, 0]} align='middle'>
                <Col span={11}>
                  <Card className="red-card" hoverable>
                    <Row align='middle' justify='space-between'>
                      <Col span={20} >
                        <Text className='text' style={{ color: '#fff', fontSize: 20, paddingLeft: 20 }}>Credit/Debit Card</Text>
                      </Col>
                      <Col align='right' span={4}>
                        <RightOutlined style={{ fontSize: 20, color: '#fff' }} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={11}>
                  <Card style={{ padding: '0px 5px ' }} >

                    <Text type='secondary' style={{ fontSize: 17 }}>Credit/Debit Card number</Text> <br />

                    <InputNumber placeholder="xxxx xxxx xxxx xxxx" maxLength={16} />
                  </Card>
                </Col>
                <Col span={11}>
                  <Card style={{ padding: '10px 15px ' }} hoverable>
                    <Row align='middle' justify='space-between'>
                      <Col span={20}>
                        <Text className='text' >Phonepe/Google Pay/BHIM UPI</Text>
                      </Col>
                      <Col align='right' span={4}>
                        <RightOutlined style={{ fontSize: 20, color: '#000' }} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={11}>
                  <Row align='middle' gutter={[9, 0]}>
                    <Col span={12} >
                      <Card style={{ padding: '0px 5px ' }} >

                        <Text type='secondary' style={{ fontSize: 17 }}>Expiry Date</Text> <br />

                        {/* <InputNumber placeholder="xxxx xxxx xxxx xxxx" /> */}
                        <DatePicker placeholder='xx/xx' />

                      </Card>
                    </Col>
                    <Col span={12} >
                      <Card style={{ padding: '0px 5px ' }} >
                        <Text type='secondary' style={{ fontSize: 17 }}>Enter CVV</Text> <br />
                        <InputNumber placeholder="xxx" maxLength={10} />
                      </Card>
                    </Col>
                  </Row>

                </Col>

                <Col span={11}>
                  <Card style={{ padding: '10px 15px ' }} hoverable>
                    <Row align='middle' justify='space-between'>
                      <Col >
                        <Text className='text'>Net Banking</Text>
                      </Col>
                      <Col align='right'>
                        <RightOutlined style={{ fontSize: 20, color: '#000' }} />
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col span={11} justify='center'>
                  <Card bordered={false} style={{ backgroundColor: 'transparent', boxShadow: 'none' }} >
                    <Row align='middle'>
                      <Col span={2}>
                        <Checkbox id='checkb' style={{ fontSize: 18 }} ></Checkbox>

                      </Col>
                      <Col span={20}>

                        <label htmlFor='checkb' className='text' style={{ paddingLeft: 0 }} >
                          Save the card for future purchases.
                        </label>
                      </Col>

                    </Row>

                  </Card>

                </Col>
              </Row>

            </Col>
            <Col span={7}>

              <Card style={{ width: "393px" }}>
                <Descriptions
                  title="Price Details"
                  column={1}
                  className="cart-title"
                >
                  <div className="spacer"></div>
                  <Descriptions.Item label="Total MRP">
                    ₹ 100.00
                  </Descriptions.Item>
                  <Descriptions.Item label="VAT">₹ 30.00</Descriptions.Item>
                  <Descriptions.Item label="Promo Code">
                    -₹ 10.00
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Total Amount"
                    style={{ borderTop: "1.1px solid #616161", paddingTop: 12 }}
                  >
                    ₹ 120.00
                  </Descriptions.Item>
                </Descriptions>
              </Card>

              {/* <Link to='cart-address'> */}

              <Button style={{
                width: 229,
              }} size="large" className="button-comman shiping-btn">
                Pay Now
              </Button>
              {/* </Link> */}
            </Col>
          </Row>
          {/* */}

        </div>
      </div>
    </>
  )
}

export default Payment