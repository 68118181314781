import { Row, Col, Typography } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import grandealz from "../../assets/comman/footer-logo.svg";
import appstore from "../../assets/comman/appstore.png";
import googleplaystore from "../../assets/comman/google-play-store.png";
import group from "../../assets/comman/group.svg";
import twitter from "../../assets/comman/twitter.png";
import instagram from "../../assets/comman/insta.svg";
import facebook from "../../assets/comman/facebook.svg";
import whatsapp from "../../assets/comman/whatsapp.svg";
import linkedin from "../../assets/comman/linkedin.png";

const { Text, Title } = Typography;
const Footer = () => {
  const location = useLocation();
  return (
    <div className="footer-wrap activelink">
      <div className="footer-container ">
        <Row gutter={[16, 16]} type="flex" justify="center">
          <Col span={8}>
            {/* <img src={grandealz} alt="" />
            <br />  
            <br /> */}
            <Text className="text">
              Grandealz is an online e-commerce platform that redefines your
              shopping experience, thereby making the process ultimately easy
              and thrilling for you. All you’ve got to do is buy the product of
              your choice online by making a payment in cash or via any payment
              tools.
              {/* Grandealz is an online e-commerce platform that redefines your
              shopping experience, thereby making the process ultimately easy
              and thrilling for you. All you’ve got to do is buy the product of
              your choice, get a free ticket and earn a fair chance at winning
              grand rewards. */}
            </Text>
            {/* <img style={{marginTop:12}} src={grandealz} alt="" /> */}
          </Col>
          <Col span={8} align="middle">
            <ul>
              {/* <li>

          
              <Title
                level={4}
                className="footer-heading"
              >
                Customer Service
              </Title>
                
            </li> */}
              <li style={{ marginTop: 0 }}>
                <Link
                  to="/contact-us"
                  className={
                    window.location.pathname === "/contact-us" ? "active" : ""
                  }
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/faq"
                  className={
                    window.location.pathname === "/faq" ? "active" : ""
                  }
                >
                  FAQs
                </Link>
              </li>
              <li>
                <Link
                  to="/how-it-works"
                  className={
                    window.location.pathname === "/how-it-works" ? "active" : ""
                  }
                >
                  How it Works
                </Link>
              </li>
              <li>
                <Link
                  to="/terms"
                  className={
                    window.location.pathname === "/terms" ? "active" : ""
                  }
                >
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/refund-policy"
                  className={
                    window.location.pathname === "/refund-policy"
                      ? "active"
                      : ""
                  }
                >
                  Refund & Cancellation Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className={
                    window.location.pathname === "/privacy-policy"
                      ? "active"
                      : ""
                  }
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/shipping-policy"
                  className={
                    window.location.pathname === "/shipping-policy"
                      ? "active"
                      : ""
                  }
                >
                  Shipping and Exchange Policy
                </Link>
              </li>
            </ul>
          </Col>
          <Col span={8} style={{ paddingLeft: 40 }}>
            <Text className="text">
              Experience the Grandealz App on mobile and buy the products.
            </Text>
            <br />
            <br />
            {/* <Row gutter={[16, 16]} className="play-store-icons">
              <Col span={12}>
                <a
                  href="https://apps.apple.com/in/app/grandealz/id6447436349"
                  target="_blank"
                >
                  <img src={appstore} alt="" width="100%" />
                </a>
              </Col>
              <Col span={12}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.v.g"
                  target="_blank"
                >
                  <img src={googleplaystore} alt="" width="100%" />
                </a>
              </Col>
            </Row> */}
            {/* <br />
            <Text className="text">we accept</Text>
            <Row align="middle">
              <Col span={12}>
                <img src={group} alt="" />
              </Col>
            </Row> */}
          </Col>
        </Row>
      </div>
      <div style={{ border: "2px solid #F1F1F1" }}>
        <div className="footer-container p-0">
          <Row align="middle" gutter={[16, 16]}>
            <Col span={4}>
              <a
                href="https://play.google.com/store/apps/details?id=com.v.g"
                target="_blank"
              >
                <img src={grandealz} className=" bg-trasparent" alt="" />
              </a>
            </Col>
            <Col span={15} align="middle">
              <Text className="text" style={{ textAlign: "center" }}>
                © 2022 All rights reserved.
              </Text>
            </Col>

            <Col span={5} align="right">
              <a
                className="pr-0"
                href="https://www.instagram.com/grandealz/?igshid=MzRlODBiNWFlZA%3D%3D"
                target="_blank"
              >
                <img
                  className="group-image cursor-pointer"
                  src={instagram}
                  alt=""
                />
              </a>
              <a
                className="pr-0"
                href="https://www.facebook.com/grandealzofficial/?mibextid=ZbWKwL"
                target="_blank"
              >
                <img
                  className="group-image cursor-pointer"
                  src={facebook}
                  alt=""
                />
              </a>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Footer;
