import { MinusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Image,
  Row,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import wishlist from "../../assets/comman/wishlist.png";
import {
  actionAddCart,
  actionDeleteWishlist,
  actionGetWishlist,
} from "../../store/actions/wishlistCartAction";

const Wishlist = (props) => {
  const { Paragraph } = Typography;
  const {
    actionAddCart,
    actionGetWishlist,
    wishlistLoader,
    wishlistData,
    addCartLoader,
    cartData,
    actionDeleteWishlist,
  } = props;

  useEffect(() => {
    actionGetWishlist();
  }, []);

  return (
    <div className="container my-order">
      <h1 className="font-bold">
        Wishlist <span className="text-line"> </span>
      </h1>
      <div className="spacer" />
      <Spin spinning={wishlistLoader}>
        <div className="order-list-overflow">
          {wishlistData && wishlistData.length > 0 ? (
            <Row gutter={[16, 16]}>
              {wishlistData &&
                wishlistData.length &&
                wishlistData.map((data, index) => {
                  return (
                    <Col md={24} lg={24} xl={24} key={index}>
                      <Card bordered={false} style={{ marginRight: 10 }}>
                        <Row
                          gutter={[16, 16]}
                          justify="space-between"
                          align="middle"
                        >
                          <Col span={24} align="right">
                            <Tooltip title="Remove From Wishlist" color="white">
                              <div
                                className="cancle-icon"
                                onClick={() =>
                                  actionDeleteWishlist({
                                    wishlistId: data && data._id,
                                    drawId: data && data.draw._id,
                                  })
                                }
                              >
                                <MinusOutlined />
                              </div>
                            </Tooltip>
                          </Col>
                          <Col
                            span={15}
                            align="left"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Image
                              style={{
                                padding: "10px",
                                backgroundColor: "#F9F9F9",
                                maxWidth: "100px",
                                minWidth: "100px",
                              }}
                              src={data.draw.product_image}
                              alt="product-image"
                              //width={150}
                              preview={false}
                            />
                            <div style={{ marginLeft: "10px" }}>
                              <p className="order-title">
                                {data.draw.product_title}
                              </p>
                              <Paragraph
                                className="order-description"
                                ellipsis={{
                                  rows: 2,
                                }}
                              >
                                {data.draw.product_description}
                              </Paragraph>
                              <p className="order-price">
                                ₹ {data.draw.product_price}
                              </p>
                            </div>
                          </Col>

                          <Col span={7} align="right">
                            <div className="spacer" />
                            {cartData.draws &&
                            cartData.draws.some(
                              (cart) => cart.draw._id === data.draw._id
                            ) ? (
                              <Link to="/cart">
                                <Button size="large" className="button-comman">
                                  View Cart
                                </Button>
                              </Link>
                            ) : (
                              <Button
                                disabled={
                                  addCartLoader ||
                                  data.draw.total_no_of_sold_out_tickets >=
                                    data.draw.total_no_of_tickets
                                }
                                size="large"
                                onClick={() => {
                                  actionAddCart({
                                    draw: data.draw._id,
                                    qty: 1,
                                  });
                                }}
                                className="button-comman"
                              >
                                Add To Cart
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          ) : (
            <div style={{ margin: "100px" }}>
              <Row>
                <Col span={24} align="middle">
                  <Empty image={wishlist} description="You wishlist is empty" />
                  <div className="spacer" />
                  <Link to="/">
                    <Button size="large" className="button-comman">
                      Start Shoping
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wishlistLoader: state.wishlistCart.wishlistLoader,
    wishlistData: state.wishlistCart.wishlistData,
    addCartLoader: state.wishlistCart.addCartLoader,
    cartData: state.wishlistCart.cartData,
    deleteWishlistLoader: state.wishlistCart.deleteWishlistLoader,
  };
};
export default connect(mapStateToProps, {
  actionGetWishlist,
  actionAddCart,
  actionDeleteWishlist,
})(Wishlist);
