export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_DATA = "REGISTER_DATA";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const VERIFY_OTP_LOADING = "VERIFY_OTP_LOADING";
export const VERIFY_OTP = "VERIFY_OTP";
export const RESEND_OTP_LOADING = "RESEND_OTP_LOADING";
export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_PROFILE_DATA = "USER_PROFILE_DATA";
export const REMOVE_USER_PROFILE_DATA = "REMOVE_USER_PROFILE_DATA";
export const IS_USER_LOGGED_IN = "IS_USER_LOGGED_IN";
export const IS_USER_VERIFIED = "IS_USER_VERIFIED";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPLOAD_PIC_LOADING = "UPLOAD_PIC_LOADING";
export const UPLOAD_PIC_DATA = "UPLOAD_PIC_DATA";
export const FORGOT_PASSWORD_LOADER = "FORGOT_PASSWORD_LOADER";
export const CHANGE_PASSWORD_LOADER = "CHANGE_PASSWORD_LOADER";
export const RESET_PASSWORD_LOADER = "RESET_PASSWORD_LOADER";
export const SET_PROFILE = "SET_PROFILE";
export const GET_BANNERS_LOADER = "GET_BANNERS_LOADER";
export const GET_BANNERS = "GET_BANNERS";
export const GET_DRAWS_LOADER = "GET_DRAWS_LOADER";
export const GET_DRAWS = "GET_DRAWS";
export const TOTAL_UPCOMING_DRAW_COUNT = "TOTAL_UPCOMING_DRAW_COUNT";
export const GET_SINGLE_DRAWS_LOADER = "GET_SINGLE_DRAWS_LOADER";
export const GET_SINGLE_DRAWS = "GET_SINGLE_DRAWS";
export const GET_COUNTRY_LOADER = "GET_COUNTRY_LOADER";
export const GET_COUNTRY_DATA = "GET_COUNTRY_DATA";
export const GET_DRAW_DETAIL_LOADING = "GET_DRAW_DETAIL_LOADING";
export const GET_DRAW_DETAIL_DATA = "GET_DRAW_DETAIL_DATA";
export const GET_WISHLIST_LOADER = "GET_WISHLIST_LOADER";
export const GET_WISHLIST_DATA = "GET_WISHLIST_DATA";
export const GET_CART_LOADER = "GET_CART_LOADER";
export const GET_CART_DATA = "GET_CART_DATA";
export const APPLY_COUPEN_LOADER = "APPLY_COUPEN_LOADER";
export const REMOVE_COUPEN_LOADER = "REMOVE_COUPEN_LOADER";
export const ADD_CART_LOADER = "ADD_CART_LOADER";
export const ADD_CART_DATA = "ADD_CART_DATA";
export const DELETE_WISHLIST_LOADER = "DELETE_WISHLIST_LOADER";
export const DELETE_WISHLIST_DATA = "DELETE_WISHLIST_DATA";
export const ADD_WISHLIST_LOADER = "ADD_WISHLIST_LOADER";
export const ADD_WISHLIST_DATA = "ADD_WISHLIST_DATA";
export const DONATE_LOADER = "DONATE_LOADER";
export const DONATE_DATA = "DONATE_DATA";
export const COUNTRY_LOADER = "COUNTRY_LOADER";
export const COUNTRY_DATA = "COUNTRY_DATA";
export const STATE_LOADER = "STATE_LOADER";
export const STATE_DATA = "STATE_DATA";
export const CITY_LOADER = "CITY_LOADER";
export const CITY_DATA = "CITY_DATA";
export const CHANGE_MOBILE_LOADER = "CHANGE_MOBILE_LOADER";
export const CHANGE_MOBILE_DATA = "CHANGE_MOBILE_DATA";
export const GET_ADDRESS_LOADER = "GET_ADDRESS_LOADER";
export const GET_ADDRESS_DATA = "GET_ADDRESS_DATA";
export const UPDATE_ADDRESS_LOADING = "UPDATE_ADDRESS_LOADING";
export const UPDATE_ADDRESS_DATA = "UPDATE_ADDRESS_DATA";
export const GET_SINGLE_ADDRESS_LOADER = "GET_SINGLE_ADDRESS_LOADER";
export const GET_SINGLE_ADDRESS = "GET_SINGLE_ADDRESS";
export const ADD_ADDRESS_DATA = "ADD_ADDRESS_DATA";
export const ADD_ADDRESS_LOADING = "ADD_ADDRESS_LOADING";
export const REMOVE_CART_DATA = "REMOVE_CART_DATA";
export const GET_ORDERS_LOADER = "GET_ORDERS_LOADER";
export const GET_ORDERS_DATA = "GET_ORDERS_DATA";
export const TOTAL_ORDERS_COUNT = "TOTAL_ORDERS_COUNT";
export const GET_SINGLE_ORDER_LOADER = "GET_SINGLE_ORDER_LOADER";
export const GET_SINGLE_ORDER_DATA = "GET_SINGLE_ORDER_DATA";
export const GET_QUIZ_LOADER = "GET_QUIZ_LOADER";
export const GET_QUIZ_DATA = "GET_QUIZ_DATA";
export const GET_UPCOMING_DRAWS = "GET_UPCOMING_DRAWS";
export const GET_UPCOMING_DRAWS_LOADER = "GET_UPCOMING_DRAWS_LOADER";
export const ADD_ALLOCATE_TICKETA_ON_ORDER_LOADER =
  "ADD_ALLOCATE_TICKETA_ON_ORDER_LOADER";
export const ADD_ALLOCATE_TICKETA_ON_ORDER_DATA =
  "ADD_ALLOCATE_TICKETA_ON_ORDER_DATA";
export const ACTIVE_TICKETS_LOADER = "ACTIVE_TICKETS_LOADER";
export const ACTIVE_TICKETS_DATA = "ACTIVE_TICKETS_DATA";
export const GET_WINNER_LOADER = "GET_WINNER_LOADER";
export const GET_WINNER_DATA = "GET_WINNER_DATA";
export const TOTAL_WINNERS_COUNT = "TOTAL_WINNERS_COUNT";
export const GET_COUPAN_LOADER = "GET_COUPAN_LOADER";
export const GET_COUPAN__DATA = "GET_COUPAN__DATA";
export const GET_ORDER_INVOICE_LOADER = "GET_ORDER_INVOICE_LOADER";
export const GET_ORDER_INVOICE_DATA = "GET_ORDER_INVOICE_DATA";
export const CREATE_BPAY_ORDER_LOADER = "CREATE_BPAY_ORDER_LOADER";
export const CREATE_BPAY_ORDER_DATA = "CREATE_BPAY_ORDER_DATA";
export const GET_REF_ID = "GET_REF_ID";
export const GET_ORDER_STATUS_LOADER = "GET_ORDER_STATUS_LOADER";
export const GET_ORDER_STATUS_DATA = "GET_ORDER_STATUS_DATA";
