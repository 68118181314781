import * as actionTypes from "../action";

const initialState = {
  LoginLoader: false,
  registerLoader: false,
  verifyOtpLoader: false,
  resendOtpLoader: false,
  isUserLoggedIn: false,
  isUserVerified: false,
  userProfileData: {},
  uploadLoader: false,
  PictureData: {},
  updateUserLoader: false,
  forgotPasswordLoader: false,
  resetPasswordLoader: false,
  changePasswordLoader: false,
  country: [],
  changeMobileLoader: false,
  countryLoader: false,
  drawDetailLoader: false,
  drawDetailData: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_LOADING:
      return { ...state, LoginLoader: action.payload };

    case actionTypes.REGISTER_LOADING:
      return { ...state, registerLoader: action.payload };

    case actionTypes.VERIFY_OTP_LOADING:
      return { ...state, verifyOtpLoader: action.payload };

    case actionTypes.RESEND_OTP_LOADING:
      return { ...state, resendOtpLoader: action.payload };

    case actionTypes.CHANGE_MOBILE_DATA:
      return { ...state, userProfileData: action.payload };

    case actionTypes.VERIFY_OTP:
      return {
        ...state,
        userProfileData: { ...state.userProfileData, roles: action.payload },
      };

    case actionTypes.IS_USER_LOGGED_IN:
      return { ...state, isUserLoggedIn: action.payload };

    case actionTypes.IS_USER_VERIFIED:
      return { ...state, isUserVerified: action.payload };

    case actionTypes.FORGOT_PASSWORD_LOADER:
      return { ...state, forgotPasswordLoader: action.payload };

    case actionTypes.CHANGE_PASSWORD_LOADER:
      return { ...state, changePasswordLoader: action.payload };

    case actionTypes.CHANGE_MOBILE_LOADER:
      return { ...state, changeMobileLoader: action.payload };

    case actionTypes.RESET_PASSWORD_LOADER:
      return { ...state, resetPasswordLoader: action.payload };

    case actionTypes.USER_PROFILE_DATA:
      return {
        ...state,
        userProfileData: { ...state.userProfileData, ...action.payload },
      };
    case actionTypes.REMOVE_USER_PROFILE_DATA:
      return {
        ...state,
        userProfileData: action.payload,
      };

    case actionTypes.UPDATE_USER_LOADING:
      return { ...state, updateUserLoader: action.payload };

    case actionTypes.UPLOAD_PIC_LOADING:
      return { ...state, uploadLoader: action.payload };

    case actionTypes.UPLOAD_PIC_DATA:
      return { ...state, PictureData: action.payload };

    case actionTypes.SET_PROFILE:
      return {
        ...state,
        userProfileData: {
          ...state.userProfileData,
          profile_pic: action.payload.response.data.profile_pic,
          file: action.payload,
        },
      };

    case actionTypes.GET_COUNTRY_LOADER:
      return { ...state, countryLoader: action.payload };

    case actionTypes.GET_COUNTRY_DATA:
      return { ...state, country: action.payload };

    case actionTypes.GET_DRAW_DETAIL_LOADING:
      return { ...state, drawDetailLoader: action.payload };

    case actionTypes.GET_DRAW_DETAIL_DATA:
      return {
        ...state,
        drawDetailData: action.payload,
      };

    default:
      return state;
  }
};
export default authReducer;
