import { Button, Col, DatePicker, Form, Input, Radio, Row, Select } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  actionChangeMobile,
  actionGetCountries,
  actionIsVerified,
  actionResendOtp,
  actionUpdateUserProfile,
  actionVerifyOtp,
} from "../../store/actions/authAction";

const PersonalDetail = (props) => {
  const { userProfileData, actionUpdateUserProfile, updateUserLoader } = props;
  const [form] = Form.useForm();

  useMemo(() => {}, [userProfileData]);
  console.log(userProfileData.profile_pic);
  useEffect(() => {
    userProfileData &&
      form.setFieldsValue({
        first_name: userProfileData.first_name,
        last_name: userProfileData.last_name,
        email: userProfileData.email,
        gender: userProfileData.gender,
        profile_pic: userProfileData.profile_pic,
        country_phone_code: userProfileData.country_phone_code,
        phone: userProfileData.phone,
        country_of_residence: userProfileData.country_of_residence,
        nationality: userProfileData.nationality,
        date_of_birth: userProfileData.date_of_birth
          ? moment(userProfileData.date_of_birth)
          : undefined,
      });
  }, [userProfileData]);

  const onFinish = (values) => {
    const data = {
      ...values,
      profile_pic: userProfileData.profile_pic
        ? userProfileData.profile_pic.split("/").pop()
        : "",
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
    };
    // console.log(userProfileData.file.response.data.profile_pic, "data");
    actionUpdateUserProfile(data, userProfileData._id);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  return (
    <div className="container">
      <h1 className="font-bold">
        Personal <span className="text-line"> Details</span>
      </h1>
      <Form
        style={{ marginTop: "30px" }}
        name="basic"
        form={form}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        size="large"
      >
        <Row align="space-between" gutter={[24, 16]}>
          <Col span={12}>
            <h3>First Name</h3>
            <Form.Item
              name="first_name"
              normalize={(value) => value.replace(/[^a-zA-Z]/g, "").trim()}
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
                {
                  validator: (_, value) =>
                    value.length <= 15
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "Please enter maximum 15 characters for first Name"
                          )
                        ),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>Last Name</h3>
            <Form.Item
              name="last_name"
              normalize={(value) => value.replace(/[^a-zA-Z]/g, "").trim()}
              rules={[
                {
                  required: true,
                  message: "Please input your last name!",
                },
                {
                  validator: (_, value) =>
                    value.length <= 15
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "Please enter maximum 15 characters for last Name"
                          )
                        ),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>Email</h3>
            <Form.Item
              name="email"
              normalize={(value) => value.trim()}
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>Date of birth</h3>
            <Form.Item
              name="date_of_birth"
              rules={[
                {
                  required: true,
                  message: "Please select your date !",
                },
              ]}
            >
              <DatePicker
                className="color-general"
                disabledDate={disabledDate}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <h3>Country Code</h3>
            <Form.Item
              name="country_phone_code"
              rules={[
                {
                  required: true,
                  message: "Please select your Country !",
                },
              ]}
            >
              <Select
                showSearch
                style={
                  {
                    //width: 180,
                  }
                }
                placeholder="Select country code"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "+91",
                    label: "+91",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={18}>
            <h3>
              Phone{" "}
              {/* <span
                style={{ cursor: "pointer", color: "#e70736" }}
                onClick={changeMobileModal}
              >
                - Change mobile Number
              </span> */}
            </h3>
            <Form.Item
              name="phone"
              normalize={(value) => value.trim()}
              rules={[
                {
                  required: true,
                  message: "Please input your phone!",
                },
                {
                  validator: (_, value) =>
                    value.length === 10
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Please enter 10 digits for Phone Number")
                        ),
                },
              ]}
            >
              <Input disabled={true} type="number" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>Nationality</h3>
            <Form.Item
              name="nationality"
              normalize={(value) => value.trim()}
              rules={[
                {
                  required: true,
                  message: "Please input your nationality!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select nationality"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "Indian",
                    label: "Indian",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>Country of residence</h3>
            <Form.Item
              name="country_of_residence"
              normalize={(value) => value.trim()}
              rules={[
                {
                  required: true,
                  message: "Please input your country of residence!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please select your gender!",
                },
              ]}
            >
              <Radio.Group size="large">
                <Radio value={"M"}>Male</Radio>
                <Radio value={"F"}>Female</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button
                loading={updateUserLoader}
                size="large"
                className="button-comman"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    verifyOtpLoader: state.auth.verifyOtpLoader,
    updateUserLoader: state.auth.updateUserLoader,
    changeMobileLoader: state.auth.changeMobileLoader,
  };
};
export default connect(mapStateToProps, {
  actionUpdateUserProfile,
  actionGetCountries,
  actionVerifyOtp,
  actionResendOtp,
  actionIsVerified,
  actionChangeMobile,
})(PersonalDetail);
