import React, { useEffect } from "react";
import coupon from "../../assets/comman/Coupon-new.png";
import couponLogo from "../../assets/comman/coupen-logo.png";
import cashWin from "../../assets/comman/cashWin.png";
import { Button, Col, Row, Empty, Card, Divider, Spin } from "antd";
import { actionGetActiveTickets } from "../../store/actions/ticketsAction";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import dayjs from "dayjs";

const ActiveCoupon = (props) => {
  const { actionGetActiveTickets, activeTicketLoader, activeTicketData } =
    props;

  useEffect(() => {
    actionGetActiveTickets();
  }, []);
  return (
    <div className="container active-coupon ">
      <h1 className="font-bold">
        <span className="text-line">Tickets</span>
      </h1>
      <div className="spacer" />
      <Spin spinning={activeTicketLoader}>
        {activeTicketData && activeTicketData.length > 0 ? (
          <div className="order-list-overflow">
            <Row gutter={[16, 16]}>
              {activeTicketData &&
                activeTicketData.length &&
                activeTicketData.map((data, index) => {
                  return (
                    <Col xxl={12} xl={20} md={24} sm={24} key={index}>
                      <Card style={{ marginRight: 8 }}>
                        <Row>
                          <Col span={18}>
                            <img src={couponLogo} alt="logo-coupon" />
                            <div className="spacer" />
                            <p className="coupon-title">
                              {data.draw.draw_title}
                            </p>
                            <p className="coupon-title">
                              Product - {data.draw.product_title}
                            </p>
                            <p className="coupon-title">
                              {/* Purchased on - {dayjs(data.draw.createdAt).format('DD/MM/YY HH:mm A')} */}
                              Purchased on -{" "}
                              {dayjs(data.purchased_on).format(
                                "DD,MMMM YYYY HH:mm A"
                              )}
                            </p>
                          </Col>
                          <Col span={6} align="right">
                            <img
                              width={80}
                              className="coupon-background"
                              src={data.draw.draw_image}
                              stc="cash-logo"
                            />
                          </Col>
                          <Col span={24}>
                            <Divider />
                          </Col>
                          <div className="left-center-radius"></div>
                          <div className="right-center-radius"></div>
                          <Col span={24} align="right">
                            <p>
                              <span className="coupon-title">Ticket no -</span>
                              <span className="pink-text">
                                {data.draw_ticket}
                              </span>
                            </p>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </div>
        ) : (
          <div style={{ margin: "100px" }}>
            <Row>
              <Col span={24} align="middle">
                <Empty
                  image={coupon}
                  description="You can view active coupons here after you make your purchase"
                />
                <div className="spacer" />
                <Link to="/">
                  <Button size="large" className="button-comman">
                    Start Shoping
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        )}
      </Spin>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    activeTicketLoader: state.tickets.activeTicketLoader,
    activeTicketData: state.tickets.activeTicketData,
  };
};

export default connect(mapStateToProps, { actionGetActiveTickets })(
  ActiveCoupon
);
