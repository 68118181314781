import { Col, Row, Spin, Button, Image } from "antd";
import React, { useEffect, useMemo } from "react";
import Slider from "react-animated-slider";
import { connect } from "react-redux";
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';
import { actionGetBanners } from "../../store/actions/homeAction";

const HeroSection = (props) => {
  const { actionGetBanners, bannerLoader, bannerData } = props;

  useMemo(() => { }, [bannerData]);

  useEffect(() => {
    actionGetBanners();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots:dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
    }
    // nextArrow: lefticon,
    // prevArrow: righticon,
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  return (
    <>
      <Spin spinning={bannerLoader}>
        {/* {
        dataNew &&
        dataNew.length > 0 &&  */}

        {
          bannerData && bannerData.length > 0 &&
          <Slider {...settings} className="slider-wrapper unclick-div">

            {bannerData &&
              bannerData.map((data, index) => {
                return (
                  <div
                    className="hero-section"
                    key={index}
                    style={{
                      backgroundImage: `url(${data.background_image})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <Row className="hero-container">
                      <Col span={14} className="padding">
                        {/* <h2 className="white">{data.title}</h2> */}
                        <div className="heading white">{data.title}</div>
                        <h2 className="white">{data.description}</h2>
                        <Button
                          href={data.btn_url}
                          className="detail-btn"
                          size="large"
                        >
                          {data.btn_text}
                        </Button>
                      </Col>
                      <Col span={10} className="hero-midd">
                        <Image preview={false} src={data.image} alt="" />
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </Slider>
        }

      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerLoader: state.home.bannerLoader,
    bannerData: state.home.bannerData,
  };
};

export default connect(mapStateToProps, { actionGetBanners })(HeroSection);
