import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { actionResetPassword } from "../../store/actions/authAction";

const ResetPassword = (props) => {
  const Navigate = useNavigate();
  const { actionResetPassword, resetPasswordLoader } = props;

  const token = useLocation();
  const tokenSubmit = token.search.split("=")[1];

  const onFinish = (values) => {
    actionResetPassword(
      { password: values.password, token: tokenSubmit ? tokenSubmit : "" },
      Navigate
    );
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div style={{ padding: "100px" }}>
      <div className="container activelink">
        <Card
          style={{
            width: 400,
            margin: "auto",
            padding: "24px",
            borderRadius: "10px ",
          }}
        >
          <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
            Reset Password
          </h1>
          <div className="spacer" />
          <Form
            autoComplete="off"
            requiredMark={"optional"}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              colon={false}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 8,
                  message:
                    "passwords must be longer than or equal to 8 characters !",
                },
              ]}
            >
              <Input.Password className="border-input" placeholder="Password" />
            </Form.Item>
            <div className="spacer" />
            <Form.Item
              name="cpassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please Confirm Your Password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                className="border-input"
                placeholder="Confirm Password"
              />
            </Form.Item>

            <div className="spacer" />

            <Form.Item>
              <Button
                className="button-comman"
                loading={resetPasswordLoader}
                htmlType="submit"
                size="medium"
                style={{ width: 180, color: "black", float: "left" }}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    resetPasswordLoader: state.auth.resetPasswordLoader,
  };
};

export default connect(mapStateToProps, { actionResetPassword })(ResetPassword);
