import React from "react";
import { Typography } from "antd";
const PrivacyPolicy = () => {
  const { Title } = Typography;
  return (
    <div className="wrapper">
      <Title className="text-line" level={1}>
        {" "}
        Privacy Policy{" "}
      </Title>
      <div className="spacer"></div>
      <br />

      <p>
        We at Granedalz are committed to protect and safeguard your privacy.
      </p>
      <br />
      <h2>SCOPE</h2>
      <br />
      <ul>
        <li>
          This privacy policy sets out how Supergran Dealz LLP (“Grandealz”,
          “We”, “Our”, “Us”) uses and protects any information that you give to
          Grandealz when you use this website and/or Grandealz Mobile
          Application (“Platform”). This privacy policy (“Privacy Policy”),
          together with the Terms governs your use of the Grandealz Platform. By
          using, browsing, accessing, or purchasing from the Grandealz Platform
          you agree to be bound by this Privacy Policy and give consent to the
          collection, storage, possession, handling, sharing, disclosure or
          transfer of your information in accordance with the provisions of the
          Privacy Policy.
        </li>
        <br />
        <li>
          Our privacy policy is subject to change at any time without any
          prionotice. To make sure you are aware of any changes, please review
          this policy periodically.
        </li>
        <br />
        <li>
          This Policy does not apply to Personal Information collected from you
          offline (unless otherwise specified), to Customers/Users of countries
          other than India or to third-party websites to which Platform may
          link.
        </li>
        <br />
        <li>
          By accessing and using our Platform, you shall be deemed to have
          agreed to accept the Privacy Policy.
        </li>
        <br />
        <li>
          We encourage you to read this Policy regarding the collection, use,
          and disclosure of your information. If you do not agree with any of
          the terms or policies described in this Policy, you shall choose to
          discontinue using the Platform.
        </li>
        <br />
        <li>
          For the purpose of this Policy, the term “Personal Information” shall
          mean any information that relates to you or is capable of identifying
          you, which is submitted to and/or collected over the Platform,
          including without limitation, name, address, phone number, email
          address, gender, age, birth date.
        </li>
        <br />
        <li>
          We may use your Personal Information to verify your identity; create
          and give access to your user account on the Platform; fulfil the
          purchase requests; administer contests or promotional events; let you
          participate in the promotional schemes offered by Grandealz;
          distribute prize/gifts (if any) won by you in Promotional Schemes;
          communicate with you about your account and activities on the
          Platform; provide marketing communications; respond to your requests
          and queries; comply with legal obligations; help us learn about your
          shopping preferences; do internal research on our customers’
          demographics, interests, and behaviour to better understand and serve
          you; resolve your query or dispute; prevent, detect, investigate and
          take action against any illegal activities, suspected fraud, or
          violations of Company’s terms and conditions; establish, exercise or
          defend legal rights in connection with ongoing or prospective legal
          proceedings and seek professional or legal advice in relation to such
          legal proceedings; and comply with any applicable law,
          regulation, legal process or enforceable governmental request.
        </li>
      </ul>
      <br />
      <h2>COLLECTION OF INFORMATION:</h2>
      <ul>
        <li>
          Grandealz limits itself to collect information in order to provide
          accurate and efficient services to its users and for the purposes as
          mentioned in this Policy.
        </li>
        <li>
          You agree to provide information, that is true, correct, up to date
          and accurate. You may access, amend, alter or delete your information
          partially or fully by logging into your account on the Platform.
        </li>
        <li>
          To protect your privacy, you should not provide Grandealz with any
          information that is not specifically requested or that you do not wish
          to share. However, by not giving certain information and details as
          may be required by Grandealz, you may not be able to use our
          Platform/Services fully.
        </li>
        <li>
          We may automatically track certain information about you based upon
          your behaviour on our Platform. We use this information to do internal
          research on our users' demographics, interests, and behaviour to
          better understand, protect and serve our users.
        </li>
        <li>
          In order to use certain features of the Platform, you may be required
          to register and create an account. An online registration form may
          require you to provide information, including without limitation, your
          name, email address, and a password. It is your responsibility to
          protect the confidentiality of your User Account credentials. Do not
          share your password with any third parties. If your password has been
          compromised for any reason, you should change it immediately.
        </li>
        <li>
          We might receive information about you from other sources, such as
          updated delivery and address information from third parties, which we
          use to correct our records and deliver your next purchase more easily.
          Further, we may receive information about you from third parties that
          feature our Products or promotional offers if you opt-in to receive
          information from us. You may also choose to participate in a
          third-party application or social media sites through which you allow
          us to collect (or the third party to share) Personal Information about
          you, including usage information. We may also receive information,
          such as marketing related or demographic information about you from
          third parties to enhance our ability to tailor our content and offer
          you the Products that we believe may be of interest to you. Grandealz
          is not responsible for the privacy practices of such third-party
          websites which it does not own, manage or control.
        </li>
      </ul>
      <br />
      <h2>DISCLOSURE OF INFORMATION:</h2>
      <ul>
        <li>
          We may from time to time be required to disclose the information
          collected from you to our third-party service providers who assist us
          in order to facilitate the provision of services and purchase of
          Products on our Platform. By using our Platform, you consent to any
          such disclosure of your information with third party service
          providers.
        </li>
        <li>
          We may also disclose your information when such disclosure is required
          under any law or judicial decree or when we, in our sole discretion,
          deem it necessary in order to protect our rights or the rights of
          other Users, to prevent harm to the Platform.
        </li>
        <li>
          A third-party payment gateway provider may be required to collect
          certain financial information from you including, but not restricted
          to, your credit/debit card number or your bank account details
          (collectively referred to as “Financial Information”) for billing and
          payment processes. We shall not be responsible for any actions or
          inactions of third-party payment gateway providers or any breach of
          conditions, representations and warranties given by them.
        </li>
      </ul>
      <br />
      <h2>COOKIES</h2>
      <br />
      <ul>
        <li>
          We may track your preferences and activities on the Platform.
          “Cookies” are small data files transferred to your computer's
          hard-drive by a website, while using a web browser. They keep a record
          of your activities on the Platform making your subsequent visits to
          the site more efficient. The use of cookies is a common practice
          adopted to provide better shopping experience to the Users.
        </li>
        <li>
          By continuing the use of the Platform, you are agreeing to our use of
          cookies. If you do not agree to our use of cookie, you can block them
          in your browser setting.
        </li>
        <li>
          We use cookies to understand and save Customer’s preferences to make
          their future visits of our Platform more efficient; keep track of
          advertisements; to improve the browsing experience of our Users.
        </li>
        <br />
      </ul>
      <h2>THIRD PARTY SITES:</h2>
      <br />
      <ul>
        <li>
          Our Platform may contain links to Third Party Websites/Applications.
          However, once you have used these links to leave our site, you should
          note that we do not have any control over the said Third Party
          Websites/Applications. Therefore, we cannot be responsible for the
          protection and privacy of any information which you provide while
          visiting such Websites/Applications. Please note that such Third Party
          Websites/Applications are not governed by this Privacy Policy. You
          should exercise caution and go through the Privacy Policy applicable
          to the Websites/Applications in question.
        </li>
        <li>
          We may from time to time maintain social media presence. You can also
          interact with us through social media. When you interact with us using
          social media, we may receive information such as your user ID, your
          profile picture, photos you post, and similar information, which is
          often determined by your privacy settings on the relevant social media
          sites. We may use the information for the purposes we have described
          throughout this Policy and/or Website Terms & Conditions. Your use of
          social media sites is primarily governed by the site operators’
          privacy policies and terms of service, and the information you share
          with us and with others is largely controlled by the privacy settings
          you have established on those sites. Grandealz will not be responsible
          for storage, use and transmission of your information on such social
          media sites.
        </li>

        <br />
      </ul>
      <h2>OPT OUT OPTION:</h2>
      <br />
      <p>
        We provide all users with the opportunity to opt-out from receiving
        non-essential (promotional, marketing-related) communications from us.
        If you want to remove your contact information from our lists, please
        write to us at the details mentioned below or unsubscribe from the
        communications by clicking on the links for unsubscribing provided in
        such communications.
      </p>
      <br />
      <h2>AMENDMENT OR DELETION OF PERSONAL INFORMATION:</h2>
      <br />
      <p>
        If you would like to amend or delete your Personal Information which is
        stored by us, you can send us an email at the address given below in
        this email. We try our best to respond to such requests expeditiously.
      </p>
      <br />
      <h2>AGE</h2>
      <br />
      <p>
        Our Platform is not designed for persons under the age of 18. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 18. If you are under 18 years of age, you should refrain from
        browsing and using our Platform. If we are made aware that we have
        collected the Personal Information of a person below the age of 18
        years, we will take steps to delete the information within a reasonable
        time frame. In the event, where in contravention to the Privacy Policy
        and Terms, a person below the age of 18 uses our Platform or Services,
        we shall not be held liable or responsible for any damage or injury
        suffered by such person.
      </p>
      <br />
      <h2>CONSENT</h2>
      <br />
      <p>
        By using the Platform and/ or by providing your information, you consent
        to the collection and use of the information you disclose on the
        Platform in accordance with this Privacy Policy and Terms mentioned on
        this Website. If you have consented to the collection, use and/or
        disclosure of your Personal Information, you have the right to withdraw
        this consent at any time by writing to us at the email address mentioned
        below in this Policy. However, please note that if you withdraw your
        consent, we may no longer be able to provide you with the corresponding
        service for which you have withdrawn your consent. Please note that any
        processing that we have carried out before the withdrawal of your
        consent remains lawful. Notwithstanding such request, your information
        may be retained to comply with our legal obligations, resolve disputes
        and enforce our agreements.
      </p>
      <br />
      <h2>COMMENTS, FEEDBACK AND REVIEW:</h2>
      <br />
      <p>
        If you post any feedback, reviews, comments or content on our Platform
        or other social media pages of Grandealz, you should be aware that the
        same can be read, collected, or used by the third parties. We are not
        responsible for the information you choose to submit, and we cannot
        guarantee that third parties have not made copies of or will not use
        such information in any way.
      </p>
      <br />
      <h2>THIRD PARTY ANALYTICS:</h2>
      <br />
      <p>
        We may use third-party analytics services to evaluate your use of our
        Platform, compile reports on activity, collect demographic data, analyse
        performance metrics, and collect and evaluate other information relating
        to the Platform. These third parties use cookies and other technologies
        to help analyse and provide us the information. You consent to the
        processing of information about you by these analytics providers for the
        purposes set out in this Policy.
      </p>
      <br />
      <h2> PRIVACY CONCERN:</h2>
      <br />
      <p>
        If you are concerned about the handling of your Personal Information, or
        if you have any complaints or queries related to your Personal
        Information or our Policy, please contact:
        {/* <br /> Name: ________________ */}
        <br /> Email: info@grandealz.com
        <br /> Phone: +91-855-534-9865
        <br />
      </p>
      <br />
      <h2>DATA SECURITY:</h2>
      <br />
      <p>
        Though we endeavour to protect the Personal Information against loss or
        theft, as well as unauthorized access, disclosure, use or modification
        with appropriate security safeguards we do not guarantee that there will
        be no unintended disclosures of any information and potential security
        breaches. We shall not be liable to any user for any loss, damage or
        harm caused to the user due to the unauthorized access or misuse of the
        Personal Information by any third party or due to events that are beyond
        our reasonable control.
      </p>
      <br />
      <br />
      <h2>WEBSITE TERMS AND CONDITIONS:</h2>
      <br />
      <p>
        This Privacy Policy is in continuation of the Website Terms &
        Conditions. In case of any discrepancy between the two, the provisions
        of Terms & Conditions shall prevail.
      </p>
      <br />

      <h2>Products Return/Exchange Policy:</h2>
      <br />
      <p>
        At Grandealz, we always endeavour to give you the best shopping
        experience possible. In case if the Product delivered is wrong, damaged
        or defective, you can raise a request for exchange by contacting our
        Customer Care, provided the following conditions are met:
      </p>
      <br />
      <ul style={{ listStyleType: "" }}>
        <li>
          <p>
            If you receive a wrong, damaged or defective Product, you shall
            inform us within 48 hours of receipt, with proof of purchase and
            supporting images and/or video. Our Customer Care team will review
            your request and, if deemed valid, initiate the exchange process as
            provided herein and under the Terms.
          </p>
        </li>
        <li>
          <p>
            Grandealz will not responsible for any damage caused after delivery.
            Damages due to neglect, improper usage or wrong application by the
            User will not be covered.
          </p>
        </li>
        <li>
          <p>
            Exchange Request for products will be accepted only if the products
            are returned in their original packaging, in an unused condition.
          </p>
        </li>
        <li>
          <p>
            Any exchange request initiated after five business days from receipt
            of the Product by the User will be entertained at the sole
            discretion of Grandealz.
          </p>
        </li>
        <li>
          <p>
            It is hereby clarified that exchange request will be entertained by
            Grandealz only in case if the product delivered is wrong, damaged or
            defective and in no other case.{" "}
          </p>
        </li>
        <li>
          <p>
            The Product will be exchanged and replaced with another piece of the
            identical description. The availability of exact replacements of the
            Product may be subject to stock availability with Grandealz. In such
            cases, Grandealz will work with you to find a suitable solution,
            which may include offering alternative Products, at Grandealz’ s
            discretion.
          </p>
        </li>
        <li>
          <p>
            Several types of Products are exempt from being exchanged, namely,
            the Products perishable in nature, intimate or sanitary items,
            innerwear, swimwear, personal care products.
          </p>
        </li>
        <li>
          <p>
            Whether Grandealz will provide pick up services on approval of
            Exchange request or the User will have to self-ship the Product will
            depend on the type of the Product and the location of the User.
            Grandealz will inform the User whether it will offer Pick up service
            or the User will have to self-ship the Product at the time Grandealz
            accepts the Exchange Request placed through its customer care
            division.
          </p>
        </li>
        <li>
          <p>
            In case of self-ship of Products by the User, the shipping cost will
            be reimbursed subject to the User’s return of the Product having met
            Grandealz’s Exchange Policy and a copy of courier receipt is shared
            by the User and validated by Grandealz. For self-ship returns, the
            Exchange process will only be initiated if the returned Product
            passes through a quality check conducted by Grandealz. If the
            quality check fails, the Product will be reshipped back to you.
          </p>
        </li>
        <li>
          <p>
            By using our platform, you agree to comply with our exchange policy
            and procedures. We reserve the right to modify or update the
            exchange policy at any time. In the event the modified exchange
            policy is not acceptable to you, you shall discontinue browsing
            through the Platform and using our services. However, if you
            continue to use the Platform, you shall be deemed to have agreed
            with the exchange policy.
          </p>
        </li>
        <li>
          <p>
            All sales under this Platform are final and Grandealz does not offer
            any Money Back Guarantee. You recognise and agree that you are not
            entitled to a refund for any purchase made through the Platform,
            unless expressly provided by Grandealz.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
