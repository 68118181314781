// const mode = "PROD";
const mode = "DEV";

const BASE_URL =
  mode === "PROD"
    ? "https://api.grandealz.com"
    : "https://api.grandealz.vytech.co";
const BASE_URL_UPLOAD =
  mode === "PROD"
    ? "https://api.grandealz.com/uploads"
    : "https://api.grandealz.vytech.co/uploads";

const BASE_URL_UPLOAD_PROFILE =
  mode === "PROD"
    ? "https://api.grandealz.com/uploads/upload-profile-pic"
    : "https://api.grandealz.vytech.co/uploads/upload-profile-pic";

const APIKET_RZP =
  mode == "PROD" ? "rzp_live_TtHYTIXQ3kCfqo" : "rzp_test_aHECB7xFOIol9J";
module.exports = {
  BASE_URL,
  BASE_URL_UPLOAD,
  APIKET_RZP,
  BASE_URL_UPLOAD_PROFILE,
};
