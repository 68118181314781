import { Card, Col, Row, Typography } from "antd";
import React from "react";
const { Title } = Typography
const Faq = () => {
  return (
    <>
      <div className="wrapper">
        <Title className="text-line" level={1}> Frequently asked questions </Title>
        <div className="spacer"></div>
        <br />
        <br />

        <p className="faq-text">About Us</p>
        <Row align="middle" gutter={[16, 8]}>
          {/* <Col span={12} align="left">
            <Card
              title={<p className="faq-card-text">How can I participate in the prize giveaways?</p>}
              bordered={false}
            >
              <p className="faq-paragraph" >
                To participate in the prize giveaways at Grandealz, all you need to do is make a purchase. With every purchase you make, you automatically earn an entry into the exciting prize draws. The more purchases you make, the more chances you have to win incredible rewards!
              </p>
            </Card>
          </Col> */}
          <Col span={12} align="left">
            <Card
              title={<p className="faq-card-text">Are the payment options secure?</p>}
              bordered={false}
              style={{}}
            >
              <p className="faq-paragraph" >
                Yes, Grandealz prioritizes the security of your transactions. We provide secure payment gateways that encrypt your financial information, ensuring your payment details are protected. You can shop with confidence knowing that your personal and payment information is handled with the utmost security.
              </p>
            </Card>
          </Col>
          <Col span={12} align="left">
            <Card
              title={<p className="faq-card-text">How long does it take to receive my order?</p>}
              bordered={false}
              style={{}}
            >
              <p className="faq-paragraph" >
                Grandealz aims to provide prompt and reliable delivery services. The exact delivery time may vary depending on your location and the availability of the product. However, we strive to deliver your order as quickly as possible. You can expect an estimated delivery time frame to be provided during the checkout process.
              </p>
            </Card>
          </Col>
          <Col span={12} align="left">
            <Card
              title={<p className="faq-card-text">Can I get personalized product recommendations?</p>}
              bordered={false}
              style={{}}
            >
              <p className="faq-paragraph" >
                Yes, Grandealz offers personalized product recommendations to enhance your shopping experience. Based on your preferences and browsing history, our system suggests tailored product suggestions to help you discover items that match your interests. This feature enables you to find relevant and appealing products more easily.
              </p>
            </Card>
          </Col>
          <Col span={12} align="left">
            <Card
              title={<p className="faq-card-text">What if I need assistance or have a question?</p>}
              bordered={false}
              style={{}}
            >
              <p className="faq-paragraph" >
                Grandealz provides 24/7 customer support to assist you with any queries or concerns. If you have questions about a product, an order, or the prize giveaways, you can reach out to our dedicated support team at any time. We are committed to ensuring that your shopping journey is smooth and enjoyable, and we are here to help you every step of the way.
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Faq;
