import * as actionTypes from "../action";

const initialState = {
  activeTicketLoader: false,
  activeTicketData: [],
  coupanLoader: false,
  coupanData: [],
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTIVE_TICKETS_LOADER:
      return { ...state, activeTicketLoader: action.payload };

    case actionTypes.ACTIVE_TICKETS_DATA:
      return { ...state, activeTicketData: action.payload };

    case actionTypes.GET_COUPAN_LOADER:
      return { ...state, coupanLoader: action.payload };

    case actionTypes.GET_COUPAN__DATA:
      return { ...state, coupanData: action.payload };

    default:
      return state;
  }
};
export default ticketsReducer;
