import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";

export const actionGetOrders =
  (skip, limit, search, status, start_date, end_date) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ORDERS_LOADER, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/orders/?limit=${limit}&skip=${skip}${
          search ? "&search=" + search : ""
        }${status ? "&status=" + status : ""}${
          start_date ? "&start_date=" + start_date : ""
        }${end_date ? "&end_date=" + end_date : ""}`
      );
      dispatch({ type: actionTypes.GET_ORDERS_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_ORDERS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_ORDERS_COUNT,
          payload: result.data.total_records,
        });
        return true;
      } else {
        dispatch({
          type: actionTypes.GET_ORDERS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_ORDERS_COUNT,
          payload: 0,
        });
        return false;
      }
    } catch (error) {
      dispatch({ type: actionTypes.GET_ORDERS_LOADER, payload: false });
      return false;
    }
  };

export const actionGetOrderDetail = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_SINGLE_ORDER_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/orders/${id}`);
    dispatch({ type: actionTypes.GET_SINGLE_ORDER_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_SINGLE_ORDER_DATA,
        payload: result.data.data,
      });
    } else
      dispatch({
        type: actionTypes.GET_SINGLE_ORDER_DATA,
        payload: result.data.data,
      });
  } catch (error) {
    dispatch({ type: actionTypes.GET_SINGLE_ORDER_LOADER, payload: false });
  }
};

export const actionAddAllocateTickets =
  (data, setQuizGame, setGetTicket) => async (dispatch) => {
    dispatch({
      type: actionTypes.ADD_ALLOCATE_TICKETA_ON_ORDER_LOADER,
      payload: true,
    });
    try {
      let result = await axios.post(
        `${BASE_URL}/orders/allocate-tickets/${data}`
      );
      dispatch({
        type: actionTypes.ADD_ALLOCATE_TICKETA_ON_ORDER_LOADER,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_ALLOCATE_TICKETA_ON_ORDER_DATA,
          payload: result.data.data,
        });
        setQuizGame && setQuizGame(false);
        setGetTicket && setGetTicket(true);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      dispatch({
        type: actionTypes.ADD_ALLOCATE_TICKETA_ON_ORDER_LOADER,
        payload: false,
      });
    }
  };

export const actionCreateBpayOrder = (userData) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_BPAY_ORDER_LOADER, payload: true });
  try {
    let result = await axios.post(
      `${BASE_URL}/orders/create-bpay-order`,
      userData
      // { self_pickup }
    );
    dispatch({ type: actionTypes.CREATE_BPAY_ORDER_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.CREATE_BPAY_ORDER_DATA,
        payload: result.data.data,
      });
      dispatch({
        type: actionTypes.GET_REF_ID,
        payload: result.data.payment_ref_id,
      });
      window.open(result.data.data.checkout_url, "_blank");
    } else {
      message.error(result.data.message, 5);
    }
  } catch (error) {
    dispatch({ type: actionTypes.CREATE_BPAY_ORDER_LOADER, payload: false });
    console.log(error, "error");
  }
};

export const actionBPayOrderStatus =
  (userData, setIsPayNowModalOpen) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ORDER_STATUS_LOADER, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/orders/bpay-payment-status`,
        userData
      );
      dispatch({ type: actionTypes.GET_ORDER_STATUS_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_ORDER_STATUS_DATA,
          payload: result.data.data,
        });
        console.log("dfdfg");
        console.log(result.data.data);
        console.log(result.data);
        setIsPayNowModalOpen && setIsPayNowModalOpen(true);
      } else if (parseInt(result.data.status) === 400) {
        dispatch({ type: actionTypes.GET_ORDER_STATUS_LOADER, payload: false });
        console.log("404 erorr");
      } else {
        // message.error(result.data.message, 5);
        dispatch({ type: actionTypes.GET_ORDER_STATUS_LOADER, payload: true });
        console.log(result.data, "error data");
        console.log("Other erorr");
      }
    } catch (error) {
      dispatch({ type: actionTypes.GET_ORDER_STATUS_LOADER, payload: false });
      console.log(error, "error");
    }
  };
