import * as actionTypes from "../action";

const initialState = {
  bannerLoader: false,
  bannerData: [],
  drawLoader: false,
  drawData: [],
  singleDrawLoader: false,
  singleDrawData: [],
  upcomingDrawLoader: false,
  upcomingDrawData: [],
  winnerLoader: false,
  winnerData: [],
  winnerCount: {},
  upcomingDrawCount: {},
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BANNERS_LOADER:
      return { ...state, bannerLoader: action.payload };

    case actionTypes.GET_BANNERS:
      return { ...state, bannerData: action.payload };

    case actionTypes.GET_DRAWS_LOADER:
      return { ...state, drawLoader: action.payload };

    case actionTypes.GET_DRAWS:
      return { ...state, drawData: action.payload };

    case actionTypes.GET_UPCOMING_DRAWS_LOADER:
      return { ...state, upcomingDrawLoader: action.payload };

    case actionTypes.GET_UPCOMING_DRAWS:
      return { ...state, upcomingDrawData: action.payload };

    case actionTypes.GET_SINGLE_DRAWS_LOADER:
      return { ...state, singleDrawLoader: action.payload };

    case actionTypes.GET_SINGLE_DRAWS:
      return { ...state, singleDrawData: action.payload };

    case actionTypes.GET_WINNER_LOADER:
      return { ...state, winnerLoader: action.payload };

    case actionTypes.GET_WINNER_DATA:
      return { ...state, winnerData: action.payload };

    case actionTypes.TOTAL_WINNERS_COUNT:
      return { ...state, winnerCount: action.payload };

    case actionTypes.TOTAL_UPCOMING_DRAW_COUNT:
      return { ...state, upcomingDrawCount: action.payload };

    default:
      return state;
  }
};
export default homeReducer;
