import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actionGetDraws } from "../../store/actions/homeAction";
import Campaigns from "./Campaigns";
import ClosingSoon from "./ClosingSoon";
import HeroSection from "./HeroSection";
import Products from "./Products";
import SoldOut from "./SoldOut";

const Home = (props) => {
  const { actionGetDraws, drawLoader, drawData } = props;

  useEffect(() => {
    actionGetDraws("Publish");
  }, []);

  return (
    <>
      <div style={{ height: "auto", overflow: "auto" }}>
        <HeroSection />
        {/* <ClosingSoon drawData={drawData} drawLoader={drawLoader} /> */}
        <Campaigns drawData={drawData} drawLoader={drawLoader} />
        <SoldOut drawData={drawData} drawLoader={drawLoader} />
        <Products drawData={drawData} drawLoader={drawLoader} />
        {/* <Draws />  */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    drawLoader: state.home.drawLoader,
    drawData: state.home.drawData,
  };
};

export default connect(mapStateToProps, { actionGetDraws })(Home);
