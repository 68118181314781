import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Segmented,
  Spin,
  Typography,
  Pagination,
  Select
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import filter from "../../assets/comman/filter.png";
import {
  actionGetUpcomingDraws,
  actionGetWinners,
} from "../../store/actions/homeAction";
import WinnerCard from "../common/WinnerCard";
const { Title, Text } = Typography;

const Draws = (props) => {
  const {
    actionGetUpcomingDraws,
    upcomingDrawLoader,
    upcomingDrawData,
    actionGetWinners,
    winnerLoader,
    winnerData,
    winnerCount,
    upcomingDrawCount,
  } = props;
  const [draws, setDraws] = useState("Winners");
  const [searchWinner, setSearchWinner] = useState("");
  const [drawDateWinner, setDrawDateWinner] = useState("");
  const [drawYearWinner, setDrawYearWinner] = useState("");
  const [searchUpcoming, setSearchUpcoming] = useState("");
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useMemo(() => { }, [upcomingDrawData]);

  useEffect(() => {
    //actionGetUpcomingDraws(0, 12, "UpComming");
    actionGetWinners(0, 12);
  }, []);

  const resetForm = () => {
    form.resetFields()
  };

  const onChangeSegmentValue = (e) => {
    form.resetFields()
    setSearchWinner("")
    setDrawDateWinner("")
    setDrawYearWinner("")
    setSearchUpcoming("")
    if (e === "Winners") {
      actionGetWinners(0, 12);
    } else {
      actionGetUpcomingDraws(0, 12, "UpComming");
    }
    setDraws(e);
  };

  const onFinish = (value) => {
    setSearchWinner(value.search ? value.search : "")
    setDrawDateWinner(value.draw_date ? dayjs(value.draw_date).format('YYYY-MM-DD') : "")
    setDrawYearWinner(value.draw_year ? dayjs(value.draw_year).format('YYYY') : "")
    const data = actionGetWinners(
      0,
      12,
      value.search ? value.search : "",
      value.draw_date ? dayjs(value.draw_date).format('YYYY-MM-DD') : "",
      value.draw_year ? dayjs(value.draw_year).format('YYYY') : "",
    );
    data && setIsModalOpen(false)
  };
  const onFinishUpcoming = (value) => {
    setSearchUpcoming(value.search ? value.search : "")
    const data = actionGetUpcomingDraws(0, 12, "UpComming", value.search ? value.search : "");
    data && setIsModalOpen(false)
  };

  const onPaginationWinner = (e, d) => {
    actionGetWinners(e - 1, d, searchWinner ? searchWinner : '', drawDateWinner ? drawDateWinner : '', drawYearWinner ? drawYearWinner : '');
  };

  const onPaginationUpcomingWinner = (e, d) => {
    actionGetUpcomingDraws(e - 1, d, "UpComming", searchUpcoming ? searchUpcoming : '');
  };

  return (
    <>
      <div className="draw-main" style={{ minHeight: "50vh" }}>
        <div className="draws-container">
          <Row align="middle" className="slider-title">
            <Col span={24} align="left">
              <Title className="title width" level={3}>
                Draws
              </Title>
            </Col>
          </Row>
          <br />
        </div>

        <div className="draw-main-sec">
          <Row justify="center" style={{ alignItems: "center" }}>
            <Col span={6}>
              <Segmented
                size="large"
                block
                options={["Winners", "Upcoming Draws"]}
                onChange={(e) => onChangeSegmentValue(e)}
              />
            </Col>
            <Col span={1} align="center">
              <img
                src={filter}
                onClick={showModal}
                style={{
                  cursor: "pointer",
                  background: "white",
                  padding: "10px",
                  borderRadius:"10px"
                }}
              />
            </Col>
          </Row>

          {draws === "Winners" ? (
            <>
              <Spin spinning={winnerLoader}>
                <div className="draw-wrapper">
                  {winnerData && winnerData.length > 0 ? (
                    <>
                      <Row gutter={[32, 16]}>
                        {winnerData &&
                          winnerData.length > 0 &&
                          winnerData.map((data, index) => {
                            return (
                              <Col key={index} md={8} lg={8} xl={8} xxl={6}>
                                <Card
                                  style={{
                                    padding: "15px",
                                    textAlign: "center",
                                  }}
                                  className="winner-card"
                                  cover={
                                    <>
                                      <Image
                                        src={data.draw_image}
                                        preview={false}
                                        width={150}
                                        alt="example"
                                      />
                                    </>
                                  }
                                >
                                  <p className="congratulation-text">
                                    Congratulation
                                  </p>
                                  <p className="winner-name-draw-title">
                                    {data.winner.user.first_name +
                                      " " +
                                      data.winner.user.last_name}
                                  </p>
                                  <p className="winner-title">
                                    on Winning{" "}
                                    <span className="winner-name-draw-title">
                                      {data.draw_title}
                                    </span>
                                  </p>
                                  <p className="ticket">
                                    Ticket No:- {data.winner.draw_ticket}
                                  </p>
                                </Card>
                              </Col>
                            );
                          })}
                      </Row>
                      <Row>
                        <Col
                          span={24}
                          align="left"
                          style={{ marginTop: "10px" }}
                        >
                          <Pagination
                            key={2}
                            size="small"
                            total={winnerCount}
                            defaultPageSize={12}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                            onChange={(e) => onPaginationWinner(e, 12)}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div className="draw-wrapper">
                      <p style={{ textAlign: "center", fontSize: "20px" }}>
                        There are no Winner Announced at the moment. Please try
                        again later.
                      </p>
                    </div>
                  )}
                </div>
              </Spin>
            </>
          ) : (
            <>
              <Spin spinning={upcomingDrawLoader}>
                {upcomingDrawData && upcomingDrawData.length > 0 ? (
                  <>
                    <div className="draw-wrapper">
                      <Row gutter={[32, 16]}>
                        {upcomingDrawData &&
                          upcomingDrawData.length > 0 &&
                          upcomingDrawData.map((data, index) => {
                            return (
                              <Col key={index} md={8} lg={8} xl={8} xxl={6}>
                                <Link to={`/product-detail/${data._id}`}>
                                  <WinnerCard
                                    image={data.draw_image}
                                    draw_title={data.draw_title}
                                    draw_sub_title={data.draw_sub_title}
                                    product_price={data.product_price}
                                  />
                                </Link>
                              </Col>
                            );
                          })}
                      </Row>
                      <Row>
                        <Col
                          span={24}
                          align="left"
                          style={{ marginTop: "10px" }}
                        >
                          <Pagination
                            key={1}
                            size="small"
                            total={upcomingDrawCount}
                            defaultPageSize={12}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                            onChange={(e) => onPaginationUpcomingWinner(e, 12)}
                          />
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <div className="draw-wrapper">
                    <p style={{ textAlign: "center", fontSize: "20px" }}>
                      There are no Upcoming Draws at the moment. Please try
                      again later.
                    </p>
                  </div>
                )}
              </Spin>
            </>
          )}

          <Modal
            footer={false}
            className="Draws-modal"
            title={
              <>
                <Row align="middle">
                  <Col align="left" span={4}>
                    <DownOutlined onClick={handleCancel} />
                  </Col>
                  <Col span={16} align="middle">
                    <Title level={3}>Filter</Title>
                  </Col>
                  <Col span={4} align="right">
                    <a
                      onClick={() => resetForm()}
                      style={{ color: "#E70736", fontSize: 18 }}
                    >
                      RESET
                    </a>
                  </Col>
                </Row>
              </>
            }
            open={isModalOpen}
            onOk={handleOk}
          //onCancel={handleCancel}
          >
            {
              draws === "Winners" ?

                <Form
                  name="forms"
                  form={form}
                  style={{ padding: "0px 10px" }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    name="search"
                  >
                    <Input
                      size="large"
                      placeholder="Search Here..."
                      prefix={<SearchOutlined />}
                    />
                  </Form.Item>
                  <div className="spacer"></div>
                  <Form.Item
                    name="draw_year"
                  >
                    <DatePicker size="large" picker="year" placeholder="Year" />
                  </Form.Item>
                  <div className="spacer"></div>
                  <Form.Item
                    name="draw_date"
                  >
                    <DatePicker size="large" picker="date" placeholder="date" />
                  </Form.Item>
                  <div className="spacer"></div>
                  <div style={{ textAlign: "center", marginTop: 25 }}>
                    <Form.Item>
                      <Button
                        style={{ width: "60%", height: 50 }}
                        size="large"
                        className="button-comman"
                        htmlType="submit"
                      >
                        Apply
                      </Button>
                    </Form.Item>
                    <div className="spacer"></div>
                  </div>
                </Form> :
                <Form
                  name="forms"
                  form={form}
                  style={{ padding: "0px 10px" }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinishUpcoming}
                  autoComplete="off"
                >
                  <Form.Item
                    name="search"
                  >
                    <Input
                      size="large"
                      placeholder="Search Here..."
                      prefix={<SearchOutlined />}
                    />
                  </Form.Item>
                  <div className="spacer"></div>
                  <div style={{ textAlign: "center", marginTop: 25 }}>
                    <Form.Item>
                      <Button
                        style={{ width: "60%", height: 50 }}
                        size="large"
                        className="button-comman"
                        htmlType="submit"
                      >
                        Apply
                      </Button>
                    </Form.Item>
                    <div className="spacer"></div>
                  </div>
                </Form>
            }
          </Modal>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    upcomingDrawLoader: state.home.upcomingDrawLoader,
    upcomingDrawData: state.home.upcomingDrawData,
    winnerLoader: state.home.winnerLoader,
    winnerData: state.home.winnerData,
    winnerCount: state.home.winnerCount,
    upcomingDrawCount: state.home.upcomingDrawCount,
  };
};

export default connect(mapStateToProps, {
  actionGetUpcomingDraws,
  actionGetWinners,
})(Draws);
