import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { useState } from "react";
import Swal from "sweetalert2";
import other from "../../assets/comman/download.png";
import upi from "../../assets/comman/upi_logo_icon_169316.png";
import { actionGetQuiz } from "../../store/actions/wishlistCartAction";

import { Link, useNavigate } from "react-router-dom";
import payLogo from "../../assets/comman/pay-logo.svg";

import axios from "axios";
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  APIKET_RZP,
  BASE_URL,
} from "../../../src/components/config/web-config";
import Logopaynow from "../../assets/comman/Logo-paynow.svg";
import getticket from "../../assets/comman/getticket.png";
import orderconfirm from "../../assets/comman/order-confirm.svg";
import orderimage from "../../assets/comman/order.svg";
import { actionIsVerified } from "../../store/actions/authAction";
import {
  actionAddAddress,
  actionGetAddress,
  actionGetCity,
  actionGetCountries,
  actionGetState,
} from "../../store/actions/loacationAction";
import {
  actionAddAllocateTickets,
  actionCreateBpayOrder,
  actionBPayOrderStatus,
} from "../../store/actions/orderAction";
import { actionEmptyCart } from "../../store/actions/wishlistCartAction";
const { Title, Text, Paragraph } = Typography;

const CartAddress = (props) => {
  const {
    actionAddAddress,
    addAddressLoader,
    actionBPayOrderStatus,
    createBPayOrderData,
    actionGetAddress,
    countryLoader,
    cartData,
    getPaymentSucessLoader,
    actionGetQuiz,
    quizLoader,
    quizData,
    countryData,
    stateLoader,
    actionIsVerified,
    actionCreateBpayOrder,
    stateData,
    userProfileData,
    cityLoader,
    cityData,
    actionEmptyCart,
    actionGetCountries,
    actionGetCity,
    actionGetState,
    addressLoader,
    addressData,
    addAddressData,
    actionAddAllocateTickets,
    allocateOrderTicketLoader,
  } = props;
  const [number, setNumber] = useState(1);
  const [radio, setRadio] = useState("");
  const [getOrderPaymentDetail, setGetOrderPaymentDetail] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPayNowModalOpen, setIsPayNowModalOpen] = useState(false);
  const [openStartModal, setOpenStartModal] = useState(false);
  const [quizGame, setQuizGame] = useState(false);
  const [error, setError] = useState(false);
  const [randomQuiz, setRandomQuiz] = useState("");
  const [getTicket, setGetTicket] = useState(false);
  const [open, setOpen] = useState(false);
  const [disableState, setDisableState] = useState(true);
  const [disableCity, setDisableCity] = useState(true);
  const [homeDisable, setHomeDisable] = useState(false);
  const [workDisable, setWorkDisable] = useState(false);
  const [OtherDisable, setOtherDisable] = useState(false);
  const [addressDisable, setAddressDisable] = useState(false);
  const [selectAddressPayNOw, setSelectAddressPayNOw] = useState(true);
  const [openOption, setOpenOption] = useState(false);
  const [BpayOrderId, setBpayOrderId] = useState("");
  const [PaymentStatus, setPaymentStatus] = useState(false);
  const [boolean, setBoolean] = useState(false);
  const [selectedAddresData, setSelectedAddresData] = useState({
    id: "",
    type: "",
    address: "",
  });
  const [OrderAllocateId, setOrderAllocateId] = useState("");

  const [paymentMethod, setPaymentMethod] = useState();

  const handleOpenOption = () => {
    setOpenOption(true);
  };

  console.log(selectedAddresData.address, "selectedAddresData");

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    if (e.target.value === "upi") {
      actionCreateBpayOrder({
        address: selectedAddresData.address,
        self_pickup: false,
      });
      setOpenOption(false);
      setBoolean(true);
      setPaymentMethod("");
    } else {
      setOpenOption(false);
      paymentHandler();
      setPaymentMethod("");
    }
  };

  const onCanceled = () => {
    setOpenOption(false);
  };

  const [form] = Form.useForm();
  const Navigate = useNavigate();
  useEffect(() => {
    actionGetAddress();
    actionGetCountries();
    actionGetQuiz();
  }, []);

  useMemo(() => {
    const randomIndex = Math.floor(Math.random() * quizData.length);
    const randomQuizData = quizData[randomIndex];
    setRandomQuiz(randomQuizData);
  }, [quizData]);

  useMemo(() => {
    addressData &&
      addressData.length &&
      addressData.find((data) => {
        if (data.address_type === "Home") {
          setHomeDisable(true);
        } else if (data.address_type === "Work") {
          setWorkDisable(true);
        } else if (data.address_type === "Other") {
          setOtherDisable(true);
        } else {
          setHomeDisable(false);
          setWorkDisable(false);
          setOtherDisable(false);
        }
        if (data.is_default_address) {
          setSelectedAddresData({
            id: data._id,
            type: data.address_type,
            address: {
              name: data.name,
              phone: data.phone,
              pincode: data.pincode,
              address: data.address,
              locality_town: data.locality_town,
              city: {
                name: data.name,
                countryCode: data.city.countryCode,
                stateCode: data.city.stateCode,
              },
              state: {
                name: data.state.name,
                isoCode: data.state.isoCode,
                countryCode: data.state.countryCode,
              },
              country: {
                name: data.country.name,
                isoCode: data.country.isoCode,
                flag: data.country.flag,
                phonecode: data.country.phonecode,
                currency: data.country.currency,
              },
              address_type: data.address_type,
              is_default_address: data.is_default_address,
            },
          });

          setSelectAddressPayNOw(false);
        }
      });

    console.log(addressData, "addressData");

    addressData && addressData.length >= 3
      ? setAddressDisable(true)
      : setAddressDisable(false);
  }, [addressData]);

  const CheckQuizAns = (d) => {
    if (randomQuiz.ans === d) {
      actionAddAllocateTickets(
        PaymentStatus ? BpayOrderId : OrderAllocateId,
        setQuizGame,
        setGetTicket
      );
    } else {
      setError(true);
    }
  };

  const onClickTryAgain = () => {
    actionGetQuiz();
    setError(false);
  };

  const OpenStartModal = () => {
    setIsPayNowModalOpen(false);
    setQuizGame(true);
  };

  const onCanclePlayQuizModal = () => {
    setIsPayNowModalOpen(false);
    Navigate("/");
  };

  const onCloseQuationQuiz = () => {
    setQuizGame(false);
    Navigate("/");
  };

  const onCloseSuccesPlayGame = () => {
    setGetTicket(false);
    Navigate("/my-order");
  };

  console.log(PaymentStatus, "PaymentStatus");
  console.log(boolean, "boolean");

  const PaymentStatusCheck = async () => {
    try {
      setPaymentLoader(true);
      let result = await axios.post(`${BASE_URL}/orders/bpay-payment-status`, {
        payment_ref_id: createBPayOrderData.payment_ref_id,
      });
      if (parseInt(result.data.status) === 200) {
        console.log({ resultSucess: result.data });
        // setStatusData(result.data.data);
        result.data.data.bpay_payment.payment_status === "Success"
          ? setPaymentStatus(true)
          : setPaymentStatus(false);
        setIsPayNowModalOpen(true);
        setBpayOrderId(result.data.data && result.data.data._id);
        setPaymentLoader(false);
        console.log(result.data);
        setBoolean(false);
        actionEmptyCart();
      } else {
        console.log(result.data);
        //message.error(result.data.message, 5);
      }
    } catch (error) {
      //  message.error(error, 5);
      setPaymentLoader(false);
      return false;
    }
  };

  useEffect(() => {
    let intervalId;
    if (boolean && !PaymentStatus) {
      intervalId = setInterval(() => {
        PaymentStatusCheck();
      }, 5000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [boolean, createBPayOrderData]);

  const paymentHandler = () => {
    const amountData = cartData.total * 100;
    setGetOrderPaymentDetail(true);
    // In Paisa 50 = 50 * 100
    axios.post(`${BASE_URL}/orders/payment`).then((result) => {
      if (parseInt(result.data.status) === 200) {
        setGetOrderPaymentDetail(false);
        const order = result.data.data;
        const options = {
          key: APIKET_RZP,
          amount: amountData,
          name: "Grandealz",
          description: "Grandealz shop less win more!!",
          order_id: order.id,
          image: payLogo,
          prefill: {
            name: userProfileData.first_name + userProfileData.last_name,
            email: userProfileData.email,
            contact: userProfileData.phone,
          },
          handler: async (response) => {
            setPaymentLoader(true);
            axios
              .post(`${BASE_URL}/orders/payment-success`, {
                razorpayPaymentId: response.razorpay_payment_id,
                address:
                  addressData &&
                  addressData.length &&
                  addressData.find(
                    (data) => data.address_type === selectedAddresData.type
                  ),
              })
              .then((res) => {
                if (parseInt(res.data.status) === 200) {
                  setOrderAllocateId(res.data.data._id);
                  setPaymentLoader(
                    false,
                    Swal.fire({
                      icon: "success",
                      text: res.data.message,
                      allowOutsideClick: false,
                    }).then((result) => {
                      if (result.value) {
                        setIsPayNowModalOpen(true);
                        actionEmptyCart();
                        setSelectAddressPayNOw(true);
                        setSelectedAddresData({
                          id: "",
                          type: "",
                          address: "",
                        });
                      }
                    })
                  );
                } else
                  Swal.fire({
                    icon: "error",
                    text: res.message,
                  });
              });
          },
          theme: {
            color: "#060021",
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      } else setGetOrderPaymentDetail(false);
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    actionAddAddress(values, form, setIsModalOpen);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [value, setValue] = useState();
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const handleChangeState = (e, data) => {
    actionGetState(data.isoCode);
    setDisableState(false);
  };

  const handleChangeCity = (e, data) => {
    setDisableCity(false);
    actionGetCity(data.countryCode, data.isoCode);
  };

  const setPayNowValue = (id, type) => {
    setSelectAddressPayNOw(false);
    setSelectedAddresData({
      id,
      type,
    });
  };

  const Country = [];
  countryData &&
    countryData.length &&
    countryData.map((data, index) => {
      Country.push({
        ...data,
        value: data.isoCode,
        label: data.name,
      });
    });

  const state = [];
  stateData &&
    stateData.length &&
    stateData.map((data, index) => {
      state.push({
        ...data,
        value: data.isoCode,
        label: data.name,
      });
    });

  const city = [];
  cityData &&
    cityData.length &&
    cityData.map((data, index) => {
      city.push({
        ...data,
        value: data.name,
        label: data.name,
      });
    });

  return (
    <>
      <Spin spinning={paymentLoader}>
        <div className="cart-margin cart-address">
          <div className="container">
            <Row gutter={[24, 16]}>
              <Col span={17}>
                <Title className="text-line" level={1}>
                  Address
                </Title>
                <div className="spacer"></div>
                <div className="spacer"></div>
                <Row gutter={[9, 9]}>
                  <Col span={24}>
                    {addressData &&
                      addressData.map((data, index) => {
                        return (
                          <Card
                            onClick={() =>
                              cartData &&
                              cartData.draws &&
                              cartData.draws.length > 0
                                ? setPayNowValue(data._id, data.address_type)
                                : ""
                            }
                            className={
                              selectedAddresData.id === data._id
                                ? "active-bg"
                                : ""
                            }
                            key={data._id}
                            style={{
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <div className="d-flex justify-content-between">
                              <p className="font-24 d-flex-center">
                                {data.name}
                                <span className="address-Type">
                                  {data.address_type}
                                </span>
                              </p>
                              <Link to="/my-address">
                                {" "}
                                <Button icon={<EditOutlined />} />
                              </Link>
                            </div>

                            <div className="spacer"></div>
                            <p className="cart-card-text font-24">
                              {data.address +
                                " " +
                                data.locality_town +
                                " ," +
                                data.city.name +
                                " " +
                                data.state.name +
                                "-" +
                                data.pincode}
                            </p>
                            <div className="spacer"></div>

                            <p style={{ marginTop: 40 }} className="font-24">
                              <span className="cart-card-text ">Mobile :</span>
                              {/* 9654896542 */}
                              {data.phone}
                            </p>
                          </Card>
                        );
                      })}
                  </Col>
                  <Col span={24} className="new">
                    <Button
                      disabled={addressDisable}
                      className="addnew"
                      onClick={showModal}
                      style={{
                        width: "100%",
                        height: "60px",
                      }}
                    >
                      <p className="font-24 text-center">ADD NEW ADDRESS</p>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                <Card style={{ width: "100%" }}>
                  <Descriptions
                    title="Price Details"
                    column={1}
                    className="cart-title"
                  >
                    <div className="spacer"></div>
                    <Descriptions.Item label="Sub Total">
                      ₹{" "}
                      {cartData.sub_total
                        ? parseFloat(cartData.sub_total).toFixed(2)
                        : 0}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tax (GST)">
                      ₹ {cartData.tax ? parseFloat(cartData.tax).toFixed(2) : 0}
                    </Descriptions.Item>
                    {cartData.coupon_code && (
                      <Descriptions.Item label="Discount">
                        ₹ {parseFloat(cartData.coupon_discount).toFixed(2)}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item
                      label="Total Amount"
                      className="pb-0"
                      style={{
                        borderTop: "1.1px solid #616161",
                        paddingTop: 12,
                      }}
                    >
                      ₹{" "}
                      {cartData.total
                        ? parseFloat(cartData.total).toFixed(2)
                        : 0}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Inclusive of Tax (GST)"
                      className="font-10"
                    ></Descriptions.Item>
                  </Descriptions>
                </Card>

                {userProfileData.roles !== "bot" ? (
                  <Button
                    disabled={selectAddressPayNOw}
                    loading={getOrderPaymentDetail}
                    size="large"
                    onClick={handleOpenOption}
                    className="button-comman shiping-btn"
                  >
                    Proceed To Pay
                  </Button>
                ) : (
                  <Button
                    size="large"
                    onClick={() => actionIsVerified(true)}
                    className="button-comman shiping-btn"
                  >
                    Proceed To Pay
                  </Button>
                )}
              </Col>
            </Row>

            {/* Payment option Modal Start here */}

            <Modal
              style={{
                top: 50,
              }}
              className="payment-modal"
              // closable={false}
              onCancel={onCanceled}
              footer={false}
              title={
                <Row>
                  <Col span={24}>
                    <Title style={{ textAlign: "center" }} level={5}>
                      Select Payment Method
                    </Title>
                  </Col>
                </Row>
              }
              open={openOption}
            >
              <Radio.Group
                onChange={handlePaymentMethodChange}
                style={{ width: "100%" }}
                value={paymentMethod}
              >
                <Card className="payment-card ">
                  <Radio value="upi" style={{ width: "100%" }}>
                    <Typography.Title level={5}>UPI </Typography.Title>
                  </Radio>
                  <Image
                    style={{ textAlign: "center" }}
                    src={upi}
                    preview={false}
                    width={65}
                  />
                </Card>
                <Card className="payment-card ">
                  <Radio value="other" style={{ width: "100%" }}>
                    <Typography.Title level={5}>Other </Typography.Title>
                  </Radio>
                  <Image
                    style={{ textAlign: "center" }}
                    src={other}
                    preview={false}
                    width={40}
                  />
                </Card>
              </Radio.Group>
            </Modal>

            {/* Payment option Modal Ends here */}

            <Modal
              footer={false}
              style={{
                top: 60,
              }}
              className="modal-big"
              title={
                <>
                  <Title level={4} style={{ padding: "0 10px" }}>
                    ADD NEW ADDRESS
                  </Title>
                </>
              }
              open={isModalOpen}
              onCancel={handleCancel}
            >
              <Form
                className="form-basic"
                name="basic"
                initialValues={{
                  remember: true,
                }}
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div style={{ padding: "25px", paddingBottom: 35 }}>
                  <Text style={{ fontSize: 20, margin: "10px 0 20px 0" }}>
                    Contact details
                  </Text>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="name"
                        style={{ marginTop: 8 }}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Name!",
                          },
                        ]}
                      >
                        <Input placeholder="Name*" />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        maxLength={10}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone!",
                          },
                          {
                            validator: (_, value) =>
                              value && value.length === 10
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please enter 10 digits for Phone Number"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input type="number" placeholder="phone No*" />
                      </Form.Item>
                      <div className="spacer"></div>
                    </Col>
                    <Text style={{ fontSize: 20, paddingBottom: 8 }}>
                      ADDRESS
                    </Text>

                    <Col span={24}>
                      <Form.Item
                        name="pincode"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Pin Code!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          maxLength="6"
                          placeholder=" Pin Code*"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Address!",
                          },
                        ]}
                      >
                        <Input placeholder="Address (House No, Building, street, Afea)*" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name="locality_town"
                            rules={[
                              {
                                required: true,
                                message: "Please input your locality/town!",
                              },
                            ]}
                          >
                            <Input placeholder="Locality / Town*" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="country"
                            rules={[
                              {
                                required: true,
                                message: "Please select your Country !",
                              },
                            ]}
                          >
                            <Select
                              onSelect={handleChangeState}
                              loading={countryLoader}
                              showSearch
                              placeholder="Select Country"
                              optionFilterProp="children"
                              filterOption={(iv, op) =>
                                op.label
                                  .toLocaleLowerCase()
                                  .includes((iv || "").toLocaleLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              options={Country}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name="state"
                            rules={[
                              {
                                required: true,
                                message: "Please select your State !",
                              },
                            ]}
                          >
                            <Select
                              loading={stateLoader}
                              onSelect={handleChangeCity}
                              disabled={disableState ? true : false}
                              showSearch
                              placeholder="Select State"
                              optionFilterProp="children"
                              filterOption={(iv, op) =>
                                op.label
                                  .toLocaleLowerCase()
                                  .includes((iv || "").toLocaleLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              options={state}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: "Please select your City !",
                              },
                            ]}
                          >
                            <Select
                              className="addselect"
                              showSearch
                              disabled={disableCity ? true : false}
                              loading={cityLoader}
                              placeholder="Select City"
                              optionFilterProp="children"
                              filterOption={(iv, op) =>
                                op.label
                                  .toLocaleLowerCase()
                                  .includes((iv || "").toLocaleLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              options={city}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Text style={{ fontSize: 20, paddingBottom: 8 }}>
                      SAVE ADDRESS AS
                    </Text>
                    <Col span={24}>
                      <Card className="modal-card">
                        {/* <Radio.Group  buttonStyle="solid" onChange={handleRadioChange} value={radio}>
                        <Radio.Button style={{ marginRight: 10 }} className="radio-button" value={0}>Home</Radio.Button>
                        <Radio.Button className="radio-button" value={1}>Work</Radio.Button> */}
                        <Form.Item
                          name="address_type"
                          rules={[
                            {
                              required: true,
                              message: "Please select your Address Type !",
                            },
                          ]}
                        >
                          <Radio.Group onChange={onChange} value={value}>
                            <Radio.Button
                              disabled={homeDisable}
                              style={{ marginRight: 10 }}
                              className="radio-button"
                              value="Home"
                            >
                              Home
                            </Radio.Button>
                            <Radio.Button
                              disabled={workDisable}
                              style={{ marginRight: 10 }}
                              className="radio-button"
                              value="Work"
                            >
                              Work
                            </Radio.Button>
                            <Radio.Button
                              disabled={OtherDisable}
                              className="radio-button"
                              value="Other"
                            >
                              Other
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Card>
                      <div className="spacer"></div>
                    </Col>
                    <Col span={24}>
                      <Card className="modal-card">
                        {/* <Form.Item name='is_default_address'
                        rules={[
                          {
                            required: false,
                            message: "Please select your Address Type !",
                          },
                        ]}
                      >
                      <Checkbox style={{ marginLeft: 10 }} size="large">
                        Make this my default address
                      </Checkbox>
                      </Form.Item> */}
                        <Form.Item
                          name="is_default_address"
                          valuePropName="checked"
                        >
                          <Checkbox style={{ marginLeft: 10 }} size="large">
                            Make this my default address
                          </Checkbox>
                        </Form.Item>
                      </Card>
                    </Col>
                    {/* <Modal.footer> */}

                    <div className="spacer"></div>
                  </Row>
                </div>

                <Form.Item className="footer-btn">
                  <Button className="address-btn" htmlType="submit">
                    ADD ADDRESS
                  </Button>
                </Form.Item>
              </Form>
            </Modal>

            <Modal
              centered
              className=" pay-now-modal"
              footer={false}
              open={isPayNowModalOpen}
              onCancel={() => onCanclePlayQuizModal()}
            >
              <Row align="middle" className="p-30 border-none-input">
                <Col span={24} align="middle">
                  <Image preview={false} src={orderimage} alt="alt-image" />
                  <Title level={4} className="paynow-text">
                    Order Confirmed
                  </Title>
                  <Image preview={false} src={orderconfirm} alt="alt-image" />
                  <br />
                  <br />
                  <Text className="paynow-text">
                    Answer the question and avail your tickets
                  </Text>
                  <br />
                  <br />
                  <Button
                    onClick={OpenStartModal}
                    className="button-comman playnow-btn"
                    htmlType="submit"
                  >
                    Play Now
                  </Button>
                </Col>
              </Row>
            </Modal>

            {/* Quiz Page Modal */}

            <Modal
              loading={quizLoader}
              centered
              className="quiz-modal pay-now-modal"
              footer={false}
              open={quizGame}
              onCancel={() => onCloseQuationQuiz(false)}
            >
              <Row align="middle" className="border-none-input px-20">
                <Col span={24} align="middle">
                  <Image preview={false} src={Logopaynow} alt="alt-image" />
                </Col>
                <Card
                  className="startquiz-card card-quiz min-height "
                  bordered={false}
                  style={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {error ? (
                    <>
                      <p className="quiz-text1">
                        your answer is wrong please try again to play this game.
                      </p>
                      <br />
                      <div className="mt-15">
                        <Text className="text1" onClick={onClickTryAgain}>
                          Try Again
                        </Text>
                      </div>
                    </>
                  ) : (
                    <>
                      <Title level={4} className="quiz-text1">
                        {randomQuiz ? randomQuiz.q : "---"}
                      </Title>
                      <section className="sec-box">
                        {randomQuiz &&
                        randomQuiz.options &&
                        randomQuiz.options.length
                          ? randomQuiz.options.map((d, i) => {
                              return (
                                <Button
                                  disabled={error}
                                  size="large"
                                  onClick={() => CheckQuizAns(d)}
                                  id="quiz-box"
                                  key={i}
                                >
                                  {d}
                                </Button>
                              );
                            })
                          : ""}
                      </section>
                    </>
                  )}
                </Card>
              </Row>
            </Modal>

            {/* Ticket Page Modal */}

            <Modal
              centered
              className="quiz-modal pay-now-modal"
              footer={false}
              open={getTicket}
              onCancel={() => onCloseSuccesPlayGame(false)}
            >
              <Row align="middle" className="border-none-input px-20">
                <Col span={24} align="middle">
                  <Image preview={false} src={Logopaynow} alt="alt-image" />
                </Col>
                <Card
                  className="startquiz-card card-quiz "
                  bordered={false}
                  style={{
                    textAlign: "center",

                    width: "100%",
                  }}
                >
                  <Row
                    justify="center"
                    align="middle"
                    className="border-none-input px-20"
                  >
                    <Col span={24} align="middle">
                      <Image preview={false} src={getticket} alt="alt-image" />
                      <Title
                        level={4}
                        className="paynow-text"
                        style={{ color: "black" }}
                      >
                        Congratulations! Avail your tickets at
                      </Title>

                      <Paragraph className="copy-text">
                        <Link to="/active-tickets">View Tickets</Link>
                      </Paragraph>
                      <br />
                    </Col>
                  </Row>

                  <Button
                    size="large"
                    onClick={() => onCloseSuccesPlayGame()}
                    className="mb-15 button-comman"
                    // style={{ width: "276px" }}
                    style={{ width: "276px" }}
                  >
                    Close
                  </Button>
                </Card>
              </Row>
            </Modal>
          </div>
        </div>
      </Spin>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    addAddressLoader: state.addAddressLoader,
    cartData: state.wishlistCart.cartData,
    allocateOrderTicketLoader: state.order.allocateOrderTicketLoader,
    addAddressData: state.addAddressData,
    addressLoader: state.location.addressLoader,
    addressData: state.location.addressData,
    countryLoader: state.location.countryLoader,
    countryData: state.location.countryData,
    stateLoader: state.location.stateLoader,
    stateData: state.location.stateData,
    userProfileData: state.auth.userProfileData,
    quizLoader: state.wishlistCart.quizLoader,
    quizData: state.wishlistCart.quizData,
    cityLoader: state.location.cityLoader,
    cityData: state.location.cityData,
    getPaymentSucessLoader: state.order.getPaymentSucessLoader,
    createBPayOrderData: state.order.createBPayOrderData,
  };
};

export default connect(mapStateToProps, {
  actionAddAddress,
  actionGetAddress,
  actionGetCountries,
  actionCreateBpayOrder,
  actionGetQuiz,
  actionEmptyCart,
  actionGetCity,
  actionGetState,
  actionIsVerified,
  actionAddAllocateTickets,
  actionBPayOrderStatus,
})(CartAddress);
