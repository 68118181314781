import axios from "axios";
import { BASE_URL } from "../../../src/components/config/web-config";
import * as actionTypes from "../action";

export const actionGetBanners = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_BANNERS_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/banners`);
    dispatch({ type: actionTypes.GET_BANNERS_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_BANNERS,
        payload: result.data.data,
      });
    } else
      dispatch({
        type: actionTypes.GET_BANNERS,
        payload: result.data.data,
      });
  } catch (error) {
    dispatch({ type: actionTypes.GET_BANNERS_LOADER, payload: false });
  }
};

export const actionGetDraws = (status) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_DRAWS_LOADER, payload: true });
  try {
    let result = await axios.get(
      `${BASE_URL}/draws?limit=200&skip=0&status=${status}`
    );
    dispatch({ type: actionTypes.GET_DRAWS_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_DRAWS,
        payload: result.data.data,
      });
    }
    dispatch({
      type: actionTypes.GET_DRAWS,
      payload: result.data.data,
    });
  } catch (error) {
    dispatch({ type: actionTypes.GET_DRAWS_LOADER, payload: false });
  }
};

export const actionGetSingleDrawDetail = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_SINGLE_DRAWS_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/draws/${id}`);
    dispatch({ type: actionTypes.GET_SINGLE_DRAWS_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_SINGLE_DRAWS,
        payload: result.data.data,
      });
    }
    dispatch({
      type: actionTypes.GET_SINGLE_DRAWS,
      payload: result.data.data,
    });
  } catch (error) {
    dispatch({ type: actionTypes.GET_SINGLE_DRAWS_LOADER, payload: false });
  }
};

export const actionGetUpcomingDraws =
  (skip, limit, status, search) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_UPCOMING_DRAWS_LOADER, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/draws?limit=${limit}&skip=${skip}&status=${status}${search ? "&search=" + search : ""
        }`
      );
      dispatch({ type: actionTypes.GET_UPCOMING_DRAWS_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_UPCOMING_DRAWS,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_UPCOMING_DRAW_COUNT,
          payload: result.data.total_records,
        });
        return true
      } else {
        dispatch({
          type: actionTypes.GET_UPCOMING_DRAWS,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_UPCOMING_DRAW_COUNT,
          payload: 0,
        });
        return false
      }
    } catch (error) {
      dispatch({ type: actionTypes.GET_UPCOMING_DRAWS_LOADER, payload: false });

      return false;
    }
  };

export const actionGetWinners =
  (skip, limit, search, draw_date, draw_year) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_WINNER_LOADER, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/draws/winners?limit=${limit}&skip=${skip}${search ? "&search=" + search : ""
        }${draw_date ? "&draw_date=" + draw_date : ""}${draw_year ? "&draw_year=" + draw_year : ""
        }`
      );
      dispatch({ type: actionTypes.GET_WINNER_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_WINNER_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_WINNERS_COUNT,
          payload: result.data.total_records,
        });
        return true
      } else {
        dispatch({
          type: actionTypes.GET_WINNER_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_ORDERS_COUNT,
          payload: 0,
        });
        return false
      }
    } catch (error) {
      dispatch({ type: actionTypes.GET_WINNER_LOADER, payload: false });
      return false
    }
  };
