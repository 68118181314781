import { Typography } from "antd";
import React from "react";
const { Title } = Typography;

const Topheader = () => {
  return (
    <>
      <div className="top-header">
        <div className="container">
          {/* <Title level={4}>Email : <span className='email-text'>info@gmail.com</span></Title> */}
          <h3>
            Email :-
            <a href="mailto:info@grandealz.com">
              <span className="email-text">info@grandealz.com</span>
            </a>
          </h3>
          <h3>
            Call Now :-
            <a href="tel:+9949561234">
              <span className="email-text">+91-855-534-9865</span>
            </a>
          </h3>
        </div>
      </div>
    </>
  );
};

export default Topheader;
