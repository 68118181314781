import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";

export const actionGetCountries = () => async (dispatch) => {
  dispatch({ type: actionTypes.COUNTRY_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/location/get-all-countries`);
    dispatch({ type: actionTypes.COUNTRY_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.COUNTRY_DATA,
        payload: result.data.data,
      });
    }
    dispatch({
      type: actionTypes.COUNTRY_DATA,
      payload: result.data.data,
    });
  } catch (error) {
    dispatch({ type: actionTypes.COUNTRY_LOADER, payload: false });
  }
};

export const actionGetState = (countryCode) => async (dispatch) => {
  dispatch({ type: actionTypes.STATE_LOADER, payload: true });
  try {
    let result = await axios.get(
      `${BASE_URL}/location/get-states-of-country/${countryCode}`
    );
    dispatch({ type: actionTypes.STATE_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.STATE_DATA,
        payload: result.data.data,
      });
    }
    dispatch({
      type: actionTypes.STATE_DATA,
      payload: result.data.data,
    });
  } catch (error) {
    dispatch({ type: actionTypes.STATE_LOADER, payload: false });
  }
};

export const actionGetCity = (countryCode, stateCode) => async (dispatch) => {
  dispatch({ type: actionTypes.CITY_LOADER, payload: true });
  try {
    let result = await axios.get(
      `${BASE_URL}/location/get-cities-of-states/${countryCode}/${stateCode}`
    );
    dispatch({ type: actionTypes.CITY_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.CITY_DATA,
        payload: result.data.data,
      });
    }
    dispatch({
      type: actionTypes.CITY_DATA,
      payload: result.data.data,
    });
  } catch (error) {
    dispatch({ type: actionTypes.CITY_LOADER, payload: false });
  }
};

export const actionGetAddress = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ADDRESS_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/addresses`);
    dispatch({ type: actionTypes.GET_ADDRESS_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_ADDRESS_DATA,
        payload: result.data.data,
      });
    }
    dispatch({
      type: actionTypes.GET_ADDRESS_DATA,
      payload: result.data.data,
    });
  } catch (error) {
    dispatch({ type: actionTypes.GET_ADDRESS_DATA, payload: false });
  }
};

export const actionAddAddress =
  (userData, form, setIsModalOpen) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_ADDRESS_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/addresses`, userData);
      dispatch({ type: actionTypes.ADD_ADDRESS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_ADDRESS_DATA,
          payload: result.data.data,
        });

        form && form.resetFields();
        setIsModalOpen && setIsModalOpen(false);
        message.success(result.data.message, 5);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_ADDRESS_LOADING, payload: false });
    }
  };

export const actionUpdateAddress =
  (userData, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_ADDRESS_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/addresses/${id}`, userData);
      dispatch({ type: actionTypes.UPDATE_ADDRESS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_ADDRESS_DATA,
          payload: result.data.data,
        });
        message.success(result.data.message, 5);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_ADDRESS_LOADING, payload: false });
    }
  };

export const actionSingleAddressData = (id) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_SINGLE_ADDRESS_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/addresses/${id}`);
    dispatch({ type: actionTypes.GET_SINGLE_ADDRESS_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_SINGLE_ADDRESS,
        payload: result.data.data,
      });
    }
    dispatch({
      type: actionTypes.GET_SINGLE_ADDRESS,
      payload: result.data.data,
    });
  } catch (error) {
    dispatch({ type: actionTypes.GET_SINGLE_ADDRESS_LOADER, payload: false });
  }
};
