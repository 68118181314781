import axios from "axios";
import jwt_decode from "jwt-decode";

const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem("GrandealzFrontendJwtToken", token);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("Authorization");
  }
};

export default setAuthToken;
