import {
  MinusOutlined,
  PlusOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Empty,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import axios from "axios";
import { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  APIKET_RZP,
  BASE_URL,
} from "../../../src/components/config/web-config";
import Logopaynow from "../../assets/comman/Logo-paynow.svg";
import jeep from "../../assets/comman/audi-jeep.png";
import other from "../../assets/comman/download.png";
import getticket from "../../assets/comman/getticket.png";
import line from "../../assets/comman/line1.png";
import orderconfirm from "../../assets/comman/order-confirm.svg";
import orderimage from "../../assets/comman/order.svg";
import payLogo from "../../assets/comman/pay-logo.svg";
import cart from "../../assets/comman/round-cart.png";
import truck from "../../assets/comman/truck.png";
import upi from "../../assets/comman/upi_logo_icon_169316.png";
import { actionIsVerified } from "../../store/actions/authAction";
import { actionGetDraws } from "../../store/actions/homeAction";
import {
  actionAddAllocateTickets,
  actionBPayOrderStatus,
  actionCreateBpayOrder,
} from "../../store/actions/orderAction";
import { actionGetCoupanAll } from "../../store/actions/ticketsAction";
import {
  actionAddCart,
  actionApplyCoupenCode,
  actionDonateToggle,
  actionEmptyCart,
  actionGetCart,
  actionGetQuiz,
  actionRemoveCoupenCode,
} from "../../store/actions/wishlistCartAction";
const { Title, Text, Paragraph } = Typography;
const { Meta } = Card;

const Cart = (props) => {
  const {
    actionCreateBpayOrder,
    cartData,
    cartLoader,
    actionAddCart,
    actionGetCart,
    actionDonateToggle,
    getOrderStatusData,
    donateLoader,
    userProfileData,
    getOrderStatusLoader,
    actionIsVerified,
    actionBPayOrderStatus,
    coupanData,
    actionGetDraws,
    createBPayOrderData,
    actionGetCoupanAll,
    drawData,
    addressData,
    actionEmptyCart,
    quizLoader,
    quizData,
    actionGetQuiz,
    createBPayOrderLoader,
    actionAddAllocateTickets,
    allocateOrderTicketLoader,
    applyCoupenLoader,
    removeCoupenLoader,
    actionApplyCoupenCode,
    actionRemoveCoupenCode,
  } = props;
  const [form] = Form.useForm();

  const [value, setValue] = useState("none");
  const [text, setText] = useState("Apply");
  const [getOrderPaymentDetail, setGetOrderPaymentDetail] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [boolean, setBoolean] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPayNowModalOpen, setIsPayNowModalOpen] = useState(false);
  const [openStartModal, setOpenStartModal] = useState(false);
  const [quizGame, setQuizGame] = useState(false);
  const [getTicket, setGetTicket] = useState(false);
  const [open, setOpen] = useState(false);
  const [randomQuiz, setRandomQuiz] = useState("");
  const [error, setError] = useState(false);
  const [OrderAllocateId, setOrderAllocateId] = useState("");
  const [radioValue, setRadioValue] = useState(null);
  const [openOption, setOpenOption] = useState(false);
  const [BpayOrderId, setBpayOrderId] = useState("");
  const [PaymentStatus, setPaymentStatus] = useState(false);
  const Navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState();
  const cartCheck = () => {};

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    if (e.target.value === "upi") {
      actionCreateBpayOrder({
        self_pickup: radioValue === 2 ? true : false,
      });
      setOpenOption(false);
      setBoolean(true);
      setPaymentMethod("");
    } else {
      setOpenOption(false);
      paymentHandler();
      setPaymentMethod("");
    }
  };

  const payment_ref_id = createBPayOrderData.payment_ref_id;
  console.log(payment_ref_id, "payment_ref_id");

  const PaymentStatusCheck = async () => {
    try {
      setPaymentLoader(true);
      let result = await axios.post(`${BASE_URL}/orders/bpay-payment-status`, {
        payment_ref_id: createBPayOrderData.payment_ref_id,
      });
      if (parseInt(result.data.status) === 200) {
        console.log({ resultSucess: result.data });
        result.data.data.bpay_payment.payment_status === "Success"
          ? setPaymentStatus(true)
          : setPaymentStatus(false);
        setIsPayNowModalOpen(true);
        setBpayOrderId(result.data.data && result.data.data._id);
        setPaymentLoader(false);
        console.log(result.data);
        setBoolean(false);
        actionEmptyCart();
      } else {
        console.log(result.data);
      }
    } catch (error) {
      setPaymentLoader(false);
      return false;
    }
  };

  // result.data.data.draws &&
  //   result.data.data.draws.map((data) => console.log(data));

  useEffect(() => {
    let intervalId;
    let timeoutId;

    if (boolean && !PaymentStatus) {
      intervalId = setInterval(() => {
        PaymentStatusCheck();
      }, 5000);
    } else {
      clearInterval(intervalId);
      timeoutId = setTimeout(() => {
        setPaymentLoader(false);
      }, 5 * 60 * 1000);
    }
    return () => {
      clearInterval(intervalId);
       clearTimeout(timeoutId);
    };
  }, [boolean, createBPayOrderData]);

  const handleOpenOption = () => {
    setOpenOption(true);
  };

  const onCanceled = () => {
    setOpenOption(false);
  };

  useEffect(() => {
    actionGetCart();
    actionGetDraws("Publish");
    actionGetQuiz();
  }, []);

  const onFinish = async (values) => {
    const result = await actionApplyCoupenCode(values);
    result && form.resetFields();
    result && setIsModalOpen(false);
  };

  const onApplyCoupenButton = async (values) => {
    const result = await actionApplyCoupenCode(values);
    result && setIsModalOpen(false);
  };

  useMemo(() => {
    const randomIndex = Math.floor(Math.random() * quizData.length);
    const randomQuizData = quizData[randomIndex];
    setRandomQuiz(randomQuizData);
  }, [quizData]);

  useEffect(() => {
    if (cartData.is_donate) {
      setRadioValue(null);
    }
  }, [cartData, isPayNowModalOpen]);
  console.log(getOrderStatusData, "gt");
  const sugggestedDrawData =
    (drawData &&
      drawData.length &&
      drawData.filter(
        (data) =>
          data.total_no_of_sold_out_tickets < data.total_no_of_tickets &&
          cartData.draws &&
          cartData.draws.some((d) => d.draw._id !== data._id)
      )) ||
    [];

  const showModal = () => {
    setIsModalOpen(true);
    actionGetCoupanAll();
  };
  const getTicketOpen = () => {
    setQuizGame(false);
    setGetTicket(true);
  };

  console.log(BpayOrderId, "BpayOrderId");

  console.log(OrderAllocateId, "OrderAllocateId");
  console.log(PaymentStatus, "PaymentStatus");

  const CheckQuizAns = (d) => {
    if (randomQuiz.ans === d) {
      actionAddAllocateTickets(
        PaymentStatus ? BpayOrderId : OrderAllocateId,
        setQuizGame,
        setGetTicket
      );
    } else {
      setError(true);
      //message.error("your ans is wrong");
    }
  };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  const cancel = () => {
    setIsModalOpen(false);
  };
  const handleCountinue = () => {
    setOpen(false);
  };
  const OpenStartModal = () => {
    setIsPayNowModalOpen(false);
    setQuizGame(true);
  };
  const quiz = () => {
    setOpenStartModal(false);
    setQuizGame(true);
  };

  const onClickTryAgain = () => {
    actionGetQuiz();
    setError(false);
  };

  const okText = () => {
    setOpen(false);
  };

  const remove = () => {
    value === "none" ? setValue("block") : setValue("none");
    text === "Apply" ? setText("remove") : setText("Apply");
  };

  const onChangeAdress = () => {
    setOpen(true);
  };

  const onCancleModalDelivery = () => {
    setOpen(false);
    setRadioValue(null);
  };

  const onCanclePlayQuizModal = () => {
    setIsPayNowModalOpen(false);
    Navigate("/");
  };

  const onCloseQuationQuiz = () => {
    setQuizGame(false);
    // Navigate("/");
  };

  const onCloseSuccesPlayGame = () => {
    setGetTicket(false);
    Navigate("/my-order");
  };

  const onChangeRadio = (e) => {
    setRadioValue(e.target.value);
  };

  const paymentHandler = () => {
    const amountData = cartData.total * 100;
    setGetOrderPaymentDetail(true);
    // In Paisa 50 = 50 * 100
    axios.post(`${BASE_URL}/orders/payment`).then((result) => {
      if (parseInt(result.data.status) === 200) {
        setGetOrderPaymentDetail(false);
        const order = result.data.data;
        const options = {
          key: APIKET_RZP,
          amount: amountData,
          name: "Grandealz",
          description: "Grandealz shop less win more!!",
          order_id: order.id,
          image: payLogo,
          prefill: {
            name: userProfileData.first_name + userProfileData.last_name,
            email: userProfileData.email,
            contact: userProfileData.phone,
          },
          method: {
            netbanking: true,
            card: true,
            wallet: true,
            upi: true,
            paylater: false,
          },
          handler: async (response) => {
            setPaymentLoader(true);
            axios
              .post(`${BASE_URL}/orders/payment-success`, {
                razorpayPaymentId: response.razorpay_payment_id,
                self_pickup: radioValue === 2,
              })
              .then((res) => {
                if (parseInt(res.data.status) === 200) {
                  setOrderAllocateId(res.data.data._id);
                  setPaymentLoader(
                    false,
                    Swal.fire({
                      icon: "success",
                      text: res.data.message,
                      allowOutsideClick: false,
                    }).then((result) => {
                      if (result.value) {
                        actionEmptyCart();
                        setIsPayNowModalOpen(true);
                      }
                    })
                  );
                } else
                  Swal.fire({
                    icon: "error",
                    text: res.message,
                  });
              });
          },
          theme: {
            color: "#060021",
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      } else setGetOrderPaymentDetail(false);
      // message.error(result.data.message);
    });
  };

  return (
    <Spin spinning={cartLoader || getOrderStatusLoader || paymentLoader}>
      {cartData.draws && cartData.draws.length > 0 ? (
        <div className=" cart-margin">
          <div className="container comman-container">
            <Row gutter={[24, 16]}>
              {/* <Col xs={10} sm={10} md={10} lg={14} xl={14} xxl={17}> */}
              <Col span={18}>
                <Title className="text-line" level={1}>
                  Cart
                </Title>
                <div className="spacer"></div>
                <Card className="cart-card" style={{ width: "100%" }}>
                  <Row
                    align="middle"
                    gutter={[24, 16]}
                    className="pb-0 p-25-15"
                  >
                    {cartData.draws &&
                      cartData.draws.length > 0 &&
                      cartData.draws.map((data, index) => {
                        return (
                          <Fragment key={index}>
                            <Col span={5}>
                              <div className="resposive-image">
                                <Image
                                  preview={false}
                                  src={data.draw.product_image}
                                  width={150}
                                />
                              </div>
                            </Col>
                            <Col span={11}>
                              <Title level={5}>{data.draw.draw_title}</Title>
                              <p className="cart-card-text">
                                {data.draw.product_title}
                              </p>
                              <p
                                className="font-24"
                                style={{ color: "#EC092D" }}
                              >
                                ₹{data.draw.product_price}
                              </p>
                              {/* <p className="font-24 text">
                                <span className="cart-card-text small">
                                  {cartData.is_donate ? 2 : 1} Ticket per unit
                                </span>
                              </p> */}
                            </Col>
                            <Col span={7} align="right">
                              {data.qty === 1 ? (
                                <Tooltip title="Remove From Cart" color="white">
                                  <Button
                                    disabled={cartLoader}
                                    onClick={() =>
                                      actionAddCart({
                                        draw: data.draw._id,
                                        qty: data.qty - 1,
                                      })
                                    }
                                    size="large"
                                    icon={<MinusOutlined />}
                                    className="hover-false border-radius h-45"
                                  />
                                </Tooltip>
                              ) : (
                                <Button
                                  disabled={cartLoader}
                                  onClick={() =>
                                    actionAddCart({
                                      draw: data.draw._id,
                                      qty: data.qty - 1,
                                    })
                                  }
                                  size="large"
                                  icon={<MinusOutlined />}
                                  className=" hover-false border-radius h-45"
                                />
                              )}
                              <InputNumber
                                //value={number}
                                value={data.qty}
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "transparent",
                                }}
                                min={1}
                                max={100000}
                                disabled={true}
                              />
                              <Button
                                onClick={() =>
                                  actionAddCart({
                                    draw: data.draw._id,
                                    qty: data.qty + 1,
                                  })
                                }
                                size="large"
                                disabled={cartLoader}
                                icon={<PlusOutlined />}
                                className="hover-false border-radius h-45"
                              />
                            </Col>
                          </Fragment>
                        );
                      })}
                    {/* <Col span={24} className="background-radius">
                      <Switch
                        defaultChecked={
                          cartData.is_donate ? cartData.is_donate : false
                        }
                        loading={donateLoader}
                        onChange={(e) =>
                          actionDonateToggle(cartData._id, {
                            is_donate: e,
                          })
                        }
                      />

                      <Title
                        className="pl-10 font-20"
                        level={4}
                        style={{ color: "white" }}
                      >
                        Donate Product(s) & Double Your Tickets
                      </Title>
                    </Col> */}
                  </Row>
                </Card>
                <Title level={4} className="margin-y">
                  People Have also bought this together
                </Title>
                <Row gutter={[9, 16]}>
                  {sugggestedDrawData &&
                    sugggestedDrawData.length > 0 &&
                    sugggestedDrawData.slice(0, 2).map((data, index) => {
                      return (
                        <Col span={12} key={index}>
                          <Card style={{ width: "100%" }}>
                            <Row
                              align="middle"
                              className="p-3"
                              gutter={[24, 16]}
                            >
                              <Col xl={10} xxl={7}>
                                <div
                                  className="image-container "
                                  style={{ background: "transparent" }}
                                >
                                  <Image
                                    style={{ padding: "5px" }}
                                    preview={false}
                                    // src={data.draw_image}
                                    src={data.product_image}
                                  />
                                </div>
                              </Col>
                              <Col xxl={16} xl={14} className="px-25">
                                <Title
                                  ellipsis={true}
                                  level={4}
                                  className="card-text-rep"
                                >
                                  {data.draw_title}
                                </Title>
                                <Text ellipsis={true}>
                                  <p
                                    className="cart-card-text card-text-rep "
                                    style={{ fontSize: 20 }}
                                  >
                                    {data.product_title}
                                  </p>
                                </Text>
                                <p
                                  className=" card-text-rep"
                                  style={{ color: "#EC092D", fontSize: 20 }}
                                >
                                  ₹ {data.product_price}
                                </p>
                                {cartData.draws &&
                                cartData.draws.some(
                                  (d) => d.draw._id == data._id
                                ) ? (
                                  <Button
                                    style={{ width: 112, marginTop: 5 }}
                                    className="button-comman"
                                  >
                                    Added
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={
                                      data.total_no_of_sold_out_tickets >=
                                      data.total_no_of_tickets
                                    }
                                    onClick={() =>
                                      actionAddCart({
                                        draw: data._id,
                                        qty: 1,
                                      })
                                    }
                                    style={{ width: 112, marginTop: 5 }}
                                    className="button-comman"
                                  >
                                    Add to cart
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
              {/* <Col xs={2} sm={4} md={8} lg={8} xl={5} xxl={6}> */}
              <Col span={6}>
                <Card style={{ width: "100%" }}>
                  <Descriptions
                    title="Price Details"
                    column={1}
                    className="cart-title"
                  >
                    <div className="spacer"></div>
                    <Descriptions.Item label="Sub Total">
                      ₹ {parseFloat(cartData.sub_total).toFixed(2)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tax (GST)">
                      ₹ {parseFloat(cartData.tax).toFixed(2)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Coin"
                      style={{ display: `${value}` }}
                    >
                      ₹ 0
                    </Descriptions.Item>
                    {cartData.coupon_code && (
                      <Descriptions.Item label="You Save">
                        ₹ {parseFloat(cartData.coupon_discount).toFixed(2)}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item
                      label="Total Amount"
                      className="pt-12 pad-0 pb-0"
                      style={{
                        borderTop: "1.1px solid #616161",
                      }}
                    >
                      ₹ {parseFloat(cartData.total).toFixed(2)}
                      <p></p>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Inclusive of Tax (GST)"
                      className="font-10"
                    ></Descriptions.Item>
                  </Descriptions>
                </Card>
                {radioValue === 2 && (
                  <Card
                    hoverable
                    style={{ width: "100%" }}
                    className="card-body"
                  >
                    <Row
                      align="middle"
                      className="padding-20-10"
                      style={{
                        borderRadius: "10px",
                        background: " white",
                      }}
                    >
                      <Col span={2}>
                        <RightCircleOutlined style={{ marginTop: "5px" }} />
                      </Col>
                      <Col span={17}>Self Pickup Selected</Col>
                      <Col
                        span={3}
                        style={{ cursor: "pointer", color: "rgb(236, 9, 45)" }}
                        onClick={() => onChangeAdress()}
                      >
                        Change
                      </Col>
                    </Row>
                  </Card>
                )}
                {radioValue === 2 ? (
                  userProfileData.roles !== "bot" ? (
                    <Button
                      loading={getOrderPaymentDetail}
                      size="large"
                      // onClick={() => paymentHandler()}
                      onClick={handleOpenOption}
                      className="button-comman shiping-btn"
                    >
                      Pay Now
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      onClick={() => actionIsVerified(true)}
                      className="button-comman shiping-btn"
                    >
                      Pay Now
                    </Button>
                  )
                ) : userProfileData.roles !== "bot" ? (
                  <Button
                    size="large"
                    onClick={() => setOpen(true)}
                    className="button-comman shiping-btn"
                  >
                    Proceed To Pay
                  </Button>
                ) : (
                  <Button
                    size="large"
                    onClick={() => actionIsVerified(true)}
                    className="button-comman shiping-btn"
                  >
                    Proceed To Pay
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="padding-100 min-height-50vh">
          <Row justify="center">
            <Col span={16} align="middle">
              <Empty image={cart} description="You Cart is empty" />
              <div className="spacer" />
              <Link to="/">
                <Button size="large" className="button-comman">
                  Start Shoping
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      )}

      {/* Payment option Modal Start here */}

      <Modal
        style={{
          top: 50,
        }}
        className="payment-modal"
        onCancel={onCanceled}
        footer={false}
        title={
          <Row>
            <Col span={24}>
              <Title style={{ textAlign: "center" }} level={5}>
                Select Payment Method
              </Title>
            </Col>
          </Row>
        }
        open={openOption}
      >
        <Radio.Group
          onChange={handlePaymentMethodChange}
          style={{ width: "100%" }}
          value={paymentMethod}
        >
          {/* <Row>
            <Col span={24}> */}

          <Card className="payment-card ">
            <Radio value="upi" style={{ width: "100%" }}>
              <Typography.Title level={5}>UPI </Typography.Title>
            </Radio>
            <Image
              style={{ textAlign: "center" }}
              src={upi}
              preview={false}
              width={65}
            />
          </Card>
          <Card className="payment-card ">
            <Radio value="other" style={{ width: "100%" }}>
              <Typography.Title level={5}>Other </Typography.Title>
            </Radio>
            <Image
              style={{ textAlign: "center" }}
              src={other}
              preview={false}
              width={40}
            />
          </Card>
        </Radio.Group>
      </Modal>

      {/* Payment option Modal Ends here */}

      {/* Ticket Page Modal */}

      <Modal
        centered
        className="quiz-modal pay-now-modal"
        footer={false}
        open={getTicket}
        onCancel={() => onCloseSuccesPlayGame(false)}
      >
        <Row align="middle" className="border-none-input px-20">
          <Col span={24} align="middle">
            <Image preview={false} src={Logopaynow} alt="alt-image" />
          </Col>
          <Card
            className="startquiz-card card-quiz "
            bordered={false}
            style={{
              textAlign: "center",

              width: "100%",
            }}
          >
            <Row
              justify="center"
              align="middle"
              className="border-none-input px-20"
            >
              <Col span={24} align="middle">
                <Image preview={false} src={getticket} alt="alt-image" />
                <Title
                  level={4}
                  className="paynow-text"
                  style={{ color: "black" }}
                >
                  Congratulations! Avail your tickets at
                </Title>

                <Paragraph className="copy-text">
                  <Link to="/active-tickets">View Tickets</Link>
                </Paragraph>
                <br />
              </Col>
            </Row>

            <Button
              size="large"
              onClick={() => onCloseSuccesPlayGame()}
              className="mb-15 button-comman"
              // style={{ width: "276px" }}
              style={{ width: "276px" }}
            >
              Close
            </Button>
          </Card>
        </Row>
      </Modal>

      {/* Quiz Page Modal */}

      <Modal
        loading={quizLoader || allocateOrderTicketLoader}
        centered
        className="quiz-modal pay-now-modal"
        footer={false}
        open={quizGame}
        onCancel={() => onCloseQuationQuiz()}
      >
        <Row align="middle" className="border-none-input px-20">
          <Col span={24} align="middle">
            <Image preview={false} src={Logopaynow} alt="alt-image" />
          </Col>
          <Card
            className="startquiz-card card-quiz "
            bordered={false}
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            {error ? (
              <>
                <p className="quiz-text1">
                  your answer is wrong please try again to play this game.
                </p>
                <br />
                <div className="mt-15">
                  <Text className="text1" onClick={onClickTryAgain}>
                    Try Again
                  </Text>
                </div>
              </>
            ) : (
              <>
                <Title level={4} className="quiz-text1">
                  {randomQuiz ? randomQuiz.q : "---"}
                </Title>
                <section className="sec-box">
                  {randomQuiz && randomQuiz.options.length
                    ? randomQuiz.options.map((d, i) => {
                        return (
                          <Button
                            disabled={error}
                            size="large"
                            onClick={() => CheckQuizAns(d)}
                            id="quiz-box"
                            key={i}
                          >
                            {d}
                          </Button>
                        );
                      })
                    : ""}
                </section>
              </>
            )}
          </Card>
        </Row>
      </Modal>

      {/* Game Start  Page Modal */}

      <Modal
        centered
        className=" start-quiz pay-now-modal"
        footer={false}
        open={openStartModal}
        onCancel={() => setOpenStartModal(false)}
      >
        <Row align="middle" className="px-20 border-none-input">
          <Col span={24} align="middle">
            <Image preview={false} src={Logopaynow} alt="alt-image" />
          </Col>
          <Card
            title="Chance to win"
            className="startquiz-card "
            bordered={false}
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <Title level={4} className="quiz-text">
              Please provide an answer to the question and avail your ticket.
            </Title>

            <Card
              title="The All new Audi Q3"
              className="startquiz-card pad-0"
              bordered={false}
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={12} align="middle">
                  <Image preview={false} src={jeep} alt="alt-image" />
                </Col>
                <Col span={12}>
                  <Title level={5} style={{ textAlign: "left", fontSize: 16 }}>
                    The All new Audi Q3
                  </Title>
                </Col>
              </Row>
            </Card>
            <Col span={24} className="pb-15">
              <Button
                size="large"
                onClick={quiz}
                className="button-comman my-15 w-100"
              >
                Start
              </Button>
            </Col>
            <Text className="text">Terms and conditions</Text>
          </Card>
        </Row>
      </Modal>

      {/* Order Confirm Page Modal */}

      <Modal
        centered
        className=" pay-now-modal"
        footer={false}
        open={isPayNowModalOpen}
        onCancel={() => onCanclePlayQuizModal()}
      >
        <Row align="middle" className="p-30 border-none-input">
          <Col span={24} align="middle">
            <Image preview={false} src={orderimage} alt="alt-image" />
            <Title level={4} className="paynow-text">
              Order Confirmed
            </Title>
            <Image preview={false} src={orderconfirm} alt="alt-image" />
            <br />
            <br />
            <Text className="paynow-text">
              Answer the question and avail your tickets
            </Text>
            <br />
            <br />
            <Button
              onClick={OpenStartModal}
              className="button-comman playnow-btn"
              htmlType="submit"
            >
              Play Now
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        className="modal"
        footer={false}
        title={
          <>
            <Title style={{ textAlign: "center" }} level={5}>
              Coupon Code
            </Title>
          </>
        }
        open={isModalOpen}
        onCancel={cancel}
      >
        {coupanData && coupanData.length > 0 ? (
          <>
            <Card className="modal-card w-100">
              <Form
                name="basic"
                form={form}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row
                  justify="space-between"
                  align="middle"
                  className="border-none-input"
                >
                  <Col span={15} className="d-flex">
                    <Form.Item
                      name="coupon_code"
                      rules={[
                        {
                          required: true,
                          message: "Please input Coupon code!",
                        },
                      ]}
                    >
                      <Input
                        style={{ backgrond: "#f9f9f9" }}
                        name="promo_code"
                        placeholder="Add promo code "
                      />
                    </Form.Item>
                  </Col>
                  <Col span={9} align="right">
                    <Form.Item>
                      <Button
                        className="button-comman color-btn"
                        htmlType="submit"
                      >
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
            <div className="spacer"></div>
            <Row className="px-10">
              <Col span={24}>
                <Title style={{ fontSize: 15 }}>Available Coupons</Title>
              </Col>
            </Row>
            <Row className="order-list-overflow">
              {coupanData &&
                coupanData.length &&
                coupanData.map((data, index) => {
                  return (
                    <Col span={24} className="bg-img mb-7" key={index}>
                      <Row justify="center" align="middle">
                        <Col span={12}>
                          <Title level={5} style={{ fontSize: 20 }}>
                            {data.discount_type === "Flat"
                              ? data.discount_type +
                                " ₹" +
                                data.discount +
                                " OFF"
                              : data.discount + "% " + " OFF"}
                          </Title>
                          <Text type="secondary" style={{ fontSize: 15 }}>
                            {data.description}
                          </Text>
                        </Col>
                        <Col span={12} justify="center" align="right">
                          <Text
                            style={{
                              fontSize: 15,
                              marginRight: "5px",
                              color: "#E70736",
                            }}
                          >
                            {data.coupon}
                          </Text>
                        </Col>
                      </Row>
                      <div className="spacer"></div>
                      <Col span={24}>
                        <div className="flex">
                          <Image src={line} preview={false} />
                        </div>
                        <Row align="middle">
                          <Col span={12}>
                            <Text>
                              Save{" "}
                              {data.discount_type === "Flat"
                                ? "₹" + data.discount
                                : data.discount + "%"}{" "}
                              with this code
                            </Text>
                          </Col>
                          <Col span={12} align="right">
                            <Button
                              disabled={
                                applyCoupenLoader ||
                                cartData.coupon_code === data.coupon
                              }
                              className="button-comman color-btn"
                              htmlType="submit"
                              onClick={() =>
                                onApplyCoupenButton({
                                  coupon_code: data.coupon,
                                })
                              }
                            >
                              {cartData.coupon_code === data.coupon
                                ? "Applied"
                                : "Apply"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  );
                })}
            </Row>
          </>
        ) : (
          <Empty description="No Coupon Found" />
        )}
      </Modal>

      <Modal
        style={{
          top: 20,
        }}
        className="modal"
        // closable={false}
        onCancel={() => onCancleModalDelivery()}
        footer={false}
        title={
          <Row>
            <Col span={24}>
              <Title style={{ textAlign: "center" }} level={5}>
                Delivery
              </Title>
            </Col>
          </Row>
        }
        open={open}
      >
        <Radio.Group onChange={onChangeRadio} value={radioValue}>
          <Space direction="vertical">
            <Card
              className="modal-card"
              style={{
                width: "100%",
              }}
            >
              <Row gutter={[24, 9]} align="middle" justify="center">
                <Col span={16} className="p-0">
                  <Radio value={1}> Deliver at your door step</Radio>
                  <br />
                  <div className="pl-29">
                    <Text
                      type="secondary"
                      style={{
                        fontSize: "12px",
                        textAlign: "justify",
                        color: " #616161",
                      }}
                    >
                      For INR 50.00 your product(s) delivered to you.
                    </Text>
                    <br />
                    <Link to="/cart-address">
                      <Button
                        onClick={okText}
                        className="button-comman color-btn mt-10 w-100px "
                        htmlType="submit"
                        disabled={radioValue === 2 || radioValue === null}
                      >
                        Address
                      </Button>
                    </Link>
                  </div>
                </Col>
                <Col span={6} align="middle">
                  <Image preview={false} src={truck} />
                </Col>
              </Row>
            </Card>
            <Card className="w-100 mt-20 modal-card">
              <Row align="middle">
                <Col span={24}>
                  <Radio value={2}>
                    <Text className="font-15">Self Pickup From Our Outlet</Text>
                  </Radio>
                </Col>
                {radioValue === 2 && (
                  <>
                    <Col span={24} className="self-pickup-card">
                      <div style={{ paddingLeft: 24 }}>
                        <Text
                          type="secondary"
                          style={{
                            fontSize: "12px",
                            textAlign: "justify",
                            color: " #616161",
                          }}
                        >
                          SupaGrandealz LLP 503, Arista Business, Opp HOF House
                          Sindhubhavan Road, Bodakdev, Ahmedabad 380054, Gujarat
                          India
                        </Text>
                      </div>
                    </Col>
                    <Col span={24}>
                      <Button
                        style={{
                          marginTop: "10px",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                        align="center"
                        onClick={() => handleCountinue()}
                      >
                        Continue
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Card>
          </Space>
        </Radio.Group>
      </Modal>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return {
    cartData: state.wishlistCart.cartData,
    cartLoader: state.wishlistCart.cartLoader,
    quizLoader: state.wishlistCart.quizLoader,
    quizData: state.wishlistCart.quizData,
    donateLoader: state.wishlistCart.donateLoader,
    allocateOrderTicketLoader: state.order.allocateOrderTicketLoader,
    userProfileData: state.auth.userProfileData,
    drawLoader: state.home.drawLoader,
    drawData: state.home.drawData,
    addressData: state.location.addressData,
    coupanLoader: state.wishlistCart.coupanLoader,
    coupanData: state.tickets.coupanData,
    applyCoupenLoader: state.tickets.applyCoupenLoader,
    removeCoupenLoader: state.tickets.removeCoupenLoader,
    getOrderStatusLoader: state.order.getOrderStatusLoader,
    getOrderStatusData: state.order.getOrderStatusData,
    createBPayOrderLoader: state.order.createBPayOrderLoader,
    createBPayOrderData: state.order.createBPayOrderData,

    // order
  };
};
export default connect(mapStateToProps, {
  actionAddCart,
  actionGetCart,
  actionDonateToggle,
  actionIsVerified,
  actionGetDraws,
  actionEmptyCart,
  actionGetQuiz,
  actionGetCoupanAll,
  actionAddAllocateTickets,
  actionApplyCoupenCode,
  actionRemoveCoupenCode,
  actionCreateBpayOrder,
  actionBPayOrderStatus,
})(Cart);
