import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Segmented,
  Select,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  actionGetCountries,
  actionGetState,
  actionGetAddress,
  actionSingleAddressData,
  actionGetCity,
  actionUpdateAddress,
  actionAddAddress,
} from "../../store/actions/loacationAction";
import { connect } from "react-redux";

const Address = (props) => {
  const [value, setValue] = useState("Home");
  const [disableState, setDisableState] = useState(true);

  const [disableCity, setDisableCity] = useState(true);
  const [addressId, setaddressId] = useState("");

  const {
    actionGetCountries,
    actionGetState,
    actionGetAddress,
    actionUpdateAddress,
    singleAddressLoader,
    actionSingleAddressData,
    singleAddressData,
    actionGetCity,
    countryLoader,
    addressLoader,
    addressData,
    countryData,
    stateLoader,
    stateData,
    cityLoader,
    cityData,
    actionAddAddress,
    addAddressLoader,
    updateAddressLoader,
  } = props;
  const [form] = Form.useForm();

  const Country = [];
  countryData &&
    countryData.length &&
    countryData.map((data, index) => {
      Country.push({
        ...data,
        value: data.isoCode,
        label: data.name,
      });
    });

  const state = [];
  stateData &&
    stateData.length &&
    stateData.map((data, index) => {
      state.push({
        ...data,
        value: data.isoCode,
        label: data.name,
      });
    });

  const city = [];
  cityData &&
    cityData.length &&
    cityData.map((data, index) => {
      city.push({
        ...data,
        value: data.name,
        label: data.name,
      });
    });

  const checkIsExist = (value) => {
    const addressDataObj =
      addressData && addressData.find((data) => data.address_type === value);
    addressDataObj ? setaddressId(addressDataObj._id) : setaddressId("");

    console.log({ addressDataObj });

    return addressDataObj
      ? form.setFieldsValue({
          address: addressDataObj.address,
          name: addressDataObj.name,
          phone: addressDataObj.phone,
          locality_town: addressDataObj.locality_town,
          pincode: addressDataObj.pincode ? addressDataObj.pincode : "",
          country: addressDataObj.country.isoCode,
          state: addressDataObj.state.name,
          city: addressDataObj.city.name,
          id: addressDataObj._id,
        })
      : form.resetFields();
  };

  useMemo(() => {
    checkIsExist(value);
  }, [addressData, value, addressId]);

  const handleChangeState = (e, data) => {
    actionGetState(data.isoCode);
    setDisableState(false);
  };

  const handleChangeCity = (e, data) => {
    setDisableCity(false);
    actionGetCity(data.countryCode, data.isoCode);
  };

  const onChange = (e) => {
    setValue(e);
  };

  useEffect(() => {
    actionGetCountries();
    actionGetAddress();
  }, []);

  useMemo(() => {}, [addressData]);

  const onFinish = (values) => {
    const countryObj =
      countryData &&
      countryData.find((data) => data.isoCode === values.country);
    const stateObj =
      stateData && stateData.find((data) => data.isoCode === values.state);
    const cityObj =
      cityData && cityData.find((data) => data.name === values.city);
    const addressObj =
      addressData && addressData.find((data) => data.address_type === value);
    const data = {
      ...values,
      address_type: value,
      is_default_address: false,
      country: countryObj ? countryObj : addressObj.country,
      state: stateObj ? stateObj : addressObj.state,
      city: cityObj ? cityObj : addressObj.city,
    };
    addressId ? actionUpdateAddress(data, addressId) : actionAddAddress(data);
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <div className="container">
      <h1 className="font-bold">
        My <span className="text-line">Address</span>
        <h2>{Country.label}</h2>
      </h1>
      <div className="spacer" />
      <Row>
        <Col span={24}>
          <Segmented
            block
            size="large"
            options={["Home", "Work", "Other"]}
            value={value}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Form
        form={form}
        style={{ marginTop: "30px" }}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        size="large"
      >
        <Row align="space-between" gutter={[24, 16]}>
          <Col span={12}>
            <h3>Name</h3>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your Name!",
                },
              ]}
            >
              <Input placeholder="Name*" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>Phone No*</h3>

            <Form.Item
              maxLength={10}
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone!",
                },
                {
                  validator: (_, value) =>
                    value.length === 10
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Please enter 10 digits for Phone Number")
                        ),
                },
              ]}
            >
              <Input type="number" placeholder="phone No*" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>Address</h3>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please input your Address!",
                },
              ]}
            >
              <Input
                type="text"
                placeholder="House No, Building, street, Area*"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <h3>Locality / Town*</h3>
            <Form.Item
              name="locality_town"
              rules={[
                {
                  required: true,
                  message: "Please input Locality/Town!",
                },
              ]}
            >
              <Input type="text" placeholder="Locality/Town" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>Pin Code*</h3>
            <Form.Item
              name="pincode"
              rules={[
                {
                  required: true,
                  message: "Please input your Pin Code!",
                },
                {
                  validator: (_, value) =>
                    value.length <= 6
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "Please enter maximum 6 characters for Pin Code"
                          )
                        ),
                },
              ]}
            >
              <Input type="number" maxLength="6" placeholder=" Pin Code*" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>Country</h3>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please select your Country !",
                },
              ]}
            >
              <Select
                onSelect={handleChangeState}
                // loading={countryLoader}
                showSearch
                placeholder="Select Country"
                optionFilterProp="children"
                filterOption={(iv, op) =>
                  op.label
                    .toLocaleLowerCase()
                    .includes((iv || "").toLocaleLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={Country}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>State</h3>
            <Form.Item
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please select your State !",
                },
              ]}
            >
              <Select
                loading={stateLoader}
                onSelect={handleChangeCity}
                disabled={disableState ? true : false}
                showSearch
                placeholder="Select State"
                optionFilterProp="children"
                filterOption={(iv, op) =>
                  op.label
                    .toLocaleLowerCase()
                    .includes((iv || "").toLocaleLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={state}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h3>City</h3>
            <Form.Item
              name="city"
              rules={[{ required: true, message: "Please select your City !" }]}
            >
              <Select
                showSearch
                disabled={disableCity ? true : false}
                loading={cityLoader}
                placeholder="Select City"
                optionFilterProp="children"
                filterOption={(iv, op) =>
                  op.label
                    .toLocaleLowerCase()
                    .includes((iv || "").toLocaleLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={city}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <div className="spacer" />
            <Form.Item>
              <Button
                size="large"
                className="button-comman"
                htmlType="submit"
                loading={addAddressLoader || updateAddressLoader}
              >
                {addressId ? "Update" : "Add"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countryLoader: state.location.countryLoader,
    countryData: state.location.countryData,
    stateLoader: state.location.stateLoader,
    stateData: state.location.stateData,
    cityLoader: state.location.cityLoader,
    cityData: state.location.cityData,
    addressLoader: state.location.addressLoader,
    addressData: state.location.addressData,
    singleAddressLoader: state.location.singleAddressLoader,
    singleAddressData: state.location.singleAddressData,
    addAddressLoader: state.location.addAddressLoader,
    updateAddressLoader: state.location.updateAddressLoader,
  };
};
export default connect(mapStateToProps, {
  actionGetCountries,
  actionGetState,
  actionGetCity,
  actionUpdateAddress,
  actionSingleAddressData,
  actionGetAddress,
  actionAddAddress,
})(Address);
