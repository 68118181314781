import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Row, Image, Badge, Typography } from "antd";
import logo from "../../assets/comman/logo.svg";
import heart from "../../assets/comman/heart.svg";
import cart from "../../assets/comman/cart.svg";
import defaultimage from "../../assets/comman/default-image.svg";
import { connect } from "react-redux";
const { Title } = Typography;

const Header = (props) => {
  const { userProfileData, wishlistData, cartData , openRegisterModal } = props;
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const initialValue = 0;
  const sumWithInitial =
    cartData.draws &&
    cartData.draws.length &&
    cartData.draws.reduce(
      (accumulator, currentValue) => accumulator + currentValue.qty,
      initialValue
    );
  // console.log(sumWithInitial)
  return (
    <>
      <div
        className={isSticky ? "sticky header-container" : "header-container"}
      >
        <header className="main-header">
          <div className="activelink">
            <Link to="/">
              <img src={logo} alt="" width="170" />
            </Link>
          </div>
          <div className="menu">
            <ul>
              <li>
                <Link
                  to="/"
                  className={location.pathname === "/" ? "active" : ""}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/products"
                  className={location.pathname === "/products" ? "active" : ""}
                >
                  Products
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/draws"
                  className={location.pathname === "/draws" ? "active" : ""}
                >
                  Draws
                </Link>
              </li> */}

              <li>
                <Link
                  to="/how-it-works"
                  className={
                    location.pathname === "/how-it-works" ? "active" : ""
                  }
                >
                  How It Works
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  className={
                    location.pathname === "/contact-us" ? "active" : ""
                  }
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="cart">
            <Row gutter={[16, 0]} align="middle">
              <Link to="/wishlist">
                <Col span={4}>
                  <Badge
                    count={wishlistData ? wishlistData.length : 0}
                    color="#ea082e"
                  >
                    <img className="small-img" src={heart} alt="" width="25" />
                  </Badge>
                </Col>
              </Link>
              <Link to="/cart">
                <Col span={4}>
                  <Badge
                    count={sumWithInitial ? sumWithInitial : 0}
                    color="#ea082e"
                  >
                    <img className="small-img" src={cart} alt="" width="25" />
                  </Badge>
                </Col>
              </Link>
              <Col span={11}>
                {userProfileData.first_name ? (
                  <Title level={3} ellipsis={true}>
                    {userProfileData.first_name +
                      " " +
                      userProfileData.last_name}
                  </Title>
                ) : (
                  <Link to="/login">
                    <h3 onClick={openRegisterModal} > Login</h3>
                  </Link>
                )}
              </Col>
              <Col span={2} align="right">
                <Link to="/personal-detail">
                  <Image
                    width={55}
                    style={{ borderRadius: "50%", textAlign: "center" }}
                    src={
                      userProfileData.profile_pic
                        ? userProfileData.profile_pic
                        : defaultimage
                    }
                    preview={false}
                  />
                </Link>
              </Col>
            </Row>
          </div>
        </header>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    wishlistData: state.wishlistCart.wishlistData,
    cartData: state.wishlistCart.cartData,
  };
};
export default connect(mapStateToProps)(Header);
