import * as actionTypes from "../action";

const initialState = {
  wishlistLoader: false,
  wishlistData: [],
  cartLoader: false,
  cartData: [],
  quizLoader: false,
  quizData: [],
  addCartLoader: false,
  deleteWishlistLoader: false,
  addwishlistLoader: false,
  donateLoader: false,
  applyCoupenLoader: false,
  removeCoupenLoader: false,
};

const wishlistCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPLY_COUPEN_LOADER:
      return { ...state, applyCoupenLoader: action.payload };

    case actionTypes.REMOVE_COUPEN_LOADER:
      return { ...state, removeCoupenLoader: action.payload };

    case actionTypes.GET_WISHLIST_LOADER:
      return { ...state, wishlistLoader: action.payload };

    case actionTypes.GET_WISHLIST_DATA:
      return { ...state, wishlistData: action.payload };

    case actionTypes.DELETE_WISHLIST_LOADER:
      return { ...state, deleteWishlistLoader: action.payload };

    case actionTypes.DELETE_WISHLIST_DATA:
      return {
        ...state,
        wishlistData: [
          ...state.wishlistData.filter(
            (data) => data.draw._id !== action.payload.drawId
          ),
        ],
      };

    case actionTypes.GET_CART_LOADER:
      return { ...state, cartLoader: action.payload };

    case actionTypes.GET_CART_DATA:
      return { ...state, cartData: action.payload };

    case actionTypes.GET_QUIZ_LOADER:
      return { ...state, quizLoader: action.payload };

    case actionTypes.GET_QUIZ_DATA:
      return { ...state, quizData: action.payload };

    case actionTypes.ADD_CART_LOADER:
      return { ...state, addCartLoader: action.payload };

    case actionTypes.ADD_CART_DATA:
      return { ...state, cartData: action.payload };

    case actionTypes.ADD_WISHLIST_LOADER:
      return { ...state, addwishlistLoader: action.payload };

    case actionTypes.ADD_WISHLIST_DATA:
      return {
        ...state,
        wishlistData: [...state.wishlistData, action.payload],
      };

    case actionTypes.DONATE_LOADER:
      return { ...state, donateLoader: action.payload };

    case actionTypes.REMOVE_CART_DATA:
      return { ...state, cartData: action.payload };

    case actionTypes.DONATE_DATA:
      return {
        ...state,
        cartData: action.payload,
      };

    default:
      return state;
  }
};
export default wishlistCartReducer;
