import React, { useEffect } from "react";
import { Card, Image, Spin, Typography } from "antd";
import drawcar from "../../assets/comman/draw-car.png";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
const { Title, Text } = Typography;

const WinnerCard = (props) => {
  return (
    <Card
      hoverable
      style={{ padding: "15px" }}
      className="winner-card"
      cover={
        <>
          <Image
            src={props.image}
            preview={false}
            className="bg"
            alt="example"
          />
        </>
      }
    >
      <Title level={4} className="level4">
        {props.draw_title}
      </Title>
      <Text style={{ fontSize: 20 }}>{props.draw_sub_title}</Text>
      <div>
        <Text style={{ fontSize: 18, color: "#E70736" }}>
          {`₹${props.product_price}`}
        </Text>
      </div>
    </Card>
  );
};

export default WinnerCard;
