import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Statistic,
} from "antd";
import "antd/dist/antd.min.css";
import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import appstore from "../src/assets/comman/apple_placeholder.png";
import googleplaystore from "../src/assets/comman/google_placeholder.png";
import grandealzWhite from "../src/assets/comman/grandealz  White.png";
import {
  actionChangeMobile,
  actionGetUserProfile,
  actionIsUserLoggedIn,
  actionIsVerified,
  actionRegister,
  actionResendOtp,
  actionVerifyOtp,
} from "../src/store/actions/authAction";
import Address from "./components/Address/Address";
import ChangePassword from "./components/Auth/ChangePassword";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Login from "./components/Auth/Login";
import ResetPassword from "./components/Auth/ResetPassword";
import ActiveCoupon from "./components/Coupon/ActiveCoupon";
import ContactUs from "./components/Home/ContactUs";
import Draws from "./components/Home/Draws";
import Faq from "./components/Home/Faq";
import Home from "./components/Home/Home";
import HowitWorks from "./components/Home/HowitWorks";
import PrivacyPolicy from "./components/Home/PrivacyPolicy";
import RefundCancelPolicy from "./components/Home/RefundCancelPolicy";
import ShippingExchangePolicy from "./components/Home/ShippingExchangePolicy";
import Terms from "./components/Home/Terms";
import Products from "./components/Product/Product";
import ProductDetail from "./components/Product/ProductDetail";
import Cart from "./components/cart/Cart";
import CartAddress from "./components/cart/CartAddress";
import Payment from "./components/cart/Payment";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import PersonalDetailComman from "./components/common/PersonalDetailComman";
import Topheader from "./components/common/Topheader";
import setAuthToken from "./components/config/setAuthToken";
import Myorder from "./components/orders/Myorder";
import OrderDetail from "./components/orders/OrderDetail";
import PersonalDetail from "./components/personalDetail/PersonalDetail";
import Wishlist from "./components/wishlist/Wishlist";
import "./scss/index.scss";
import { actionGetAddress } from "./store/actions/loacationAction";
import {
  actionGetCart,
  actionGetWishlist,
} from "./store/actions/wishlistCartAction";

const { Countdown } = Statistic;

const App = (props) => {
  const {
    actionGetWishlist,
    actionGetCart,
    actionGetUserProfile,
    actionIsUserLoggedIn,
    isUserLoggedIn,
    actionRegister,
    registerLoader,
    userProfileData,
    verifyOtpLoader,
    actionVerifyOtp,
    actionResendOtp,
    isUserVerified,
    actionIsVerified,
    actionGetAddress,
    actionChangeMobile,
    changeMobileLoader,
  } = props;

  const [timeRemaining, setTimeRemaining] = useState("");
  const [otp, setOtp] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [isInitialOTPSent, setIsInitialOTPSent] = useState(false);
  const [ChangeMobileModalOpen, setChangeMobileModalOpen] = useState(false);

  const [maintenanceMode, setMaintenanceMode] = useState(false); // Set this to false when maintenance is done

  if (localStorage.GrandealzFrontendJwtToken) {
    actionIsUserLoggedIn(true);
    setAuthToken(localStorage.GrandealzFrontendJwtToken);
  }
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  useMemo(() => {
    setIsOtpModalOpen(isUserVerified);
  }, [isUserVerified]);

  useMemo(() => {}, [isUserLoggedIn]);

  useMemo(() => {
    if (userProfileData && userProfileData.roles === "bot") {
      setIsOtpModalOpen(true);
      timeRemaining == 0
        ? setTimeRemaining(0)
        : setTimeRemaining(Date.now() + 300 * 1000);
    }
  }, [userProfileData]);

  useEffect(() => {
    if (localStorage.GrandealzFrontendJwtToken) {
      actionIsUserLoggedIn(true);
      setAuthToken(localStorage.GrandealzFrontendJwtToken);
      actionGetUserProfile();
      actionGetWishlist();
      actionGetCart();
      actionGetAddress();
    }
  }, [localStorage.GrandealzFrontendJwtToken]);

  const RequireAuth = ({ children }) => {
    let location = useLocation();
    if (
      !localStorage.GrandealzFrontendJwtToken &&
      location.pathname === "/personal-detail"
    ) {
      return React.createElement(Navigate, {
        to: "/login",
        state: { from: location },
        replace: true,
      });
    }

    // if (maintenanceMode) {
    //   return React.createElement(Navigate, {
    //     to: "/",
    //     state: { from: location },
    //     replace: true,
    //   });
    // }

    if (!localStorage.GrandealzFrontendJwtToken) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return React.createElement(Navigate, {
        to: "/login",
        state: { from: location },
        replace: true,
      });
    }

    return children;
  };
  const failed = (errorInfo) => {
    console.log("Faidled:", errorInfo);
  };
  const onFinishMobile = (value) => {
    console.log(value);
    const result = actionChangeMobile(
      setIsOtpModalOpen,
      setChangeMobileModalOpen,
      form3,
      value
    );
    result && localStorage.setItem("ChangeMobileNumber", value.phone);
    setTimeRemaining(Date.now() + 300 * 1000);
    // form.resetFields();
  };

  const NotFound = () => {
    return <h1 style={{ textAlign: "center" }}>Not Found</h1>;
  };
  const changeMobileModal = () => {
    setIsOtpModalOpen(false);
    setChangeMobileModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const openRegisterModal = () => {
    if (!isUserLoggedIn || window.location.pathname === "/contact-us") {
      setIsModalOpen(true);
    } else {
      console.log("opened");
    }
  };

  const handleOkChangeMobile = () => {
    setChangeMobileModalOpen(false);
  };

  const handleCancelChangeMobile = () => {
    setChangeMobileModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOkOtp = () => {
    setIsOtpModalOpen(false);
  };
  const handleCancelOtp = () => {
    setIsOtpModalOpen(false);
    actionIsVerified(false);
  };

  const onFinish = (values) => {
    console.log(values);
    actionRegister(
      { ...values, referralcode: "" },
      setIsModalOpen,
      setIsOtpModalOpen,
      setTimeRemaining,
      form2,
      actionIsVerified
    );
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Faidled:", errorInfo);
  };

  const onFinishCount = () => {
    setTimeRemaining(0);
  };

  const resendOTPButton = async () => {
    const result = await actionResendOtp();
    result && setTimeRemaining(Date.now() + 300 * 1000);
  };

  const resendOTP = async () => {
    const result = await actionResendOtp();
    result && !isInitialOTPSent && setIsInitialOTPSent(true);
    result && setTimeRemaining(Date.now() + 300 * 1000);
  };

  const verifySubmitOTP = async (otp) => {
    console.log(otp);
    const result = actionVerifyOtp(otp, setIsOtpModalOpen);
    result && localStorage.removeItem("ChangeMobileNumber");
  };

  return (
    <>
      {isMobile ? (
        <>
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <Row style={{ background: "#000015", padding: "35px" }}>
              <Col span={24} align="middle">
                <Image preview={false} src={grandealzWhite} />
                <div className="spacer" />
              </Col>
              <Col span={24} align="middle">
                <h3 style={{ color: "white" }}>
                  For better experience please download app
                </h3>
                <div className="spacer" />
              </Col>
              <Col span={12} align="middle">
                <a
                  href="https://apps.apple.com/in/app/grandealz/id6447436349"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  <Image preview={false} src={appstore} />
                </a>
              </Col>
              <Col span={12} align="middle">
                <a
                  href="https://play.google.com/store/apps/details?id=com.v.g"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  <Image preview={false} src={googleplaystore} />
                </a>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <div
          id={`${isUserLoggedIn ? " " : "non-active"}`}
          className="overlay-popup"
          onClick={() =>
            isUserLoggedIn ||
            window.location.pathname === "/login" ||
            window.location.pathname === "/contact-us" ||
            window.location.pathname === "/faq" ||
            window.location.pathname === "/how-it-works" ||
            window.location.pathname === "/forgot-password" ||
            window.location.pathname === "/reset-password" ||
            window.location.pathname === "/privacy-policy" ||
            window.location.pathname === "/refund-policy" ||
            window.location.pathname === "/shipping-policy" ||
            window.location.pathname === "/terms"
              ? setIsModalOpen(false)
              : setIsModalOpen(true)
          }
        >
          <Router>
            <Topheader />
            <Header openRegisterModal={openRegisterModal} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}

              <Route
                path="/order-detail/:orderId"
                element={
                  <RequireAuth>
                    <OrderDetail />
                  </RequireAuth>
                }
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/draws"
                element={
                  <RequireAuth>
                    <Draws />
                  </RequireAuth>
                }
              />
              <Route path="/faq" element={<Faq />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/refund-policy" element={<RefundCancelPolicy />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/how-it-works" element={<HowitWorks />} />
              <Route
                path="/shipping-policy"
                element={<ShippingExchangePolicy />}
              />

              <Route
                path="/products"
                element={
                  <RequireAuth>
                    <Products />
                  </RequireAuth>
                }
              />
              <Route
                path="/product-detail/:productId"
                element={
                  <RequireAuth>
                    <ProductDetail />
                  </RequireAuth>
                }
              />
              <Route
                path="/cart"
                element={
                  <RequireAuth>
                    <Cart />
                  </RequireAuth>
                }
              />
              <Route
                path="/cart-address"
                element={
                  <RequireAuth>
                    <CartAddress />
                  </RequireAuth>
                }
              />
              <Route
                path="/cart-payment"
                element={
                  <RequireAuth>
                    <Payment />
                  </RequireAuth>
                }
              />
              <Route
                path="/change-password"
                element={
                  <RequireAuth>
                    <PersonalDetailComman component={<ChangePassword />} />
                  </RequireAuth>
                }
              />
              <Route
                path="/wishlist"
                element={
                  <RequireAuth>
                    <PersonalDetailComman component={<Wishlist />} />
                  </RequireAuth>
                }
              />
              <Route
                path="/personal-detail"
                element={
                  <RequireAuth>
                    <PersonalDetailComman component={<PersonalDetail />} />
                  </RequireAuth>
                }
              />
              <Route
                path="/my-order"
                element={
                  <RequireAuth>
                    <PersonalDetailComman component={<Myorder />} />
                  </RequireAuth>
                }
              />
              <Route
                path="/my-address"
                element={
                  <RequireAuth>
                    <PersonalDetailComman component={<Address />} />
                  </RequireAuth>
                }
              />
              <Route
                path="/active-tickets"
                element={
                  <RequireAuth>
                    <PersonalDetailComman component={<ActiveCoupon />} />
                  </RequireAuth>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </Router>
        </div>
      )}

      <Router>
        <Modal
          className="register-modal"
          centered
          title={<h2 style={{ textAlign: "center" }}>Register</h2>}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            form={form2}
            style={{ marginTop: "10px" }}
            name="basic"
            initialValues={{
              term_and_condition: true,
              country_phone_code: "+91",
            }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            size="large"
          >
            <Row align="space-between" gutter={[24, 16]}>
              <Col span={24}>
                <Form.Item
                  name="first_name"
                  normalize={(value) => value.replace(/[^a-zA-Z]/g, "").trim()}
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                    {
                      validator: (_, value) =>
                        value && value.length <= 15
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "Please enter maximum 15 characters for first Name"
                              )
                            ),
                    },
                  ]}
                >
                  <Input className="border-input" placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="last_name"
                  normalize={(value) => value.replace(/[^a-zA-Z]/g, "").trim()}
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                    {
                      validator: (_, value) =>
                        value && value.length <= 15
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "Please enter maximum 15 characters for last Name"
                              )
                            ),
                    },
                  ]}
                >
                  <Input className="border-input" placeholder="Last Name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      // message: "Please enter email!",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <Input className="border-input" placeholder="Email" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="country_phone_code"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Country !",
                    },
                  ]}
                >
                  <Select
                    className="border-input"
                    showSearch
                    placeholder="+91"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      {
                        value: "+91",
                        label: "+91",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Mobile Number!",
                      pattern: /^[0-9]+$/,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          value &&
                          value.match(/^[0-9]+$/) &&
                          value.length == 10
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Invalid Mobile Number!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    pattern="[0-9]*"
                    className="border-input"
                    type="number"
                    placeholder="Phone"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      min: 8,
                      message:
                        "passwords must be longer than or equal to 8 characters !",
                    },
                  ]}
                >
                  <Input.Password
                    className="border-input"
                    placeholder="Password"
                  />
                </Form.Item>
              </Col>
              <Col span={24} align="center">
                <Form.Item
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "Please accept Usage Terms & Privacy Policy !"
                              )
                            ),
                    },
                  ]}
                  name="term_and_condition"
                  valuePropName="checked"
                >
                  <Checkbox>
                    <div>
                      I agree to&nbsp;
                      <a style={{ color: "#EA082E" }} href="/terms">
                        Usage Terms&nbsp;
                      </a>
                      and&nbsp;
                      <a style={{ color: "#EA082E" }} href="/privacy-policy">
                        Privacy Policy
                      </a>
                    </div>
                  </Checkbox>
                </Form.Item>
                {/* <p style={{ fontSize: "13px" }} className="commans-display-flex">
                <Form.Item name="term_and_condition">
                  <Checkbox
                    valuePropName="checked"
                    style={{ color: "#EA082E" }}
                  ></Checkbox>
                  I agree to
                  <a style={{ color: "#EA082E" }} href="login">
                    Usage Tearms
                  </a>
                  and
                  <a style={{ color: "#EA082E" }} href="login">
                    Privacy Policy
                  </a>
                </Form.Item>
              </p> */}
              </Col>

              <Col span={24} align="center">
                <Form.Item>
                  <Button
                    loading={registerLoader}
                    style={{ width: "50%" }}
                    size="large"
                    className="button-comman"
                    htmlType="submit"
                  >
                    Register
                  </Button>
                </Form.Item>
              </Col>
              <Col span={24} align="center">
                <p>
                  Existing User &nbsp;
                  <a style={{ color: "#EA082E" }} href="login">
                    Log in
                  </a>
                </p>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Router>

      <Modal
        className="otp-modal"
        centered
        title={
          <h2 style={{ textAlign: "center" }}>
            {!isInitialOTPSent ? "OTP Verification" : "Confirm OTP"}
          </h2>
        }
        open={isOtpModalOpen}
        onOk={handleOkOtp}
        onCancel={handleCancelOtp}
        footer={null}
      >
        {!isInitialOTPSent && (
          <div id="initial-otp-sent-container">
            <p>
              Please enter the verification code that we have sent to the mobile
              number
              {`  ${userProfileData.country_phone_code}  ${
                localStorage.ChangeMobileNumber
                  ? localStorage.ChangeMobileNumber
                  : userProfileData.phone
              }`}
            </p>
            <div style={{ marginTop: "40px" }}></div>
            <Form
              form={form}
              onFinishFailed={failed}
              onFinish={verifySubmitOTP}
              autoComplete="off"
            >
              <Form.Item
                name="otp"
                //  rules={[
                //   {
                //     required: true,
                //     message: "Please input your OTP!",
                //   },
                //   {
                //     validator: (_, value) =>
                //       value.length === 4
                //         ? Promise.resolve()
                //         : Promise.reject(
                //           new Error("Please enter 4 digits for OTP")
                //         ),
                //   },
                // ]}
              >
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  className="otp-input"
                  name="otp"
                  numInputs={4}
                />
              </Form.Item>
              <Row style={{ margin: "20px" }} justify="space-between">
                <Col span={24} align="center">
                  {timeRemaining === 0 ? (
                    <p className="bg-main">
                      <Button
                        style={{ color: "#E70736" }}
                        onClick={() => resendOTPButton()}
                        type="text"
                      >
                        Resend
                      </Button>
                    </p>
                  ) : (
                    <Countdown
                      title="Time Ramaining"
                      value={timeRemaining}
                      onFinish={onFinishCount}
                      format="mm:ss"
                    />
                  )}
                </Col>

                <Col span={24} align="center">
                  <Form.Item>
                    <Button
                      loading={verifyOtpLoader}
                      style={{ minWidth: "200px", marginTop: "20px" }}
                      className="button-comman"
                      size="large"
                      disabled={otp.length < 4 ? true : false}
                      htmlType="submit"
                    >
                      Verify
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={24} align="center">
                  <Button
                    onClick={changeMobileModal}
                    className="remove-bg"
                    style={{ color: "#E70736", marginTop: "20px" }}
                    type="text"
                  >
                    Change mobile number
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Modal>

      <Modal
        className="otp-modal mobile-modal"
        centered
        title={<h2 style={{ textAlign: "center" }}>Change Mobile No</h2>}
        open={ChangeMobileModalOpen}
        onOk={handleOkChangeMobile}
        onCancel={handleCancelChangeMobile}
        footer={null}
      >
        <Form
          form={form3}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinishMobile}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="phone"
                normalize={(value) => value.trim()}
                rules={[
                  {
                    required: true,
                    message: "Please input your phone!",
                  },
                  {
                    validator: (_, value) =>
                      value && value.length === 10
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Please enter 10 digits for Phone Number")
                          ),
                  },
                ]}
              >
                <Input
                  placeholder="Mobile No"
                  className="mobile-input"
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ margin: "20px" }} justify="space-between">
            <Col span={24} align="center">
              <Form.Item>
                <Button
                  loading={changeMobileLoader}
                  style={{ minWidth: "200px", marginTop: "20px" }}
                  className="button-comman"
                  htmlType="submit"
                  size="large"
                >
                  Change Mobile No
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    isUserLoggedIn: state.auth.isUserLoggedIn,
    registerLoader: state.auth.registerLoader,
    verifyOtpLoader: state.auth.verifyOtpLoader,
    isUserVerified: state.auth.isUserVerified,
    changeMobileLoader: state.auth.changeMobileLoader,
  };
};
export default connect(mapStateToProps, {
  actionGetUserProfile,
  actionIsUserLoggedIn,
  actionGetWishlist,
  actionGetCart,
  actionRegister,
  actionVerifyOtp,
  actionResendOtp,
  actionIsVerified,
  actionGetAddress,
  actionChangeMobile,
})(App);
