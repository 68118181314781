import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import setAuthToken from "../../../src/components/config/setAuthToken";
import * as actionTypes from "../action";

export const actionGetWishlist = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_WISHLIST_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/wish-list?limit=200&skip=0`);
    dispatch({ type: actionTypes.GET_WISHLIST_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_WISHLIST_DATA,
        payload: result.data.data,
      });
    } else {
      dispatch({
        type: actionTypes.GET_WISHLIST_DATA,
        payload: result.data.data,
      });
    }
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("GrandealzFrontendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
    }
    dispatch({ type: actionTypes.GET_WISHLIST_LOADER, payload: false });
  }
};

export const actionGetCart = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CART_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/cart`);
    dispatch({ type: actionTypes.GET_CART_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_CART_DATA,
        payload: result.data.data,
      });
    } else
      dispatch({
        type: actionTypes.GET_CART_DATA,
        payload: result.data.data,
      });
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("GrandealzFrontendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
    }
    dispatch({ type: actionTypes.GET_CART_LOADER, payload: false });
  }
};

export const actionDeleteWishlist = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_WISHLIST_LOADER, payload: true });
  try {
    let result = await axios.delete(`${BASE_URL}/wish-list/${data.wishlistId}`);
    dispatch({ type: actionTypes.DELETE_WISHLIST_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.DELETE_WISHLIST_DATA,
        payload: data,
      });
    }
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("GrandealzFrontendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
    }
    dispatch({ type: actionTypes.DELETE_WISHLIST_LOADER, payload: false });
  }
};

export const actionAddCart = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.ADD_CART_LOADER, payload: true });
  try {
    let result = await axios.post(`${BASE_URL}/cart`, data);
    dispatch({ type: actionTypes.ADD_CART_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.ADD_CART_DATA,
        payload: result.data.data,
      });
    } else {
      message.error(result.data.message, 5);
    }
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("GrandealzFrontendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
    }
    dispatch({ type: actionTypes.ADD_CART_LOADER, payload: false });
  }
};

export const actionAddWishlist = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.ADD_WISHLIST_LOADER, payload: true });
  try {
    let result = await axios.post(`${BASE_URL}/wish-list`, data);
    dispatch({ type: actionTypes.ADD_WISHLIST_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.ADD_WISHLIST_DATA,
        payload: result.data.data,
      });
    } else {
      message.error(result.data.message, 5);
    }
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("GrandealzFrontendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
    }
    dispatch({ type: actionTypes.ADD_WISHLIST_LOADER, payload: false });
  }
};

export const actionDonateToggle = (id, data) => async (dispatch) => {
  dispatch({ type: actionTypes.DONATE_LOADER, payload: true });
  try {
    let result = await axios.put(`${BASE_URL}/cart/donate-toggle/${id}`, data);
    dispatch({ type: actionTypes.DONATE_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.DONATE_DATA,
        payload: result.data.data,
      });
    } else {
      message.error(result.data.message, 5);
    }
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("GrandealzFrontendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
    }
    dispatch({ type: actionTypes.DONATE_LOADER, payload: false });
  }
};

export const actionGetQuiz = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_QUIZ_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/orders/get-quiz`);
    dispatch({ type: actionTypes.GET_QUIZ_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_QUIZ_DATA,
        payload: result.data.data,
      });
    } else
      dispatch({
        type: actionTypes.GET_QUIZ_DATA,
        payload: result.data.data,
      });
  } catch (error) {
    dispatch({ type: actionTypes.GET_QUIZ_LOADER, payload: false });
  }
};

export const actionEmptyCart = () => async (dispatch) => {
  dispatch({ type: actionTypes.REMOVE_CART_DATA, payload: [] });
};

export const actionApplyCoupenCode = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.APPLY_COUPEN_LOADER, payload: true });
  try {
    let result = await axios.put(`${BASE_URL}/cart/apply-coupon`, data);
    dispatch({ type: actionTypes.APPLY_COUPEN_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_CART_DATA,
        payload: result.data.data,
      });
      return true;
    } else {
      message.error(result.data.message, 5);
      return false;
    }
  } catch (error) {
    dispatch({ type: actionTypes.APPLY_COUPEN_LOADER, payload: false });
    return false;
  }
};
export const actionRemoveCoupenCode = () => async (dispatch) => {
  dispatch({ type: actionTypes.REMOVE_COUPEN_LOADER, payload: true });
  try {
    let result = await axios.put(`${BASE_URL}/cart/remove-coupon`);
    dispatch({ type: actionTypes.REMOVE_COUPEN_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_CART_DATA,
        payload: result.data.data,
      });
    } else {
      message.error(result.data.message, 5);
    }
  } catch (error) {
    dispatch({ type: actionTypes.REMOVE_COUPEN_LOADER, payload: false });
  }
};
