import * as actionTypes from "../action";

const initialState = {
  countryLoader: false,
  countryData: [],
  stateLoader: false,
  stateData: [],
  cityLoader: false,
  cityData: [],
  addressLoader: false,
  updateAddressLoader: false,
  addressData: [],
  singleAddressLoader: false,
  addAddressLoader: false,
  singleAddressData: {},
  addAddressData: [],
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_ADDRESS_LOADING:
      return { ...state, addAddressLoader: action.payload };

    case actionTypes.COUNTRY_LOADER:
      return { ...state, countryLoader: action.payload };

    case actionTypes.COUNTRY_DATA:
      return { ...state, countryData: action.payload };

    case actionTypes.STATE_LOADER:
      return { ...state, stateLoader: action.payload };

    case actionTypes.STATE_DATA:
      return { ...state, stateData: action.payload };

    case actionTypes.CITY_LOADER:
      return { ...state, cityLoader: action.payload };

    case actionTypes.CITY_DATA:
      return { ...state, cityData: action.payload };

    case actionTypes.GET_ADDRESS_LOADER:
      return { ...state, addressLoader: action.payload };

    case actionTypes.GET_ADDRESS_DATA:
      return { ...state, addressData: action.payload };

    case actionTypes.UPDATE_ADDRESS_LOADING:
      return { ...state, updateAddressLoader: action.payload };

    case actionTypes.GET_SINGLE_ADDRESS_LOADER:
      return { ...state, singleAddressLoader: action.payload };

    case actionTypes.GET_SINGLE_ADDRESS:
      return { ...state, singleAddressData: action.payload };

    case actionTypes.ADD_ADDRESS_DATA:
      return {
        ...state,
        addressData: [...state.addressData, action.payload],
      };

    case actionTypes.UPDATE_ADDRESS_DATA:
      return {
        ...state,
        addressData: state.addressData.map((data) =>
          data._id === action.payload._id ? action.payload : data
        ),
      };

    default:
      return state;
  }
};
export default locationReducer;
