import React from "react";
import { Card, Col, Row, Typography } from "antd";

const RefundCancelPolicy = () => {
  const { Title } = Typography;
  return (
    <div className="wrapper">
      <Title className="text-line" level={1}>
        {" "}
        Refund & Cancellation Policy{" "}
      </Title>
      <div className="spacer"></div>
      <br />

      <Row align="middle" gutter={[16, 8]}>
        <Col span={24} align="left">
          <Card title={<h3>Returns </h3>} bordered={false}>
            <p className="faq-paragraph">
              Our policy lasts 30 days. If 30 days have gone by since your
              purchase, unfortunately we can’t offer you a refund or exchange.
            </p>
            <br />
            <p className="faq-paragraph">
              To be eligible for a return, your item must be unused and in the
              same condition that you received it. It must also be in the
              original packaging.
            </p>
            <br />
            <p className="faq-paragraph">
              Several types of goods are exempt from being returned. Perishable
              goods such as food, flowers, newspapers or magazines cannot be
              returned. We also do not accept products that are intimate or
              sanitary goods, hazardous materials, or flammable liquids or
              gases.
            </p>
            <br />
            <h3>Additional non-returnable items:</h3>
            <ul>
              <li className="faq-paragraph">Gift cards</li>
              <li className="faq-paragraph">Downloadable software products</li>
              <li className="faq-paragraph">
                Some health and personal care items
              </li>
            </ul>
            <h4>
              To complete your return, we require a receipt or proof of
              purchase.
            </h4>
            <br />
            <p>
              Please do not send your purchase back to the manufacturer.
              <br />
              There are certain situations where only partial refunds are
              granted: (if applicable) Book with obvious signs of use CD, DVD,
              VHS tape, software, video game, cassette tape, or vinyl record
              that has been opened. Any item not in its original condition, is
              damaged or missing parts for reasons not due to our error. Any
              item that is returned more than 30 days after delivery
            </p>
          </Card>
        </Col>
        <Col span={24} align="left">
          <Card
            title={<h3 className="">Refunds (if applicable)</h3>}
            bordered={false}
          >
            <p className="faq-paragraph">
              Once your return is received and inspected, we will send you an
              email to notify you that we have received your returned item. We
              will also notify you of the approval or rejection of your refund.
            </p>
            <br />
            <p className="faq-paragraph">
              If you are approved, then your refund will be processed, and a
              credit will automatically be applied to your credit card or
              original method of payment, within 7 working days.
            </p>
          </Card>
        </Col>
        <Col span={24} align="left">
          <Card
            title={
              <h3 className="">Late or missing refunds (if applicable)</h3>
            }
            bordered={false}
          >
            <p className="faq-paragraph">
              If you haven’t received a refund yet, first check your bank
              account again. Then contact your credit card company, it may take
              some time before your refund is officially posted.
            </p>
            <br />
            <p className="faq-paragraph">
              Next contact your bank. There is often some processing time before
              a refund is posted. If you’ve done all of this and you still have
              not received your refund yet, please contact us at
              <a style={{ color: "black" }} href="mailto:info@grandealz.com">
                {" "}
                info@grandealz.com
              </a>
            </p>
          </Card>
        </Col>
        <Col span={24} align="left">
          <Card
            title={<h3 className="">Sale items (if applicable)</h3>}
            bordered={false}
          >
            <p className="faq-paragraph">
              Only regular priced items may be refunded, unfortunately sale
              items cannot be refunded.
            </p>
          </Card>
        </Col>
        <Col span={24} align="left">
          <Card
            title={<h3 className="">Exchanges (if applicable)</h3>}
            bordered={false}
          >
            <p className="faq-paragraph">
              We only replace items if they are defective or damaged. If you
              need to exchange it for the same item, send us an email at{" "}
              <a style={{ color: "black" }} href="mailto:info@grandealz.com">
                {" "}
                info@grandealz.com
              </a>{" "}
              and send your item to: 622 Manglam Electronic Market Jaipur
              Rajasthan India 302001.
            </p>
          </Card>
        </Col>
        <Col span={24} align="left">
          <Card title={<h3 className="">Gifts</h3>} bordered={false}>
            <p className="faq-paragraph">
              If the item was marked as a gift when purchased and shipped
              directly to you, you’ll receive a gift credit for the value of
              your return. Once the returned item is received, a gift
              certificate will be mailed to you.
            </p>
            <p className="faq-paragraph">
              If the item wasn’t marked as a gift when purchased, or the gift
              giver had the order shipped to themselves to give to you later, we
              will send a refund to the gift giver and he will find out about
              your return.
            </p>
          </Card>
        </Col>
        <Col span={24} align="left">
          <Card title={<h3 className="">Shipping</h3>} bordered={false}>
            <p className="faq-paragraph">
              To return your product, you should mail your product to: 622
              Manglam Electronic Market Jaipur Rajasthan India 302001.
            </p>
            <br />
            <p className="faq-paragraph">
              You will be responsible for paying for your own shipping costs for
              returning your item. Shipping costs are non-refundable. If you
              receive a refund, the cost of return shipping will be deducted
              from your refund.
            </p>
            <p className="faq-paragraph">
              Delivery Time Variations: From 7 to 14 Days, Depending on Your
              Location.
            </p>
            <p className="faq-paragraph">
              If you are shipping an item over $75, you should consider using a
              trackable shipping service or purchasing shipping insurance. We
              don’t guarantee that we will receive your returned item.
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RefundCancelPolicy;
