import { message } from "antd";
import axios from "axios";
import setAuthToken from "../../../src/components/config/setAuthToken";
import { BASE_URL } from "../../../src/components/config/web-config";
import * as actionTypes from "../action";

export const actionRegister =
  (
    userData,
    setIsModalOpen,
    setIsOtpModalOpen,
    setTimeRemaining,
    form,
    actionIsVerified
  ) =>
  async (dispatch, getState) => {
    dispatch({ type: actionTypes.REGISTER_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/auth/register`, userData);
      dispatch({ type: actionTypes.REGISTER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        const { access_token, roles } = result.data.data;
        setAuthToken(access_token);
        dispatch({
          type: actionTypes.USER_PROFILE_DATA,
          payload: result.data.data,
        });
        dispatch({ type: actionTypes.IS_USER_LOGGED_IN, payload: true });
        setIsModalOpen && setIsModalOpen(false);
        setIsOtpModalOpen && roles === "bot" && setIsOtpModalOpen(true);
        setTimeRemaining && setTimeRemaining(Date.now() + 300 * 1000);
        form && form.resetFields();
        message.success(result.data.message, 5);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.REGISTER_LOADING, payload: false });
    }
  };

export const actionLogin =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.LOGIN_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/auth/login`, userData);
      dispatch({ type: actionTypes.LOGIN_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        const { access_token } = result.data.data;
        setAuthToken(access_token);
        dispatch({
          type: actionTypes.USER_PROFILE_DATA,
          payload: result.data.data.user,
        });
        dispatch({ type: actionTypes.IS_USER_LOGGED_IN, payload: true });
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.response.data.message, 5);
      dispatch({ type: actionTypes.LOGIN_LOADING, payload: false });
    }
  };

export const actionVerifyOtp =
  (userData, setIsOtpModalOpen) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.VERIFY_OTP_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/auth/verify-otp`, userData);
      dispatch({ type: actionTypes.VERIFY_OTP_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.VERIFY_OTP,
          payload: "user",
        });
        setIsOtpModalOpen && setIsOtpModalOpen(false);
        message.success(result.data.message, 5);
      } else message.error(result.data.message, 5);
    } catch (error) {
      if (error.response.data.message === "Unauthorized") {
        localStorage.removeItem("GrandealzFrontendJwtToken");
        setAuthToken(false);
        window.location.replace("/login");
      }
      message.error(error.response.data.message, 5);
      dispatch({ type: actionTypes.VERIFY_OTP_LOADING, payload: false });
    }
  };

export const actionResendOtp = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.RESEND_OTP_LOADING, payload: true });
  try {
    let result = await axios.post(`${BASE_URL}/auth/resend-otp`);
    dispatch({ type: actionTypes.RESEND_OTP_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      message.success(result.data.message, 5);
      return true;
    } else {
      message.error(result.data.message, 5);
      return false;
    }
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("GrandealzFrontendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
    }
    message.error(error.response.data.message, 5);
    dispatch({ type: actionTypes.RESEND_OTP_LOADING, payload: false });
    return false;
  }
};

export const actionGetUserProfile = () => async (dispatch) => {
  dispatch({ type: actionTypes.USER_PROFILE_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/auth/me`);
    dispatch({ type: actionTypes.USER_PROFILE_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.USER_PROFILE_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("GrandealzFrontendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
    }
    dispatch({ type: actionTypes.USER_PROFILE_LOADING, payload: false });
  }
};

export const logoutUser = (navigate) => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("GrandealzFrontendJwtToken");
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch({ type: actionTypes.REMOVE_USER_PROFILE_DATA, payload: {} });
  dispatch({ type: actionTypes.IS_USER_LOGGED_IN, payload: false });
  dispatch({ type: actionTypes.GET_CART_DATA, payload: [] });
  dispatch({ type: actionTypes.GET_WISHLIST_DATA, payload: [] });
  navigate("/login");
};

export const actionUpdateUserProfile =
  (userData, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/auth`, userData);
      dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.USER_PROFILE_DATA,
          payload: result.data.data,
        });
        message.success(result.data.message, 5);
      } else message.error(result.data.message, 5);
    } catch (error) {
      if (error.response.data.message === "Unauthorized") {
        localStorage.removeItem("GrandealzFrontendJwtToken");
        setAuthToken(false);
        window.location.replace("/login");
      }
      message.error(error.message, 5);
    }
  };

export const actionUploadFile = (formData) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.UPLOAD_PIC_LOADING, payload: true });
  try {
    let result = await axios.post(`${BASE_URL}/upload-file`, formData, {
      mode: "no-cors",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: actionTypes.UPLOAD_PIC_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.UPLOAD_PIC_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
  }
};

export const actionForgotPassword =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.FORGOT_PASSWORD_LOADER, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/auth/forgote-password`,
        userData
      );
      dispatch({ type: actionTypes.FORGOT_PASSWORD_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/login`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
    }
  };

export const actionChangePassword =
  (userData, reset) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.CHANGE_PASSWORD_LOADER, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/auth/change-password`,
        userData
      );
      dispatch({ type: actionTypes.CHANGE_PASSWORD_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        reset();
      } else message.error(result.data.message, 5);
    } catch (error) {
      if (error.response.data.message === "Unauthorized") {
        localStorage.removeItem("GrandealzFrontendJwtToken");
        setAuthToken(false);
        window.location.replace("/login");
      }
      message.error(error.message, 5);
    }
  };

export const actionResetPassword =
  (userData, navigate, token) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.RESET_PASSWORD_LOADER, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/auth/reset-password`,
        userData
      );
      dispatch({ type: actionTypes.RESET_PASSWORD_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/login`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      if (error.response.data.message === "Unauthorized") {
        localStorage.removeItem("GrandealzFrontendJwtToken");
        setAuthToken(false);
        window.location.replace("/login");
      }
      message.error(error.message, 5);
      dispatch({ type: actionTypes.RESET_PASSWORD_LOADER, payload: false });
    }
  };

export const actionIsUserLoggedIn = (status) => async (dispatch) => {
  dispatch({ type: actionTypes.IS_USER_LOGGED_IN, payload: status });
};

export const actionIsVerified = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.IS_USER_VERIFIED, payload: data });
};

export const actionsetProfilePic = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.SET_PROFILE, payload: data });
};

export const actionGetCountries = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COUNTRY_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/location/get-all-countries`);
    dispatch({ type: actionTypes.GET_COUNTRY_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_COUNTRY_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    if (error.response.data.message === "Unauthorized") {
      localStorage.removeItem("GrandealzFrontendJwtToken");
      setAuthToken(false);
      window.location.replace("/login");
    }
    dispatch({ type: actionTypes.GET_COUNTRY_LOADER, payload: false });
  }
};

export const actionGetDrawDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_DRAW_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/draws/${id}`);
    dispatch({ type: actionTypes.GET_DRAW_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_DRAW_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_DRAW_DETAIL_LOADING, payload: false });
  }
};

export const actionChangeMobile =
  (setIsOtpModalOpen, setChangeMobileModalOpen, form, userData) =>
  async (dispatch, getState) => {
    dispatch({ type: actionTypes.CHANGE_MOBILE_LOADER, payload: true });
    try {
      let result = await axios.post(
        `${BASE_URL}/auth/change-mobile-number`,
        userData
      );
      dispatch({ type: actionTypes.CHANGE_MOBILE_LOADER, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.CHANGE_MOBILE_DATA,
          payload: result.data.data,
        });
        message.success(result.data.message, 5);
        setIsOtpModalOpen && setIsOtpModalOpen(true);
        setChangeMobileModalOpen && setChangeMobileModalOpen(false);
        form && form.resetFields();
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.CHANGE_MOBILE_LOADER, payload: {} });
    }
  };
