import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";

export const actionGetActiveTickets = () => async (dispatch) => {
  dispatch({ type: actionTypes.ACTIVE_TICKETS_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/orders/active-tickets`);
    dispatch({ type: actionTypes.ACTIVE_TICKETS_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.ACTIVE_TICKETS_DATA,
        payload: result.data.data,
      });
    }
    dispatch({
      type: actionTypes.ACTIVE_TICKETS_DATA,
      payload: result.data.data,
    });
  } catch (error) {
    dispatch({ type: actionTypes.ACTIVE_TICKETS_LOADER, payload: false });
  }
};



export const actionGetCoupanAll = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COUPAN_LOADER, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/coupon`);
    dispatch({ type: actionTypes.GET_COUPAN_LOADER, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_COUPAN__DATA,
        payload: result.data.data,
      });
    }
    dispatch({
      type: actionTypes.GET_COUPAN__DATA,
      payload: result.data.data,
    });
  } catch (error) {
    dispatch({ type: actionTypes.GET_COUPAN_LOADER, payload: false });
  }
};


