import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Modal,
  Row,
  Spin,
  Steps,
  Typography,
} from "antd";
import dayjs from "dayjs";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { BASE_URL } from "../config/web-config";

import { useParams } from "react-router";
import { Link } from "react-router-dom";
import getticket from "../../assets/comman/getticket.png";
import Logopaynow from "../../assets/comman/Logo-paynow.svg";
import orderconfirm from "../../assets/comman/order-confirm.svg";
import orderimage from "../../assets/comman/order.svg";
import {
  actionAddAllocateTickets,
  actionGetOrderDetail,
  actionGetOrderInvoice,
} from "../../store/actions/orderAction";
import { actionGetQuiz } from "../../store/actions/wishlistCartAction";
const { Text, Title, Paragraph } = Typography;
const items = [
  {
    title: <p className="order-title">Order Confirmed</p>,
    //description: "Fri, 11th Fab ‘22",
  },
  {
    title: <p className="order-title">Shipped</p>,
    //description: "Fri, 13th Fab ‘22",
  },
  {
    title: <p className="order-title">Out Of Delievery</p>,
    //description: "Fri, 15th Fab ‘22",
  },
  {
    title: <p className="order-title">Delivered</p>,
    //description: "Fri, 15th Fab ‘22",
  },
];

const OrderDetail = (props) => {
  const params = useParams();
  const { orderId } = params;
  const {
    actionGetOrderDetail,
    actionGetQuiz,
    singleOrdersLoader,
    singleOrdersData,
    quizLoader,
    quizData,
    actionAddAllocateTickets,
    allocateOrderTicketLoader,
  } = props;
  const [isPayNowModalOpen, setIsPayNowModalOpen] = useState(false);
  const [openStartModal, setOpenStartModal] = useState(false);

  const [quizGame, setQuizGame] = useState(false);
  const [error, setError] = useState(false);
  const [randomQuiz, setRandomQuiz] = useState("");
  const [getTicket, setGetTicket] = useState(false);
  const [playNowButton, setPlayNowButton] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [invoiceLoader, setInvoiceLoader] = useState(false);

  useEffect(() => {
    actionGetOrderDetail(orderId);
    actionGetQuiz();
  }, []);

  const downloadInvoice = () => {
    setInvoiceLoader(true);
    axios({
      url: `${BASE_URL}/orders/invoice/${singleOrdersData._id}`, // replace with your server-side endpoint
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      setInvoiceLoader(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  const items = [];
  var current = 0;

  if (
    singleOrdersData.order_status === "Confirmed" ||
    singleOrdersData.order_status === "Shiped" ||
    singleOrdersData.order_status === "Out For Delivery" ||
    singleOrdersData.order_status === "Delivered"
  ) {
    items.push({
      title: "Confirmed",
    });
    items.push({
      title: "Shipped",
    });
    items.push({
      title: "Out For Delivery",
    });
    items.push({
      title: "Delivered",
    });
  }
  if (singleOrdersData.order_status === "Confirmed") {
    current = 0;
  } else if (singleOrdersData.order_status === "Shiped") {
    current = 1;
  } else if (singleOrdersData.order_status === "Out For Delivery") {
    current = 2;
  } else if (singleOrdersData.order_status === "Delivered") {
    current = 3;
  } else {
    current = 0;
  }

  useMemo(() => {
    const randomIndex = Math.floor(Math.random() * quizData.length);
    const randomQuizData = quizData[randomIndex];
    setRandomQuiz(randomQuizData);

    singleOrdersData.draws &&
      singleOrdersData.draws.length &&
      singleOrdersData.draws.map((data) => {
        data.draw_tickets && data.draw_tickets.length <= 0
          ? setPlayNowButton(true)
          : setPlayNowButton(false);
      });
  }, [quizData, singleOrdersData]);

  const CheckQuizAns = (d) => {
    if (randomQuiz.ans === d) {
      actionAddAllocateTickets(singleOrdersData._id, setQuizGame, setGetTicket);
    } else {
      setError(true);
    }
  };

  const onClickTryAgain = () => {
    actionGetQuiz();
    setError(false);
  };

  const OpenStartModal = () => {
    setIsPayNowModalOpen(false);
    setQuizGame(true);
    // setIsPayNowModalOpen(false);
    // setOpenStartModal(true);
  };

  const quiz = () => {
    setOpenStartModal(false);
    setQuizGame(true);
  };

  return (
    <Spin spinning={singleOrdersLoader}>
      <div className="main-wrapper">
        <div className="container my-order">
          <h1 className="font-bold">
            Order <span className="text-line"> Detail</span>
          </h1>
          <div className="spacer" />
          <Card bordered={false}>
            <Row>
              <Col span={16}>Orders ID - {orderId.toUpperCase()}</Col>
              {playNowButton && (
                <Col span={8} align="right">
                  <Button
                    onClick={() => setQuizGame(true)}
                    className="button-play-now"
                  >
                    Play Now
                  </Button>
                </Col>
              )}
            </Row>
            <Col span={24}>
              Order Date -{" "}
              {dayjs(singleOrdersData.createdAt).format("DD MMMM, YYYY")}
            </Col>
          </Card>
          <div className="spacer" />

          <Card bordered={false}>
            {singleOrdersData.draws &&
              singleOrdersData.draws.length &&
              singleOrdersData.draws.map((data, index) => {
                return (
                  <div key={index}>
                    <Row justify="space-around" align="middle">
                      <Col span={4}>
                        <Image
                          style={{ borderRadius: "10px" }}
                          preview={false}
                          width={150}
                          className="image-background"
                          src={data.draw.product_image}
                          alt="product-image"
                        />
                      </Col>
                      <Col span={4}>
                        <p className="order-title">{data.draw.product_title}</p>
                        <p className="order-description">
                          {data.draw.draw_title}
                        </p>
                      </Col>
                      <Col span={4}>
                        <p className="order-price">
                          ₹ {data.draw.product_price}
                        </p>
                      </Col>
                      <Col span={3}>
                        <p className="order-price">Quantity {data.qty}</p>
                      </Col>
                      {/* <Col span={4}>
                        <p className="order-sub-description">
                          {data.draw_tickets
                            ? `${data.draw_tickets.length} Ticket`
                            : "Avail Your Ticket"}
                        </p>
                      </Col> */}

                      <Col span={4}>
                        <p className="order-sub-description">
                          Draw Date{" "}
                          {data.draw.draw_date
                            ? dayjs(data.draw.draw_date).format("DD MMMM, YYYY")
                            : dayjs(data.draw.max_draw_date).format(
                                "DD MMMM, YYYY"
                              )}
                        </p>
                      </Col>
                    </Row>
                    <Divider className="custom-devider" />
                  </div>
                );
              })}
          </Card>
          <div className="spacer" />
          {!singleOrdersData.is_donate && singleOrdersData.address && (
            <>
              <Card bordered={false}>
                <Row gutter={[24, 16]} justify="space-between" align="middle">
                  <Col span={21} align="middle">
                    <Steps
                      progressDot={true}
                      current={current}
                      size="small"
                      labelPlacement="vertical"
                      items={items}
                    />
                  </Col>
                </Row>
              </Card>
              <div className="spacer" />
              <>
                <Card bordered={false}>
                  <p className="order-title">Shipping Details</p>
                  <p className="order-sub-description">
                    {singleOrdersData.address.name}
                  </p>
                  <p className="order-sub-description">
                    {`${singleOrdersData.address.address}, ${singleOrdersData.address.city.name}  ${singleOrdersData.address.state.name}  ${singleOrdersData.address.country.name} ${singleOrdersData.address.pincode}.`}
                  </p>
                  <p className="order-sub-description">
                    Mobile:{" "}
                    <span className="order-title">
                      {" "}
                      {singleOrdersData.address.phone}
                    </span>
                  </p>
                </Card>
                <div className="spacer" />
              </>
            </>
          )}
          <Card bordered={false}>
            <Row gutter={[24, 16]} justify="space-between" align="middle">
              <Col span={24} className="order-title">
                Price Details
              </Col>
              <Col span={24}>
                <Divider className="order-divider" />
              </Col>
              <Col span={12} align="left">
                Total MRP
              </Col>
              <Col span={12} align="right">
                ₹ {singleOrdersData.sub_total}
              </Col>
              <Col span={12} align="left">
                Tax (GST)
              </Col>
              <Col span={12} align="right">
                ₹ {singleOrdersData.tax}
              </Col>
              {/* <Col span={12} align="left">
              Promo Code
            </Col>
            <Col span={12} align="right">
              ₹ 10.00
            </Col> */}
              <Col span={24}>
                <Divider className="order-divider" />
              </Col>
              <Col span={12} align="left">
                Total Amount
              </Col>
              <Col span={12} align="right">
                ₹ {singleOrdersData.total}
              </Col>
            </Row>
          </Card>
          <div className="spacer" />

          <Card bordered={false}>
            <Row>
              <Col span={12}>
                <p>
                  {" "}
                  {singleOrdersData.payment &&
                    singleOrdersData.payment.method[0].toUpperCase() +
                      singleOrdersData.payment.method.substring(1)}{" "}
                  ₹ {singleOrdersData.total}
                </p>
              </Col>
              <Col span={12}>
                {invoiceLoader ? (
                  <p style={{ textAlign: "right" }}>
                    {" "}
                    Invoice
                    <span className="pink-text" style={{ cursor: "pointer" }}>
                      {" "}
                      Loading
                    </span>
                  </p>
                ) : (
                  <p
                    onClick={() => downloadInvoice()}
                    style={{ textAlign: "right" }}
                  >
                    {" "}
                    Invoice
                    <span className="pink-text" style={{ cursor: "pointer" }}>
                      {" "}
                      Download
                    </span>
                  </p>
                )}
              </Col>
            </Row>
            {/* {singleOrdersData.payment &&
              singleOrdersData.payment.method[0].toUpperCase() +
              singleOrdersData.payment.method.substring(1)}{" "}
            ₹ {singleOrdersData.total} */}
          </Card>
        </div>
      </div>

      <Modal
        centered
        className=" pay-now-modal"
        footer={false}
        open={isPayNowModalOpen}
        onCancel={() => setIsPayNowModalOpen(false)}
      >
        <Row align="middle" className="p-30 border-none-input">
          <Col span={24} align="middle">
            <Image preview={false} src={orderimage} alt="alt-image" />
            <Title level={4} className="paynow-text">
              Order Confirmed
            </Title>
            <Image preview={false} src={orderconfirm} alt="alt-image" />
            <br />
            <br />
            <Text className="paynow-text">
              Answer the question and avail your tickets
            </Text>
            <br />
            <br />
            <Button
              onClick={OpenStartModal}
              className="button-comman playnow-btn"
              htmlType="submit"
            >
              Play Now
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* Quiz Page Modal */}

      <Modal
        loading={quizLoader || allocateOrderTicketLoader}
        centered
        className="quiz-modal pay-now-modal"
        footer={false}
        open={quizGame}
        onCancel={() => setQuizGame(false)}
      >
        <Row align="middle" className="border-none-input px-20">
          <Col span={24} align="middle">
            <Image preview={false} src={Logopaynow} alt="alt-image" />
          </Col>
          <Card
            className="startquiz-card card-quiz min-height "
            bordered={false}
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            {error ? (
              <>
                <p className="quiz-text1">
                  your answer is wrong please try again to play this game.
                </p>
                <br />
                <div className="mt-15">
                  <Text className="text1" onClick={onClickTryAgain}>
                    Try Again
                  </Text>
                </div>
              </>
            ) : (
              <>
                <Title level={4} className="quiz-text1">
                  {randomQuiz ? randomQuiz.q : "---"}
                </Title>
                <section className="sec-box">
                  {randomQuiz && randomQuiz.options.length
                    ? randomQuiz.options.map((d, i) => {
                        return (
                          <Button
                            disabled={error}
                            size="large"
                            onClick={() => CheckQuizAns(d)}
                            id="quiz-box"
                            key={i}
                          >
                            {d}
                          </Button>
                        );
                      })
                    : ""}
                </section>
              </>
            )}
          </Card>
        </Row>
      </Modal>

      {/* Ticket Page Modal */}

      <Modal
        centered
        className="quiz-modal pay-now-modal"
        footer={false}
        open={getTicket}
        onCancel={() => setGetTicket(false)}
      >
        <Row align="middle" className="border-none-input px-20">
          <Col span={24} align="middle">
            <Image preview={false} src={Logopaynow} alt="alt-image" />
          </Col>
          <Card
            className="startquiz-card card-quiz "
            bordered={false}
            style={{
              textAlign: "center",

              width: "100%",
            }}
          >
            <Row
              justify="center"
              align="middle"
              className="border-none-input px-20"
            >
              <Col span={24} align="middle">
                <Image preview={false} src={getticket} alt="alt-image" />
                <Title
                  level={4}
                  className="paynow-text"
                  style={{ color: "black" }}
                >
                  Congratulations! Avail your tickets at
                </Title>

                <Paragraph className="copy-text">
                  <Link to="/active-tickets">Show yours tickets</Link>
                </Paragraph>
                <br />
              </Col>
            </Row>

            <Button
              size="large"
              onClick={() => setGetTicket(false)}
              className="mb-15 button-comman"
              // style={{ width: "276px" }}
              style={{ width: "276px" }}
            >
              Close
            </Button>
          </Card>
        </Row>
      </Modal>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return {
    singleOrdersLoader: state.order.singleOrdersLoader,
    singleOrdersData: state.order.singleOrdersData,
    quizLoader: state.wishlistCart.quizLoader,
    quizData: state.wishlistCart.quizData,
    allocateOrderTicketLoader: state.order.allocateOrderTicketLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetOrderDetail,
  actionGetQuiz,
  actionAddAllocateTickets,
})(OrderDetail);
