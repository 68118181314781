import * as actionTypes from "../action";

const initialState = {
  ordersLoader: false,
  ordersData: [],
  allocateOrderTicketLoader: false,
  singleOrdersLoader: false,
  singleOrdersData: [],
  ordersCount: {},
  createBPayOrderLoader: false,
  createBPayOrderData: {},
  getOrderStatusLoader: false,
  getOrderStatusData: {},
  refId: {},
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOTAL_ORDERS_COUNT:
      return { ...state, ordersCount: action.payload };

    case actionTypes.GET_ORDERS_LOADER:
      return { ...state, ordersLoader: action.payload };

    case actionTypes.GET_ORDERS_DATA:
      return { ...state, ordersData: action.payload };

    case actionTypes.GET_SINGLE_ORDER_LOADER:
      return { ...state, singleOrdersLoader: action.payload };

    case actionTypes.GET_SINGLE_ORDER_DATA:
      return { ...state, singleOrdersData: action.payload };

    case actionTypes.CREATE_BPAY_ORDER_LOADER:
      return { ...state, createBPayOrderLoader: action.payload };

    case actionTypes.CREATE_BPAY_ORDER_DATA:
      return { ...state, createBPayOrderData: action.payload };

    case actionTypes.GET_ORDER_STATUS_LOADER:
      return { ...state, getOrderStatusLoader: action.payload };

    case actionTypes.GET_ORDER_STATUS_DATA:
      return { ...state, getOrderStatusData: action.payload };

    case actionTypes.GET_REF_ID:
      return { ...state, refId: action.payload };

    case actionTypes.ADD_ALLOCATE_TICKETA_ON_ORDER_LOADER:
      return { ...state, allocateOrderTicketLoader: action.payload };

    case actionTypes.ADD_ALLOCATE_TICKETA_ON_ORDER_DATA:
      return {
        ...state,
        singleOrdersData: action.payload,
      };

    default:
      return state;
  }
};
export default ordersReducer;
