import { combineReducers } from "redux";
import authReducer from "./authReducer";
import homeReducer from "./homeReducer";
import wishlistReducer from "./wishlistCartReducer";
import locationReducer from "./locationReducer";
import orderReducer from "./orderReducer";
import ticketsReducer from "./ticketsReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  wishlistCart: wishlistReducer,
  location: locationReducer,
  order: orderReducer,
  tickets: ticketsReducer,
});

export default rootReducer;
