import { Typography, Row, Col } from "antd";
import React from "react";

import play from "../../assets/comman/play-button.png";

const { Link, Paragraph ,Title} = Typography;

const HowitWorks = () => {
  return (
    <>
      <div className="video-main-screen">
        <Title className="text-line" level={1}>How it works </Title>
        <br />

        <Row>
          <Col span={24}>
            <div className="video-wrapper">
              <Typography.Link
                target="_blank"
                href={`https://youtu.be/TUG9icLLh-w`}
                className="text-link"
              >
                <img
                  className="video-thumbnail-image"
                  src={play}
                  alt="Youtube-image"
                />
              </Typography.Link>
            </div>
          </Col>
        </Row>

        {/* <Row>
          <Col span={24} className="col-border">
            <Typography.Link target="_blank" href={`https://youtu.be/TUG9icLLh-w`} className="text-link">
              <div className="video-wrapper">
                <img
                  className="video-thumbnail-image"
                  src={play}
                  alt="Youtube-image"
                />
              </div>
            </Typography.Link>
           
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default HowitWorks;
